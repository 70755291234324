import api from '@/services/api';
import { PageDTO } from '@/dtos/generics';
import { AddStorageDTO, StorageDTO } from '@/dtos/storages';
import UpdateStorageDTO from '@/dtos/storages/UpdateStorageDTO';
import SearchQuery from '@/utils/SearchQuery';

const storageProvider = {
  async list(filters: SearchQuery): Promise<PageDTO<StorageDTO>> {
    const { data } = await api.get<PageDTO<StorageDTO>>(`/storages`, {
      params: filters.query,
    });
    return data;
  },
  async getById(id: string): Promise<StorageDTO> {
    const { data } = await api.get<StorageDTO>(`/storages/${id}`);
    return data;
  },
  async create(storage: AddStorageDTO): Promise<StorageDTO> {
    const { data } = await api.post<StorageDTO>('/storages', storage);
    return data;
  },
  async update(storage: UpdateStorageDTO): Promise<StorageDTO> {
    const { data } = await api.put<StorageDTO>(
      `/storages/${storage.id}`,
      storage
    );
    return data;
  },
  async active(storageId: string): Promise<void> {
    await api.put(`/storages/${storageId}/active`);
  },
  async delete(storage: string): Promise<void> {
    await api.delete(`/storages/${storage}`);
  },
};

export default storageProvider;
