import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import {
  AddEquipmentDTO,
  EquipmentDTO,
  UpdateEquipmentDTO,
} from '@/dtos/equipments';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { EquipmentState } from './types';

const INITIAL_STATE: EquipmentState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as EquipmentDTO,
};

export enum EquipmentTypes {
  SET_LOADING = '@equipments/SET_LOADING',
  SET_FILTERS = '@equipments/SET_FILTERS',
  LIST_REQUEST = '@equipments/LIST_REQUEST',
  LIST_SUCCESS = '@equipments/LIST_SUCCESS',
  LIST_ERROR = '@equipments/LIST_ERROR',
  UPDATE_REQUEST = '@equipments/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@equipments/UPDATE_SUCCESS',
  UPDATE_ERROR = '@equipments/UPDATE_ERROR',
  GET_REQUEST = '@equipments/GET_REQUEST',
  GET_SUCCESS = '@equipments/GET_SUCCESS',
  GET_ERROR = '@equipments/GET_ERROR',
  ADD_REQUEST = '@equipments/ADD_REQUEST',
  ADD_SUCCESS = '@equipments/ADD_SUCCESS',
  ADD_ERROR = '@equipments/ADD_ERROR',
  CLEAR = '@equipments/CLEAR',
}

const EquipmentsActions = {
  setLoading: (loading: boolean) =>
    action(EquipmentTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(EquipmentTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(EquipmentTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<EquipmentDTO>) =>
    action(EquipmentTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(EquipmentTypes.LIST_ERROR, { error }),

  updateRequest: (equipments: UpdateEquipmentDTO) =>
    action(EquipmentTypes.UPDATE_REQUEST, equipments),
  updateSuccess: () => action(EquipmentTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(EquipmentTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(EquipmentTypes.GET_REQUEST, id),
  getSuccess: (equipments: EquipmentDTO) =>
    action(EquipmentTypes.GET_SUCCESS, equipments),
  getError: (error: string) => action(EquipmentTypes.GET_ERROR, { error }),

  addRequest: (data: AddEquipmentDTO) =>
    action(EquipmentTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(EquipmentTypes.ADD_SUCCESS),
  addError: (error: string) => action(EquipmentTypes.ADD_ERROR, { error }),

  clear: () => action(EquipmentTypes.CLEAR),
};
export default EquipmentsActions;

type EquipmentReducer<Action extends AnyAction> = Reducer<
  EquipmentState,
  Action
>;

const setLoading: EquipmentReducer<
  ReturnType<typeof EquipmentsActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});

const setFilters: EquipmentReducer<
  ReturnType<typeof EquipmentsActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: EquipmentReducer<
  ReturnType<typeof EquipmentsActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: EquipmentReducer<
  ReturnType<typeof EquipmentsActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: EquipmentReducer<
  ReturnType<typeof EquipmentsActions.clear>
> = () => INITIAL_STATE;

export const reducer = createReducer<EquipmentState>(INITIAL_STATE)
  .handleAction(EquipmentTypes.SET_LOADING, setLoading)
  .handleAction(EquipmentTypes.SET_FILTERS, setFilters)
  .handleAction(EquipmentTypes.LIST_REQUEST, genericRequest)
  .handleAction(EquipmentTypes.LIST_SUCCESS, listSuccess)
  .handleAction(EquipmentTypes.LIST_ERROR, genericError)
  .handleAction(EquipmentTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(EquipmentTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(EquipmentTypes.UPDATE_ERROR, genericError)
  .handleAction(EquipmentTypes.GET_REQUEST, genericRequest)
  .handleAction(EquipmentTypes.GET_SUCCESS, getSuccess)
  .handleAction(EquipmentTypes.GET_ERROR, genericError)
  .handleAction(EquipmentTypes.ADD_REQUEST, genericRequest)
  .handleAction(EquipmentTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(EquipmentTypes.ADD_ERROR, genericError)
  .handleAction(EquipmentTypes.CLEAR, clear);
