import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { AddGasDTO, GasDTO } from '@/dtos/gas';
import { PageDTO } from '@/dtos/generics';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { GasState } from './types';
import UpdateGasDTO from '@/dtos/gas/UpdateGasDTO';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: GasState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as GasDTO,
};

export enum GasTypes {
  SET_LOADING = '@gases/SET_LOADING',
  SET_FILTERS = '@gases/SET_FILTERS',
  LIST_REQUEST = '@gases/LIST_REQUEST',
  LIST_SUCCESS = '@gases/LIST_SUCCESS',
  LIST_ERROR = '@gases/LIST_ERROR',
  UPDATE_REQUEST = '@gases/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@gases/UPDATE_SUCCESS',
  UPDATE_ERROR = '@gases/UPDATE_ERROR',
  GET_REQUEST = '@gases/GET_REQUEST',
  GET_SUCCESS = '@gases/GET_SUCCESS',
  GET_ERROR = '@gases/GET_ERROR',
  ADD_REQUEST = '@gases/ADD_REQUEST',
  ADD_SUCCESS = '@gases/ADD_SUCCESS',
  ADD_ERROR = '@gases/ADD_ERROR',
  CLEAR = '@gases/CLEAR',
  ACTIVE_REQUEST = '@gases/ACTIVE_REQUEST',
  ACTIVE_SUCCESS = '@gases/ACTIVE_SUCCESS',
  ACTIVE_ERROR = '@gases/ACTIVE_ERROR',
  DELETE_REQUEST = '@gases/DELETE_REQUEST',
  DELETE_SUCCESS = '@gases/DELETE_SUCCESS',
  DELETE_ERROR = '@gases/DELETE_ERROR',
}

const GasActions = {
  setLoading: (loading: boolean) => action(GasTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(GasTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(GasTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<GasDTO>) => action(GasTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(GasTypes.LIST_ERROR, { error }),

  updateRequest: (gas: UpdateGasDTO) => action(GasTypes.UPDATE_REQUEST, gas),
  updateSuccess: () => action(GasTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(GasTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(GasTypes.GET_REQUEST, id),
  getSuccess: (gas: GasDTO) => action(GasTypes.GET_SUCCESS, gas),
  getError: (error: string) => action(GasTypes.GET_ERROR, { error }),

  activeRequest: (id: string) => action(GasTypes.ACTIVE_REQUEST, id),
  activeSuccess: () => action(GasTypes.ACTIVE_SUCCESS),
  activeError: (error: string) => action(GasTypes.ACTIVE_ERROR, { error }),

  deleteRequest: (id: string) => action(GasTypes.DELETE_REQUEST, id),
  deleteSuccess: () => action(GasTypes.DELETE_SUCCESS),
  deleteError: (error: string) => action(GasTypes.DELETE_ERROR, { error }),

  addRequest: (data: AddGasDTO) => action(GasTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(GasTypes.ADD_SUCCESS),
  addError: (error: string) => action(GasTypes.ADD_ERROR, { error }),

  clear: () => action(GasTypes.CLEAR),
};
export default GasActions;

type GasReducer<Action extends AnyAction> = Reducer<GasState, Action>;

const setLoading: GasReducer<ReturnType<typeof GasActions.setLoading>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  loading: payload.loading,
});
const setFilters: GasReducer<ReturnType<typeof GasActions.setFilters>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: GasReducer<ReturnType<typeof GasActions.listSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: GasReducer<ReturnType<typeof GasActions.getSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: GasReducer<ReturnType<typeof GasActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<GasState>(INITIAL_STATE)
  .handleAction(GasTypes.SET_LOADING, setLoading)
  .handleAction(GasTypes.SET_FILTERS, setFilters)
  .handleAction(GasTypes.LIST_REQUEST, genericRequest)
  .handleAction(GasTypes.LIST_SUCCESS, listSuccess)
  .handleAction(GasTypes.LIST_ERROR, genericError)
  .handleAction(GasTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(GasTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(GasTypes.UPDATE_ERROR, genericError)
  .handleAction(GasTypes.DELETE_REQUEST, genericRequest)
  .handleAction(GasTypes.DELETE_SUCCESS, getSuccess)
  .handleAction(GasTypes.DELETE_ERROR, genericError)
  .handleAction(GasTypes.GET_REQUEST, genericRequest)
  .handleAction(GasTypes.GET_SUCCESS, getSuccess)
  .handleAction(GasTypes.GET_ERROR, genericError)
  .handleAction(GasTypes.ACTIVE_REQUEST, genericRequest)
  .handleAction(GasTypes.ACTIVE_SUCCESS, genericSuccess)
  .handleAction(GasTypes.ACTIVE_ERROR, genericError)
  .handleAction(GasTypes.ADD_REQUEST, genericRequest)
  .handleAction(GasTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(GasTypes.ADD_ERROR, genericError)
  .handleAction(GasTypes.CLEAR, clear);
