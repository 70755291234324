import { TypePrevious } from '@/enums';
import Locale from '../types';

const locale: Locale = {
  systemName: 'Meta Track',
  systemCopyright: 'Desenvolvido por Meta Globaltech.',
  input: {
    email: 'E-mail',
    password: 'Senha',
    search: {
      name: 'Nome',
      nameOrDocument: 'Nome ou documento',
      nameConductors: 'Nome, CPF, CNH ou Empresa',
      code: 'Código',
      board: 'Informe a placa',
      nameOrEmail: 'Nome ou E-mail',
    },
    bondTypes: {
      others: 'Terceirizado',
      employee: 'Funcionário',
      autonomous: 'Autônomo',
    },
    driversLicenseType: {
      categoryA: 'Categoria A',
      categoryB: 'Categoria B',
      categoryC: 'Categoria C',
      categoryD: 'Categoria D',
      categoryE: 'Categoria E',
      categoryACC: 'Carteira ACC',
    },
    receiptAnalysis: {
      yes: 'SIM',
      no: 'NÃO',
    },
    receiptFinality: {
      medicinal: 'Medicinal',
      industrial: 'Industrial',
    },
    createdAt: 'Criado em',
    updatedAt: 'Atualizado em',
    name: 'Nome',
    phone: 'Telefone',
    confirmPassword: 'Confirmação de senha',
    date: 'Data',
    ncm: 'NCM',
    ean: 'EAN-13',
    fuelAmount: 'Quantidade de combustível (em litros)',
    registrationKey: 'Chave do motorista',
    oldPassword: 'Senha antiga',
    newPassword: 'Nova senha',
    code: 'Código',
    defaultValue: 'Valor padrão',
    city: 'Cidade',
    state: 'UF',
    zipCode: 'CEP',
    neighborhood: 'Bairro',
    street: 'Endereço',
    number: 'Número',
    complement: 'Complemento',
    document: 'Documento',
    documentType: 'Tipo de documento',
    description: 'Descrição',
    externalId: 'Identificador externo',
    observation: 'Observação',
    lot: 'Lote',
    resourceId: 'Recurso',
    intrinsicMarking: 'Marcação intrínseca',
    manufacturing: 'Fabricação',
    due: 'Vencimento',
    block: 'Bloquear',
    inspection: 'Data de inspeção',
    days: 'Dias',
    nickname: 'Nome completo',
    equals: 'É igual',
    not: 'É diferente',
    like: 'Contém',
    moreThan: 'Maior que',
    lessThan: 'Menor que',
    board: 'Placa',
    brand: 'Marca',
    model: 'Modelo',
    driversDocument: 'CPF',
    bondType: 'Tipo de Vínculo',
    company: 'Empresa',
    driversLicense: 'CNH',
    driversLicenseValidity: 'Validade da CNH',
    unassignedResource: 'Recurso não Atribuído',
    licensePlate: 'Informe a placa',
    racks: 'Nome do rack',
    min: 'Quantidade mínima',
    max: 'Quantidade máxima',
    capacity: 'Capacidade (litros)',
    rackId: 'Rack',
    equipmentId: 'Equipamentos',
    procedureId: 'Pops',
    serialNumber: 'Número de série',
    amountTag: 'Quantidade de etiquetas',
    occupation: 'Ocupação (litros)',
    occupationStorage: 'Ocupação do Tanque',
    port: 'Porta',
    url: 'URL',
    isConductor: 'É um motorista',
    gasFirstId: 'Gás Correspondente 1',
    gasSecondId: 'Gás Correspondente 2',
    gasFirstPorcentage: 'Proporção do Gas 1 (%)',
    gasSecondPorcentage: 'Proporção do Gas 2 (%)',
    isMixture: 'É um gás de mistura',
  },
  select: {
    placeholder: {
      role: 'Função no sistema',
      fuelType: 'Tipo de combustível',
      subsidiary: 'Filial',
      invoiceTypes: 'Tipo da nota fiscal',
      invoiceStatus: 'Status da nota fiscal',
      selectId: 'Selecione',
      selectLink: 'Selecione o tipo de vínculo',
      selectCategory: 'Selecione a categoria da sua CNH',
      selectAnalyze: 'Necessita de análise no recebimento',
      selectFinality: 'Finalidade',
      conductor: 'Motorista',
      selectEquipments: 'Equipamentos',
      pops: 'Pops',
      noRawMaterialFound: 'Nenhuma matéria prima encontrada',
      noEquipmentFound: 'Nenhum equipamento encontrado',
      noProcedureFound: 'Nenhum procedimento encontrado',
    },
    loading: 'Carregando...',
    noOptions: {
      noRole: 'Nenhuma regra encontrada.',
      noFuelType: 'Nenhum tipo de combustível encontrado.',
      noSubsidiary: 'Nenhuma filial encontrada.',
      noInvoiceTypes: 'Nenhum tipo de nota fiscal encontrado.',
      noInvoiceStatus: 'Nenhum status de nota fiscal encontrado.',
      noConductor: 'Nenhum motorista encontrado.',
    },
  },
  button: {
    login: 'Acessar',
    filter: 'Filtrar',
    previous: 'Anterior',
    next: 'Próxima',
    register: 'Cadastrar',
    cleanFilters: 'Limpar filtros',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    import: 'Importar',
    add: 'Adicionar',
    resendCode: 'Reenviar código',
    addCode: 'Adicionar código',
    update: 'Atualizar',
  },
  listing: {
    name: 'Nome',
    creationDate: 'Data de criação',
    quickActions: 'Ações rápidas',
    email: 'E-mail',
    role: 'Função no sistema',
    client: 'Cliente',
    ncm: 'NCM',
    ean: 'EAN-13',
    proportion: 'Proporção',
    fuelAvailable: 'Gasolina disponível',
    accessKey: 'Chave de acesso',
    productAmount: 'Quantidade de produtos',
    state: 'Estado',
    document: 'Documento',
    documentType: 'Tipo de documento',
    codeValue: 'Valor de código',
    due: 'Vencimento',
    resource: 'Recurso',
    inspection: 'Inspeção',
    generated: 'Gerado',
    amount: 'Quantidade',
    responsible: 'Responsável',
    identifier: 'Identificador',
    currentState: 'Estado atual',
    user: 'Usuário',
    event: 'Evento',
    idEvent: 'Id evento',
    cylinder: 'Cilindro',
    board: 'Placa',
    brand: 'Marca',
    model: 'Modelo',
    driversDocument: 'CPF',
    bondType: 'Tipo de Vínculo',
    company: 'Empresa',
    driversLicense: 'CNH',
    driversLicenseType: 'Categoria',
    time: 'Horário',
    min: 'Quantidade mínima',
    max: 'Quantidade máxima',
    printerAddress: 'Endereço da impressora',
    eventCurrent: 'Evento Atual',
    generatedCode: 'Gerado pelo sistema',
  },
  accessibility: {
    iconTitle: {
      deleteCode: 'Remover código',
      details: 'Detalhes',
      edit: 'Editar',
      importInvoice: 'Importar Nota Fiscal',
      increaseFuelStock: 'Adicionar combustível',
      delete: 'Apagar',
      cancel: 'Cancelar',
      dispatch: 'Despachar/Enviar',
      close: 'Fechar',
      clearSearch: 'Limpar pesquisa',
      search: 'Pesquisar',
      language: 'Linguagem do site',
      myAccount: 'Minha conta',
      filterDate: 'Filtrar por data',
      activeUser: 'Ativar usuário',
      deactiveUser: 'Desativar usuário',
      activeVehicle: 'Ativar veículo',
      deactiveVehicle: 'Desativar veículo',
      activeConductor: 'Ativar condutor',
      deactiveConductor: 'Desativar condutor',
      deactive: 'Desativar',
      active: 'Ativar',
      block: 'Bloquear',
      activeRack: 'Ativar rack',
      deactiveRack: 'Desativar rack',
      printer: 'Imprimir',
      sendPrinter: 'Enviar para impressora',
    },
  },
  invoiceFields: {
    access_key: 'Chave de acesso',
    address: 'Endereço',
    number: 'Número',
    neighborhood: 'Bairro',
    city_name: 'Cidade',
    city_code: 'Código da cidade',
    state: 'Estado',
    zip_code: 'CEP',
    nation_name: 'País',
    nation_code: 'Código do país',
    phone: 'Telefone',
    destination_name: 'Nome do destinatário',
    document: 'Documento (CPF/CNPJ)',
    amount: 'Quantidade',
    unit: 'Unidade',
    price: 'Preço',
    name: 'Nome do produto',
  },
  page: {
    generic: {
      list: {
        filter: {
          title: 'Filtros',
          total: 'Total',
          filterAnyData: 'Filtrar dados',
          others: 'Outros filtros',
          role: 'Função no sistema',
          noFilterMessage: 'Nenhum filtro selecionado',
        },
        noDataMessage: 'Nenhum dado existente',
        download: 'Download',
      },
      save: {
        headerTitle: 'Adicionar',
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar {{identifier}}',
      },
      details: {
        headerTitle: 'Detalhes',
        notAssociated: 'Não associado(a)',
        uninformed: 'Não informado',
      },
    },
    cylinder: {
      actived: 'Cilindro já ativado',
      new: 'Novos cilindros',
    },
    login: {
      formLabel: 'Entre',
      formTitle: 'na sua conta',
      button: {
        forgotPassword: 'Esqueci minha senha',
      },
    },
    events: {
      headerTitle: 'Eventos',
    },
    users: {
      headerTitle: 'Usuários',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Ativados',
          disabled: 'Desativos',
        },
        noDataMessage: 'Nenhum usuário encontrado',
        modal: {
          search: {
            title: 'Pesquise em usuários',
          },
          filter: {
            title: 'Filtrar usuários',
          },
          confirmStatusChange: {
            title: 'Confirmar mudança',
            activate: 'ativar',
            disable: 'desativar',
            warning:
              'Você está tentando {{action}} o usuário {{name}}. Tem certeza disso?',
          },
        },
      },
      save: {
        headerTitle: 'Cadastar',
        title: 'Cadastre um usuário',
        error: {
          cant_add_user: 'Você não possui permissão para cadastrar usuários.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Edite um usuário',
        error: {
          cant_edit_user: 'Você não possui permissão para editar usuários.',
        },
      },
    },
    racks: {
      headerTitle: 'Racks',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Ativados',
          disabled: 'Desativos',
        },
        noDataMessage: 'Nenhum rack encontrado',
        modal: {
          search: {
            title: 'Pesquise em racks',
          },
          filter: {
            title: 'Filtrar racks',
          },
          confirmStatusChange: {
            title: 'Confirmar mudança',
            activate: 'ativar',
            disable: 'desativar',
            warning:
              'Você está tentando {{action}} o rack {{name}}. Tem certeza disso?',
          },
        },
      },
      save: {
        headerTitle: 'Cadastar',
        title: 'Cadastre um rack',
        error: {
          cant_add_racks: 'Você não possui permissão para cadastrar racks.',
          cant_add_purpose:
            'Você nao possui permissão para configurar finalidade',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Edite um rack',
        error: {
          cant_edit_racks: 'Você não possui permissão para editar racks.',
        },
      },
      configRacks: {
        subTitle: 'Configurar',
        headerTitle: 'Configurar',
        title: 'Configurar Rack',
        error: {
          cant_edit_equipment:
            'Você não possui permissão para editar equipamentos.',
        },
      },
      purposeRacks: {
        subTitle: 'Configurar Finalidade',
        headerTitle: 'Finalidade',
        title: 'Finalidade',
        error: {
          cant_edit_equipment:
            'Você não possui permissão para editar equipamentos.',
        },
      },
    },
    vehicles: {
      headerTitle: 'Veículos',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Ativados',
          disabled: 'Desativos',
        },
        noDataMessage: 'Nenhum veículo encontrado',
        modal: {
          search: {
            title: 'Pesquise em veículos',
          },
          filter: {
            title: 'Filtrar veículos',
          },
          confirmStatusChange: {
            title: 'Confirmar mudança',
            activate: 'ativar',
            disable: 'desativar',
            warning:
              'Você está tentando {{action}} o veículo {{name}}. Tem certeza disso?',
          },
        },
      },
      save: {
        headerTitle: 'Cadastar',
        title: 'Cadastre um veículo',
        error: {
          cant_add_vehicles:
            'Você não possui permissão para cadastrar veículos.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Edite um veículo',
        error: {
          cant_edit_vehicles: 'Você não possui permissão para editar veículos.',
        },
      },
    },
    conductors: {
      headerTitle: 'Condutores',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Ativados',
          disabled: 'Desativos',
        },
        noDataMessage: 'Nenhum condutor encontrado',
        modal: {
          search: {
            title: 'Pesquise em condutores',
          },
          filter: {
            title: 'Filtrar condutores',
          },
          confirmStatusChange: {
            title: 'Confirmar mudança',
            activate: 'ativar',
            disable: 'desativar',
            warning:
              'Você está tentando {{action}} o condutor {{name}}. Tem certeza disso?',
          },
        },
      },
      save: {
        headerTitle: 'Cadastar',
        title: 'Cadastre um condutor',
        error: {
          cant_add_conductors:
            'Você não possui permissão para cadastrar condutores.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Detalhes do condutor',
        error: {
          cant_edit_conductors:
            'Você não possui permissão para editar condutores.',
        },
      },
    },
    gas: {
      headerTitle: 'Matéria Prima',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Ativados',
          disabled: 'Desativos',
        },
        noDataMessage: 'Nenhuma matéria prima encontrada',
        modal: {
          search: {
            title: 'Pesquise em matéria prima',
          },
          filter: {
            title: 'Filtrar matéria prima',
          },
          confirmStatusChange: {
            title: 'Confirmar mudança',
            activate: 'ativar',
            disable: 'desativar',
            warning:
              'Você está tentando {{action}} o gás {{name}}. Tem certeza disso?',
          },
        },
      },
      save: {
        headerTitle: 'Cadastrar',
        title: 'Cadastrar Matéria Prima',
        error: {
          cant_add_gas:
            'Você não possui permissão para cadastrar matéria prima.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar Matéria Prima',
        error: {
          cant_edit_gas: 'Você não possui permissão para editar matéria prima.',
        },
      },
    },
    storage: {
      headerTitle: 'Armazenamento',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Ativados',
          disabled: 'Desativos',
        },
        noDataMessage: 'Nenhuma armazenamento encontrada',
        modal: {
          search: {
            title: 'Pesquise em armazenamento',
          },
          filter: {
            title: 'Filtrar armazenamento',
          },
          confirmStatusChange: {
            title: 'Confirmar mudança',
            activate: 'ativar',
            disable: 'desativar',
            warning:
              'Você está tentando {{action}} a armazenamento {{name}}. Tem certeza disso?',
          },
        },
      },
      save: {
        headerTitle: 'Cadastrar',
        title: 'Cadastrar Armazenamento',
        error: {
          cant_add_storage:
            'Você não possui permissão para cadastrar armazenamento.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar armazenamento',
        error: {
          cant_edit_storage:
            'Você não possui permissão para editar armazenamento.',
        },
      },
    },
    printers: {
      headerTitle: 'Impressoras',
      tag: {
        headerTitle: 'Imprimir etiqueta',
      },
    },
    distributors: {
      headerTitle: 'Distribuidoras',
      list: {
        filter: {
          total: 'Total',
        },
        noDataMessage: 'Nenhuma distribuidora encontrada',
        modal: {
          search: {
            title: 'Pesquise em distribuidoras',
          },
          filter: {
            title: 'Filtrar distribuidoras',
          },
        },
      },
      save: {
        headerTitle: 'Cadastrar',
        title: 'Informe o nome da distribuidora',
        subTitle: 'Cadastre um usuário administrador',
        error: {
          cant_add_distributor:
            'Você não possui permissão para cadastrar distribuidoras.',
        },
      },
      edit: {
        error: {
          cant_edit_distributor:
            'Você não possui permissão para editar uma distribuidora.',
        },
      },
      details: {
        headerTitle: 'Detalhes',
        title: 'Filiais',
        noSubsidiariesFound: 'Nenhuma subsidiária encontrada',
        noStockFound: 'Sem estoque disponível',
        stockItem: 'Possui {{amount}}L (litros) de {{name}}',
      },
    },
    invoices: {
      headerTitle: 'Notas Fiscais',
      list: {
        noData: 'Nenhuma NF-e importada.',
        modal: {
          search: {
            title: 'Pesquise em notas fiscais',
          },
          transferCustody: {
            title: 'Transferência de custódia',
          },
          confirmCancel: {
            title: 'Cancelar nota fiscal',
            warning:
              'Esta ação é IRREVERSÍVEL. Tem certeza que deseja cancelar esta nota fiscal?',
          },
          filter: {
            title: 'Filtrar notas fiscais',
          },
        },
        types: {
          company: 'Meta',
          distributor: 'Distribudoras',
        },
        status: {
          created: 'Criada/Sem movimentações',
          dispatched: 'Carga despachada',
          received: 'Carga recebida',
          canceled: 'Cancelada',
        },
      },
      save: {
        headerTitle: 'Notas Fiscais',
        error: {
          cant_import_invoices:
            'Você não possui permissão para importar notas fiscais.',
          no_files_inserted: 'Nenhum arquivo inserido.',
        },
        xFilesInserted: '{{length}} arquivos inseridos.',
        uploadBoxLabel: 'Clique ou solte arquivos aqui.',
      },
      details: {
        label: {
          accessKey: 'Chave de acesso',
          importDate: 'Data de importação (no sistema)',
          dispatchDate: 'Data de despacho',
          receiverName: 'Nome do destinatário',
          receiverDocument: 'Documento do destinatário',
          documentType: 'Tipo do documento',
          city: 'Município',
          state: 'UF',
          zipCode: 'CEP',
          neighborhood: 'Bairro',
          address: 'Endereço',
          phone: 'Telefone',
          driverName: 'Nome do motorista',
          driverEmail: 'E-mail',
          registrationKey: 'Chave de registro',
          ean: 'EAN-13: ',
          ncm: 'NCM: ',
          price: 'Preço: ',
          racks: 'Nome do rack',
          min: 'Quantidade mínima',
          max: 'Quantidade máxima',
        },
        value: {
          notDispatched: 'Não despachada',
        },
        productsTitle: 'Produtos',
      },
    },
    subsidiaries: {
      headerTitle: 'Filiais',
      list: {
        noDataMessage: 'Nenhuma filial encontrada',
        modal: {
          search: {
            title: 'Pesquise em filiais',
          },
          filter: {
            title: 'Filtrar filiais',
          },
        },
      },
    },
    fuelTypes: {
      headerTitle: 'Tipos de combustíveis',
      list: {
        noDataMessage: 'Nenhum tipo encontrado',
        modal: {
          search: {
            title: 'Pesquise em catalisadores',
          },
          filter: {
            title: 'Filtrar tipos de combustíveis',
          },
        },
      },
      save: {
        headerTitle: 'Adicionar',
        title: 'Adicione um tipo de combustível',
        subTitle: 'Proporção em litros (Combustível x Catalisador)',
        error: {
          cant_add_fuel_types:
            'Você não possui permissão para adicionar tipos de combustíveis.',
        },
      },
      edit: {
        error: {
          cant_edit_fuel_types:
            'Você não possui permissão para editar tipos de combustíveis.',
        },
      },
    },
    distributorFuels: {
      headerTitle: 'Combustíveis',
      list: {
        noDataMessage: 'Nenhum combustível cadastrado',
        modal: {
          search: {
            title: 'Pesquise em combustíveis',
          },
          filter: {
            title: 'Filtrar combustíveis',
          },
          increaseFuelStocks: {
            title: 'Adicionar combustível ao estoque',
          },
        },
      },
      save: {
        headerTitle: 'Adicionar',
        title: 'Adicione um combustível',
        error: {
          cant_add_distributorFuels:
            'Você não possui permissão para adicionar combustíveis.',
        },
      },
      edit: {
        error: {
          cant_edit_distributorFuels:
            'Você não possui permissão para editar combustíbeis.',
        },
      },
    },
    activateAccount: {
      title: 'Ative sua conta',
    },
    addEvent: {
      headerTitle: 'Atualizar estado dos produtos',
      noCodeOnList: 'Nenhum código adicionado à lista.',
      events: {
        updated: 'Atualizado',
        canceled: 'Cancelado',
        misplaced: 'Extraviado',
        stolen: 'Roubado',
        damaged: 'Avariado',
      },
      error: {
        code_already_added: 'O código {{code}} já foi adicionado à lista.',
        no_code_added: 'Nenhum código adicionado.',
        unknow_user: 'Usuário não encontrado',
        system_generated: 'Gerado pelo sistema',
      },
    },
    passwordRecovery: {
      title: 'Recuperar senha',
    },
    passwordUpdate: {
      title: 'Atualizar senha',
    },
    safety: {
      headerTitle: 'Regras',
      title: 'Todas as regras',
      add: {
        title: 'Adicionar regra',
      },
      edit: {
        title: 'Editar função',
        information: 'Informações dessa função',
      },
      permissionsLabel: 'Permissões',
      error: {
        no_permissions_selected: 'Nenhuma permissão selecionada',
      },
      permission: {
        module: {
          distributor: 'Distribuidora',
          distributorFuel: 'Combustível da distribuidora',
          distributorInvoice: 'Nota fiscal de distribuidora',
          event: 'Eventos',
          fuelStock: 'Estoque de combustível',
          fuelType: 'Tipo de combustível',
          gasStation: 'Posto de gasolina',
          invoice: 'Nota fiscal',
          role: 'Regra',
          subsidiary: 'Subsidiária',
          user: 'Usuario',
          company: 'Empresa',
          client: 'Cliente',
          resource: 'Recurso',
          catalyst: 'Catalisador',
          conductor: 'Condutores',
          vehicle: 'Veículos',
          gas: 'Matéria Prima',
          rack: 'Racks',
          storage: 'Armazenamento',
          receive: 'Recebimento',
          settingRack: 'Configuração Rack',
          settingPurpose: 'Configuração Finalidade',
          updateStock: 'Atualizar Estoque',
          delivery: 'Embarque',
          printers: 'Impressora',
          landing: 'Desembarque',
          manifest: 'Manifesto de carga',
          movement: 'Movimentação',
          accessProfile: 'Perfil de Acesso',
        },
        type: {
          nothing: 'Nenhuma',
          index: 'Ler',
          store: 'Ler e criar',
          index_update: 'Ler, atualizar',
          update: 'Ler, atualizar e apagar',
          delete: 'Ler, criar, atualizar e apagar',
          index_event: 'Listar eventos',
          store_event_transfer_custody: 'Transferência de custódia',
          store_event_update: 'Atualizar',
          store_event_cancel: 'Cancelar',
          store_event_misplaced: 'Extraviado',
          store_event_damaged: 'Avariado',
          store_event_receipt_confirmed: 'Confirmação de recibo',
          available: 'Disponivel',
          hidden: 'Oculto',
        },
      },
    },
    tracking: {
      headerTitle: 'Rastreabilidade',
      noEvents: 'Nenhuma movimentação realizada.',
      modal: {
        search: {
          title: 'Pesquise em movimentações',
        },
      },
      popupLabel: {
        name: 'Nome',
        realizedAt: 'Realizado em',
      },
      filterLabel: {
        all: 'Todos',
        write: 'Escrita',
        read: 'Leitura',
      },
    },
    settings: {
      headerTitle: 'Configurações',
    },
    status: {
      previousStatus: 'Status anterior',
      headerTitle: 'Status',
      detailsHeaderTitle: 'Detalhes',
      save: {
        headerTitle: 'Adicionar status',
        [TypePrevious.ONE_OF]: 'Um dos',
        editable: 'Editável',
        statusFields: 'Campos do status',
      },
    },
    clients: {
      headerTitle: 'Clientes',
      save: {
        title: 'Adicionar cliente',
      },
      edit: {
        title: 'Editar cliente',
      },
    },
    resources: {
      headerTitle: 'Recursos',
      save: {
        headerTitle: 'Adicionar recurso',
        title: 'Cadastrar cilindro',
      },
      edit: {
        headerTitle: 'Edição De Recursos',
        title: 'Editar',
      },
    },
    codes: {
      headerTitle: 'Códigos',
      due: {
        headerTitle: 'Vencimento',
        list: {
          noDataMessage: 'Nenhum dado existente',
          filter: {
            type: 'Filtrar vencimentos',
          },
          modal: {
            filter: {
              title: 'Filtrar Dias',
            },
          },
        },
      },
      inspection: {
        headerTitle: 'Inspeção',
        list: {
          noDataMessage: 'Nenhum dado existente',
          filter: {
            type: 'Filtrar vencimentos',
          },
          modal: {
            filter: {
              title: 'Filtrar Dias',
            },
          },
        },
      },
      details: {
        label: {
          code: 'Código',
          updatedAt: 'Última atualizacão',
          accessKey: 'Chave de Acesso',
          resourceName: 'Recurso',
          currentState: 'Evento Atual',
          externalCode: 'Código Externo',
          lot: 'Lote',
          aggregatorCode: 'Código agregador',
        },
      },
    },
  },
  error: {
    validation: {
      email_invalid: 'E-mail inválido',
      email_required: 'Informe um e-mail',
      password_required: 'Informe uma senha',
      name_required: 'Informe o seu nome',
      description_required: 'Informe a descrição',
      phone_required: 'Informe o telefone',
      max_char_limit_reached: 'Máximo de caracteres atingido',
      min_password_char_required: 'A senha deve ter no mínimo 8 caracteres',
      confirm_password_required: 'Confirme a senha',
      password_dont_match_pattern:
        'Use oito ou mais caracteres\ncom uma combinação de letras, \nnúmeros e símbolos',
      passwords_dont_match: 'As senhas não conferem',
      distributor_name_required: 'Informe o nome da distribuidora',
      location_permission_required: 'Precisamos ter acesso a sua localização.',
      ncm_required: 'Informe o NCM',
      ean_required: 'Informe o EAN-13',
      fuel_type_required: 'Informe o tipo de combustível',
      event_required: 'Informe o evento',
      observation_required: 'Informe uma observação',
      fuel_amount_required: 'Informe a quantidade de combustível',
      access_denied: 'Acesso negado.',
      page_not_found: 'Página não encontrada.',
      missing_code: 'Nenhum código informado.',
      role_required: 'Informe uma função.',
      document_required: 'Informe o documento selecionado',
      zip_code_required: 'Código postal obrigatório',
      state_required: 'Campo estado obrigatório',
      city_required: 'Cidade obrigatório',
      neighborhood_required: 'Bairro obrigatório',
      street_required: 'Rua obrigatório',
      number_required: 'Número obrigatório',
      driversDocument_required: 'Informe o CPF',
      bondType_required: 'Vínculo obrigatório',
      company_required: 'Empresa obrigatório',
      board_required: 'Informe a placa do veículo',
      brand_required: 'Marca obrigatório',
      model_required: 'Modelo obrigatório',
      digit: 'Foi informado letras no campo - Documento inválido',
      max: 'O documento CNH é composto por 11 dígitos - CNH inválido',
      min: 'O documento CNH é composto por 11 dígitos - CNH inválido',
      driversLicense_required: 'Informe a CNH',
      driversLicenseValidity_required: 'Informe a validade da CNH',
      driversLicenseType_required: 'Informe a categoria da CNH',
      invalid_date_format: 'Data inválida, use DD/MM/AAAA',
      receiptAnalysis_required: 'Selecione uma opção',
      receiptFinality_required: 'Selecione uma opção',
      racks_required: 'Informe o nome do rack',
      min_required: 'Informe a quantidade mínima',
      max_required: 'Informe a quantidade máxima',
      gas_required: 'Informe o nome da matéria prima',
      select: 'Selecione uma matéria prima',
      capacity:
        'A capacidade de armazenamento deve ser um valor inteiro maior que zero',
      capacity0:
        'A capacidade de armazenamento deve ser um valor inteiro maior que 1',
      equipmentId_required: 'Informe os equipamentos',
      rackId_required: 'Selecione um rack',
      procedure_required: 'Infome os procedimentos',
      value_min: 'A quantidade mínima deve ser um valor inteiro maior que 0',
      value_max:
        'A quantidade máxima deve ser um valor inteiro maior que o mínimo',
      just_numbers: 'Campo exclusivo para números',
      just_numberIntegerMin:
        'Este campo só aceita números inteiros maiores que 0',
      just_numberIntegerMax:
        'Este campo só aceita números inteiros maiores que o mínimo',
      url_required: 'Informe uma URL',
      port_required: 'Informe uma porta',
      just_numbersInteger: 'Informe apenas numeros inteiros',
      selectRack: 'Selecione um rack',
      purpose_finality: 'Informe a finalidade',
      nameRequired: 'Informe um nome',
      gasFirstPorcentage: 'Informe a porcentagem da primeira matéria prima',
      gasSecondPorcentage: 'Informe a porcentagem da segunda matéria prima',
      gasNotFound: 'Nenhuma matéria prima encontrada',
      gasFirstId: 'Selecione uma matéria prima',
      gasFirstPorcentagem:
        'A porcentagem mínima deve ser um valor inteiro maior que 0',
      gasSecondPorcentagem:
        'A porcentagem mínima deve ser um valor inteiro maior que 0',
      unitMeasurement: 'Informe a unidade de medida',
      value0: 'Deve ser um número inteiro maior que 0',
      ncm: 'Informe a nomenclatura comum do mercosul (NCM)',
      fullSize: 'Informe o peso da embalagem cheia',
      gasCapacity: 'Informe a capacidade gasosa',
      litersCapacity: 'Informe a capacidade de litros',
      taxCodeCest: 'Informe o código de substituição tributaria (CEST)',
      emptySize: 'Informe o peso da embalagem vazia',
      currencyPackageValue: 'Informe o valor da embalagem na moeda corrente',
    },
    api: {
      // server
      internal_server_error: 'Erro interno do servidor',
      communication_failure:
        'Falha de comunicação com o servidor ou banco de dados',
      // company
      company_not_found:
        'Dados da compania não encontrados. Informe ao administrador do sistema.',
      // consumer
      consumer_document_already_register:
        'Um consumidor já está cadastrado com este documento.',
      consumer_email_already_exist:
        'Um consumidor já está cadastrado com este e-mail.',
      consumer_not_found:
        'Consumidor não encontrado. {{identifier}} não encontrado.',
      consumer_password_incorrect: 'Senha incorreta.',
      consumer_phone_already_register:
        'Um consumidor com este telefone já está cadastrado.',
      // distributorFuel
      distributor_fuel_not_found: 'Combustível não encontrado.',
      missing_fuel_ean_and_ncm: 'EAN-13 e NCM não encontrados.',
      // distributor
      distributor_already_register: 'Distribuidor(a) já cadastrado(a).',
      distributor_not_found: 'Distribuidor(a) não encontrado(a).',
      // driver
      driver_client_not_found: 'Cliente não encontrado.',
      driver_document_already_register:
        'Um motorista já está cadastrado com este documento.',
      driver_profile_not_found: 'Motorista não encontrado.',
      driver_work_already_delivered:
        'Este serviço já foi finalizado. Carga entregue.',
      driver_work_not_found: 'Serviço não encontrado.',
      // gasStation
      gas_station_not_found: 'Posto não encontrado.',
      // gasStationStock
      gas_station_stock_blocked: 'O estoque deste posto está bloqueado.',
      gas_station_stock_not_available:
        'Estoque do posto indisponível: não há estoque suficiente para a ação.',
      gas_station_stock_not_found: 'Estoque do posto não encontrado.',
      // invoice
      error_when_import_invoice:
        'Erro ao tentar importar a nota fiscal, tente novamente.',
      invoice_already_dispatched: 'Nota fiscal já foi despachada.',
      invoice_cancel_failed:
        'Erro ao tentar cancelar nota fiscal. Tente novamente.',
      invoice_not_able_to_cancel: 'Nota fiscal não pode mais ser cancelada.',
      invoice_not_able_to_work: 'Nota fiscal não elegível para trabalhos.',
      invoice_not_found: 'Nota fiscal não encontrada.',
      invoice_products_identifiers_not_found:
        'EAN-13 e NCM do produto {{productName}} da nota fiscal não encontrados.',
      missing_field_in_xml_invoice:
        'O campo {{field}} da nota fiscal não foi encontrado.',
      no_fiels_uploaded: 'Nenhum arquivo enviado.',
      user_not_allowed_to_import_invoice:
        'Você não possui permissão para importar uma nota fiscal.',
      // fuelType
      fuel_type_already_register: 'Tipo de combustível já cadastrado.',
      fuel_type_not_found: 'Tipo de combustível não encontrado.',
      // receipt
      access_key_receipt_not_found: 'Recibo não encontrado.',
      consumer_invoice_not_found: 'Nota Fiscal do Consumidor não encontrada.',
      // role
      permission_not_allowed:
        'A função que você está tentando criar possui permissões que não são permitidas para o seu usuário.',
      permission_not_found: 'Permissão não encontrada.',
      role_already_exist: 'Função já criada.',
      role_not_found: 'Função não encontrada.',
      // solicitation
      amount_not_available:
        'Quantidade de códigos insuficiente. Tente novamente.',
      code_not_found: 'Código não encontrado.',
      movement_permission_denied:
        'Você não tem permissão para realizar este evento.',
      solicitation_not_found: 'Solicitação de códigos não encontrada.',
      // user
      cant_disable_user:
        'Você não tem permissão para ativar ou desativar um funcionário.',
      company_deactivated_for_add:
        'A empresa deve estar ativada para cadastrar um funcionário.',
      email_already_registered:
        'Um usuário com este e-mail já está cadastrado.',
      inform_old_password: 'Informe a senha antiga.',
      incorrect_password: 'Senha incorreta.',
      password_are_the_same: 'As senhas são as mesmas.',
      passwords_dont_check: 'Senhas não conferem.',
      role_not_allowed: 'Função no sistema não permitida.',
      token_already_used: 'Token já utilizado.',
      token_not_found: 'Token não encontado.',
      token_not_provided: 'Token não informado.',
      user_active_limit:
        'Você excedeu o limite de usuários ativos, atualize seu plano para ativar mais.',
      user_add_limit:
        'Você excedeu o limite de usuários cadastrados, atualize seu plano para cadastrar mais.',
      user_already_exist: 'Usuário já cadastrado.',
      user_disabled: 'Usuário desativado.',
      user_unauthorized: 'Usuário não autorizado.',
      user_not_found: 'Usuário não encontrado.',
      // session
      token_expired: 'Sessão expirada.',
      conductor_Name_Already_Registered: 'Condutor já registrado',
      client_name_already_exist: 'Cliente já cadastrado',
      client_document_already_exist: 'Documento já cadastrado',
      client_document_is_invalid: 'CPF ou CNPJ é invalido',
      conductor_Not_Found: 'Condutor não encontrado',
      conductor_Drivers_Document_Already_Registered: 'CPF já cadastrado',
      conductor_Drivers_License_Already_Registered: 'CNH já cadastrada',
      conductor_Drivers_Document_Is_Invalid: 'CPF é invalido',
      conductor_validity_date_invalid: 'Data de validade da CNH inválida',
      blank_driver_fields: 'Por favor, preencha todos os campos de condutor.',
      blank_gas: 'Por favor, preencha todos os campos de matéria prima',
      conductor_validity_after_the_date:
        'Data de validade da CNH deve ser posterior à data atual',
      gas_not_found: 'Matéria Prima não encontrada',
      gas_double:
        'Os gases selecionados são idênticos. Por favor, selecione gases distintos para continuar.',
      gas_name_already_registered: 'Matéria Prima já registrada',
      storage_name_already_registered: 'Nome já registrado',
      storage_not_found: 'Registro não encontrado',
      gas_percentage_not_completed:
        'As porcentagens dos gases não estão completas. Certifique-se de que a soma das porcentagens seja igual a 100% para ambos os gases.',
    },
  },
  success: {
    updatedData: 'Dados atualizados com sucesso.',
    passwordRecoveryLinkSent: 'Link de recuperação de senha enviado.',
    passwordRecovered: 'Senha recuperada.',
    successfullyImportedInvoices: 'Notas Fiscais importadas com sucesso.',
    distributorRegistered: 'Distribuidora cadastrada com sucesso.',
    transferCustodySuccess: 'Custódia transferida',
    increaseFuelStock: 'Estoque de combustível adicionado',
  },

  // olds
  Site: {
    initialMessage:
      'Com o sistema de rastreio MetaTrack você tem maior estabilidade , inteligência e facilidade na gerência e na movimentação dos seus produtos.',
    accountActive: 'Ative sua conta',
    passwordRecovery: 'Esqueceu a senha?',
    passwordRecoveryTitle: 'Recupere sua senha',
    newPasswordTitle: 'Defina sua nova senha',
  },
  Label: {
    verifyCode: 'Código do produto',
    fuelAmount: 'Quantidade de combustivel',
    distributorName: 'Nome da distribuidora',
    fuel: 'Quantidade de combustivel em litros',
    ptBR: 'Português (BRL)',
    enUS: 'Inglês (EUA)',
    esES: 'Espanhol (ESP)',
    password: 'Senha',
    email: 'E-mail',
    confirmPassword: 'Confirme sua senha',
    code: 'Código',
    region: 'Região',
    trade: 'Nome fantasia',
    companyName: 'Razão social',
    municipalRegistration: 'Registro municipal',
    municipalExempt: 'Isento',
    stateRegistration: 'Registro estadual',
    stateExempt: 'Isento',
    state: 'Estado',
    city: 'Cidade',
    address: 'Endereço',
    neighborhood: 'Bairro',
    complement: 'Complemento',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    cep: 'CEP',
    name: 'Nome completo',
    phone: 'Telefone',
    office: 'Cargo',
    contactType: 'Setor',
    ownFleet: 'Frota própria',
    driverName: 'Nome do motorista',
    destiny: 'Destino',
    licensePlate: 'Placa do veículo',
    observation: 'Observação',
    aggregateCode: 'Código do volume/produto',
    aggregationCode: 'Código do volume',
    nickname: 'Nome',
    url: 'URL',
    port: 'Porta TCP',
    productImage: 'Imagem do Produto',
    amount: 'Quantidade',
    partitions: 'Colmeias',
    pce: 'PCE',
    country: 'País',
    factory: 'Fábrica',
    weight: 'Peso',
    unit: 'Unidade de medida',
    ean: 'EAN-13',
    dun: 'DUN-14',
    stackingLimit: 'Limite de empilhamento',
    warning: 'Advertência',
    productIdentifier: 'ID do produto',
    description: 'Descrição',
    perishable: 'Perecível',
    oldPassword: 'Senha antiga',
    newPassword: 'Senha nova',
    shippingCompanyName: 'Razão social ou nome',
    productCode: 'Código do produto',
    solicitationDescription: 'Descrição da solicitação',
    manufacturing: 'Data de fabricação',
    due: 'Data de vencimento',
    lot: 'Lote',
    intrinsicMarking: 'Marcação Intrínseca',
    partitionsAmount: 'Quantidade de Colmeias',
    abbrev: 'Abreviação',
    un: 'Número ONU',
    netWeight: 'Peso líquido',
    netUnit: 'Unidade (Kg, g, ...)',
    grossWeight: 'Peso bruto',
    grossUnit: 'Unidade (Kg, g, ...)',
    productSearch: 'Pesquise pelo nome ou PCE',
    nameSearch: 'Pesquise pelo nome',
    invoiceSearch: 'Pesquise pelo nome',
    searchPrinter: 'Pesquise pelo nome ou url',
    carriersSearch: 'Pesquise pelo nome, CNPJ ou CPF',
    activitySearch: 'Cole o código aqui',
    tr: 'TR',
    store: 'Loja',
    clientSearch: 'Pesquise pelo nome',
    client: 'Cliente',
    gasId: 'Nome',
    unitMeasurement: 'Unidade de medida',
    ncm: 'Nomenclatura comum do mercosul (NCM)',
    fullSize: 'Peso da embalagem cheia',
    gasCapacity: 'Capacidade gasosa',
    litersCapacity: 'Capacidade litros',
    taxCodeCest: 'Código de substituição tributaria (CEST)',
    emptySize: 'Peso da embalagem vazia',
    currencyPackageValue: 'Valor da embalagem na moeda corrente',
  },
  Button: {
    unBlock: 'Desbloquear',
    confirm: 'Confirmar',
    add: 'Adicionar',
    cancel: 'Cancelar',
    submit: 'Enviar',
    update: 'Atualizar',
    transfer: 'Transferir',
    register: 'Cadastrar',
    import: 'Importar',
    addCode: 'Adicionar código',
    filter: 'Filtrar',
    order: 'Ordenar',
    reprint: 'Reimprimir',
    previous: 'Anterior',
    next: 'Próxima',
    newRequest: 'Nova Solicitação',
    downloadCodes: 'Baixar códigos',
    requestPartitionCodes: 'Solicitar códigos de colmeia',
    aggregateNewPartition: 'Agregar nova colmeia',
    printRequests: 'Imprimir solicitações',
    printPartitionRequests: 'Imprimir solicitações de colmeias',
    makeRequest: 'Fazer solicitação',
    makeAggregation: 'Fazer agregação',
    print: 'Imprimir',
    printedRequests: 'Impressas',
    unprintedRequests: 'Não impressas',
    product: 'Produto',
    volume: 'Volume',
    cleanFilters: 'Limpar filtros',
    olderRequests: 'Solicitações mais antigas',
    latestRequests: 'Solicitações mais recentes',
    backToDefault: 'Voltar ao padrão',
    dProduction: 'Produção diária',
    choosePrinter: 'Escolha uma impressora',
    linkInvoice: 'Vincular NF-e',
    transferInvoice: 'Transferir custódia',
    download: 'Fazer download',
    edit: 'Editar',
    delete: 'Excluir',
    details: 'Detalhes',
    addRep: 'Adicionar representante',
    moreOptions: 'Mais opções',
  },
  SolicitationPage: {
    title: 'Solicitações de códigos',
    products: 'Produtos',
    partitions: 'Colmeias',
    volumes: 'Volumes',
    impressPrint: 'Impressão',
    tableProduct: {
      requestByProduct: 'Solicitação por produto',
      favorites: 'Favoritos',
      products: 'Produtos',
      available: 'Disponíveis',
      print: 'Imprimir',
      generateCodes: 'Gerar códigos',
      more: 'Mais',
    },
    tableVolume: {
      volumeRequest: 'Solicitação por volume',
      amount: 'Quantidade: ',
    },
    addSolicitation: {
      subTitle: 'Nova solicitação',
      cardTitleProduct: 'Gere mais códigos para ',
      cardTitlePartition: 'Nova solicitação de colmeia ',
      labelProduct: 'Produto',
      labelVolume: 'Volume',
      selectProduct: 'Selecione o produto ...',
      selectVolume: 'Selecione o volume ...',
      noProductsFound: 'Nenhum produto encontrado',
      noVolumeFound: 'Nenhum volume encontrado',
      yup: {
        noObservation: 'Informe a descrição da solicitação.',
        required: 'Informe a quantidade de códigos a gerar.',
        amount: 'Informe um número para quantidade de códigos de volumes.',
      },
    },
    addSolicitationModal: {
      title: 'Fazer nova solicitação para ',
    },
    partitionAggregationModal: {
      title: 'Agregação de colmeia',
      yup: {
        volumeRequired: 'Informe um código de volume.',
        productRequired: 'Informe um código de produto.',
      },
    },
    addSolicitationPartitionModal: {
      title: 'Solicitação de colmeia',
    },
    print: {
      selectPrinter: 'Selecione impressora...',
      noPrinterFound: 'Nenhuma impressora encontrada.',
      noCodeAdded: 'Nenhum código adicionado.',
      productPrinting: 'Impressão de produto ',
      partitionPrinting: 'Impressão de colmeia ',
      volumePrinting: 'Impressão de volume ',
      available: 'Disponíveis ',
      volumeOf: 'Volume de',
      availableCode: ' Código(s) disponível(is)',
      yupProduct: {
        requiredLot: 'Informe o lote.',
        requiredObservation: 'Informe a observação desta operação.',
        amountRequired: 'Informe a quantidade de produtos a imprimir.',
        manufacturingRequired: 'Informe a data de fabricação do produto.',
        dueRequired: 'O produto é perecível, informe a data de validade.',
        intrinsicMarkingRequired: 'Informe a marcação intrínseca.',
        printerRequired: 'Escolha uma impressora.',
      },
      error: {
        description: 'Você deve escrever um código.',
        codeLimit: 'Você não pode ultrapassar o limite de agregações.',
        thisCodeOne: 'O código',
        thisCodeTwo: 'já está na lista de códigos.',
        notBelongProduct: 'Esse produto não pertence a este volume.',
        notBelongVolume: 'Esse volume não pertence a este volume.',
        exceedingLimitCodes: 'Quantidade está ultrapassando o limite de',
      },
    },
    filterModal: {
      title: 'Filtrar solicitações',
      subTitleOne: 'Filtrar por status de impressão',
      subTitleTwo: 'Filtrar por tipo',
      subTitleThree: 'Filtrar por data',
    },
    orderModal: {
      title: 'Ordenar solicitações',
      subTitleOne: 'Ordenar por nome',
      subTitleTwo: 'Ordenar por data',
    },
    reprint: {
      title: 'Reimprimir etiqueta de volume',
      yup: {
        codeRequest: 'Informe o código do volume.',
        printerRequest: 'Escolha uma impressora.',
      },
    },
  },

  Volume: {
    title: 'Volumes',
    selectVolumeContent: 'Selecione o conteúdo do volume',
    all: 'Todos',
    total: 'Total: ',
    addVolumes: {
      add: 'Adicionar',
      edit: 'Detalhes',
      cardTitle: 'Adicionar um novo volume',
      products: 'Produtos',
      volumes: 'Volumes',
      selectProduct: 'Selecione o produto...',
      selectVolume: 'Selecione o volume...',
      selectBarcode: 'Selecione o tipo de  de barras...',
    },
    name: 'Nome',
    abbrev: 'Abreviação',
    amount: 'Quantidade',
    codes: 'Códigos disponiveis',
    content: 'Conteúdo',
    date: 'Data',
    actions: 'Mais opções',
    options: {
      details: 'Detalhes',
      codeGenerate: 'Gerar códigos',
      printBox: 'Imprimir caixa',
      rePrint: 'Reimprimir caixa',
    },
  },
  Locale: {
    role: 'Regras',
    user: 'Usuários',
    company: 'Empresa',
    license: 'Licenças',
    shippingCompany: 'Transportadora',
    solicitation: 'Solicitação',
    code: 'Código',
    invoice: 'Nota Fiscal',
    event: 'Movimentação',
    product: 'Produtos',
    printer: 'Impressora',
    report: 'Relatório',
    distributor: 'Distribuidoras',
    gasStation: 'Posto de combustivel',
    distributorFuel: 'Combustiveis da distribuidora',
    subsidiary: 'Filiais',
    fuelStock: 'Estoque de combustiveis',
    employee: 'Funcionários',
    gasStationStock: 'Estoque dos Postos de Combustivel',
    driverClient: 'Clientes da Transportadora',
    driverWork: 'Serviços da Transportadora',
    driverProfile: 'Perfil da Transportadora',
  },
  Error: {
    noDataSolicitation: 'Nenhuma solicitação encontrada.',
    noDataVolume: 'Nenhum volume encontrado',
    noDataProduct: 'Nenhum venda encontrada',
    locationPermission: 'É necessário permitir acesso a sua localização.',
    minorDate: 'A data de vencimento não pode ser inferior a de fabricação.',
    noExistCode: 'Esse código não existe no sistema.',
    unableCode: 'Esse código está impossibilitado de ser impresso.',
    codeNeverPrinted: 'Esse código nunca foi impresso.',
    choosePrinter: 'Escolha uma impressora',
    register: 'Cadastrar',
    previous: 'Anterior',
    next: 'Próxima',
    dProduction: 'Produção diária',
    linkInvoice: 'Vincular NF-e',
    transferInvoice: 'Transferir custódia',
    productPermission: 'Você não possui permissão para adicionar um produto.',
    userAddPermission:
      'Você não possui permissão para cadastrar um funcionário.',
    volumeAddPermission: 'Você não possui permissão para adicionar um volume.',
    addShippingCompanyAddPermission:
      'Você não possui permissão para cadastrar uma transportadora.',
    dailyProductionPermission:
      'Você não possui permissão para gerar um relatório sobre a produção diária.',
    editShippingCompanyAddPermission:
      'Você não possui permissão para cadastrar uma transportadora.',
    volumeEditPermission: 'Você não possui permissão para editar um volume.',
    printerEditPermission:
      'Você não possui permissão para editar uma impressora.',
    userEditPermission: 'Você não possui permissão para editar um usuário.',
    employeeEditPermission:
      'Você não possui permissão para editar um funcionário.',
    clientAddPermission: 'Você não possui permissão para adicionar um cliente.',
    employeeAddPermission:
      'Você não possui permissão para adicionar um funcionário.',
    representativeAddPermission:
      'Você não possui permissão para adicionar um representante.',
    clientEditPermission: 'Você não possui permissão para editar um cliente.',
    representativeEditPermission:
      'Você não possui permissão para editar um representante.',
    typePackageProdcut: 'Informe o tipo de embalagem',
    noBarCode: 'Informe o tipo do código de barras.',
    existProductIdentifier: 'Um produto com esse identificador ja existe.',
    amountProductSolicitation: 'A quantidade solicitada não está disponivel',
    notFoundBarcode: 'Nenhum tipo de código de barras encontrado.',
    serverCommunication: 'Houve um erro na comunicação com o servidor.',
    noAddress: 'Endereço não enconterado',
    noState: 'Nenhum estado encontrado',
    noCity: 'Nenhuma cidade encontrada',
    downloadSucess: 'Arquivo baixado com sucesso',
    oneField: 'Preencha ao menos um campo.',
    deletePermission: 'Você não possui permissão para apagar este item.',
    errorPassword:
      'A senha deve ter uma letra minúscula, uma maiúscula, um número e um caractere especial.',
  },
  Select: {
    statesList: 'Selecione o estado...',
    solicitationSearch: 'Pesquise pela descrição',
    nameSearch: 'Pesquise pelo nome ou E-mail',
    function: 'Selecione a ocupação...',
    noFunction: 'Nenhuma ocupação encontrada.',
    select: 'Selecione...',
    selectPacking: 'Selecione a embalagem',
    selectBarCode: 'Selecione o tipo de código de barras...',
    selectCity: 'Selecione a cidade...',
    noState: 'Nenhum Estado encontrado.',
    noCity: 'Nenhuma cidade encontrada.',
    fuelType: 'Selecione o tipo de combustivel',
    noFuelType: 'Nenhum tipo de combustivel disponivel, cadastre ao menos um',
    subsidiaries: 'Selecione uma filial',
    noSubsidiaries: 'Nenhuma filial cadastrada',
  },
  Navbar: {
    settings: 'Configurações',
    exit: 'Sair',
  },
  Yup: {
    empty: 'Este campo é obrigatório.',
    noName: 'Informe o nome',
    noEmail: 'Informe o e-mail',
    validEmail: 'Deve ser um e-mail válido',
    noPhone: 'Informe o telefone',
    noObservation: 'Informe uma observação para esta operação.',
    noDistributorName: 'Informe o nome da organização a ser cadastrada',
    noCode: 'Informe o código de vinculação',
    noAmount: 'Informe a quantidade.',
    noWarning: 'Informe a advertência desse volume',
    noStackingLimit: 'Informe o limite de empilhamento desse volume',
    noEan: 'Informe o EAN-13 do produto.',
    noDun: 'Informe o DUN-14 da embalagem.',
    noPositive: 'Este número deve ser positivo',
    noInteger: 'Este número deve ser inteiro',
    noDivisible:
      'A quantidade de produtos deve ser divisível pela quantidade de colméias.',
    noBarCode: 'Informe o tipo do código de barras.',
    noPartitions: 'Informe a quantidade de colmeias.',
    noPacking: 'Informe o tipo de embalagem.',
    noCountry: 'Informe o código do país.',
    noFactory: 'Informe o código da fábrica.',
    noWeight: 'Informe a massa do produto.',
    noUnity: 'Informe a unidade de medida de massa.',
    noId: 'Informe o id do produto',
    noUrl: 'Informe a url da impressora.',
    noPort: 'Informe a porta da impressora.',
    noAbbrev: 'A abreviação é obrigatória.',
    noNetWeight: 'Informe o peso líquido.',
    noGrossWeight: 'Informe o peso bruto.',
    noProduct: 'Escolha o produto.',
    noVolume: 'Escolha o volume.',
    minFixed3: 'O tamanho deve ser de 3 caracteres.',
    minFixed4: 'O tamanho deve ser de 4 caracteres.',
    minFixed5: 'O tamanho deve ser de 5 caracteres.',
    min4: 'O tamanho mínimo deve ser de 4 caracteres',
    min5: 'O tamanho mínimo deve ser de 5 caracateres',
    max5: 'O tamanho máximo dev ser de 5 caracteres',
    min8: 'O tamanho mínimo deve ser de 8 caracteres',
    maxFixed15: 'O número informado execede o limite de 15 caracteres.',
    netUnit: 'Informe a unidade de medida do peso líquido.',
    grossUnit: 'Informe a unidade de medida do peso bruto.',
    noCnpj: 'Informe o CNPJ.',
    noCpf: 'Informe o CPF.',
    sizeCnpj: 'CNPJ inválido, digite novamente.',
    sizeCPF: 'CPF inválido, digite novamente.',
    noMunicipalRegistration: 'Informe o registro municipal.',
    noStateRegistration: 'Informe o registro estadual.',
    noCep: 'Informe o CEP',
    noTargetUf: 'Informe o Estado',
    noTargetCity: 'Informe a cidade.',
    noNeighborhood: 'Informe o bairro.',
    noAddress: 'Informe o endereço.',
    noOffice: 'Informe o cargo',
    noContactType: 'Informe o setor',
    noPassword: 'Insira uma senha',
    confirmPassword: 'Confirmar a senha',
    passwordsDontCheck: 'As senhas não conferem',
    minPassword: 'A senha deve ter no mínimo 8 caracteres',
    matchesPassword:
      'A senha deve ter pelo menos uma letra minúscula, uma maiúscula, um número e um caractere especial.',
    requiredField: 'Campo obrigatório',
    noTrade: 'Informe o nome fantasia.',
    companyName: 'Informe a razão social.',
    oldPassword: 'Informe a antiga senha',
    noDriver: 'Informe o nome do motorista.',
    errorEAN: 'Não são permitidos letras em um EAN.',
    driverCPF: 'Informe ao CPF do motorista.',
    errorCPF: 'CPF inválido, digite novamente.',
    errorCNPJ: 'CNPJ inválido, digite novamente.',
    noDestiny: 'Informe o destino.',
    noPlate: 'Informe a placa do veículo.',
    noLocation: 'É necessário permitir acesso a sua localização.',
  },
  AddMoviment: {
    title: 'Movimentação',
    noCode: 'Nenhum código adicionado.',
    options: {
      deaggregation: 'Desagregação de volume',
      location: 'Localização',
      cancel: 'Cancelado',
      lost: 'Extraviado',
      damaged: 'Danificado',
      unlinked: 'Desvinculado de NFe',
      partition: 'Cancelar Colmeia',
    },
  },
  Company: {
    title: 'Sua empresa',
    infoTitle: 'Informações da empresa',
    updateTitle: 'Atualizar dados da empresa',
  },
  User: {
    title: 'Usuários',
    noUser: 'Nenhum usuário encontrado',
    occupation: 'Ocupação:',
    client: 'Cliente',
    role: 'Permissões de usuário',
    actions: 'Mais opções',
    total: 'Total',
    type: 'Tipo',
    register: 'Cadastrar',
    userActive: 'Usuário Ativado',
    userDeactive: 'Usuário Desativado',
    addUser: 'Cadastrar um novo usuário',
    edit: 'Editar',
    orgName: 'Nome do usuário: ',
    active: 'Ativos',
    desable: 'Não Ativos',
    name: 'Nome',
    creat: 'Cadastrado em:',
    infor: 'Informações do usuário',
    permi: 'Informações do usuário',
    email: 'E-mail',
    editUser: 'Editar usuário',
    subsidiaryName: 'Filial',
    disable: {
      part1: 'Tem certeza que deseja ',
      active: 'ativar',
      deactive: 'desativar',
      activation: 'ativação',
      deactivation: 'desativação',
      part2: 'este usuário?',
      part3: 'Esta é uma ação de ',
      part4: 'risco',
      part5: ' Ao confirmar a ',
      part6: ', esta ação',
      part7: 'surtira efeitos diretos na utilização do sistema pelo usuário',
    },
  },
  Distributor: {
    title: 'Distribuidora',
    noData: 'Nenhuma distribuidora encontrada',
    client: 'Cliente',
    total: 'Total',
    type: 'Tipo',
    register: 'Cadastrar',
    add: 'Cadastrar uma nova distribuidora',
    addAdmin: 'Cadastrar um administrador',
    edit: 'Editar',
    orgName: 'Nome do cliente: ',
    name: 'Nome',
    creat: 'Cadastrado em:',
    editDistributor: 'Editar distribuidora',
  },
  Invoice: {
    title: 'Notas fiscais',
    total: 'Total:',
    noMove: 'Sem movimentações',
    aggregated: 'Vinculado',
    transfered: 'Tranferência de Custódia',
    nfe: 'NFe',
    selectCarrier: 'Selecionar a transportadora',
    number: 'Nº de produtos',
    state: 'Estado:',
    date: 'Data',
    invoiceDelivered: 'Entregue',
    invoiceDispatched: 'Despachado',
    actions: 'Mais opções',
    linkInvoice: 'Vincular NF-e',
    details: 'Details',
    importTitle: 'Importação de NF-e',
    aggregation: 'notas agregadas',
    trow: 'Arraste notas fiscais ou clique aqui',
    noMovimets: 'Sem movimentações',
    linked: 'Vinculada',
    transfer: 'Custódia transferida',
    added: 'Adicionada em ',
    productsAmount: 'N ° de produtos:',
    creat: 'Criada em ',
    receiver: 'Destinatário',
    locality: 'Localidade',
    accessKey: 'Chave de acesso',
    name: 'Comprador',
    items: 'Itens',
    ean: 'EAN',
    amount: 'Quantidade',
    price: 'Preço',
    ncm: 'NCM',
    unity: 'Unidade',
    noProduct: 'Nenhum produto encontrado.',
    driverInfo: 'Informações do motorista',
    info: 'Informações da operação',
    importedSucesfully: 'Notas importadas com sucesso.',
    deleteModal: {
      part1: 'Apagar',
      part2: ' Nota Fiscal',
      part3: 'Esta é uma ação de ',
      part4: 'risco',
      part5: ' Tem certeza que deseja ',
      part6: 'excluir',
      part7: 'esta ',
      part8: 'Nota Fiscal?',
    },
    dailyProductionModal: {
      title: 'Produção diária',
      text: 'Deseja fazer o download do arquivo XML da produção diária?',
    },
  },
  Products: {
    title: 'Produtos',
    filter: 'Filtrar por data de cadastro',
    addProducts: 'Cadastre um novo produto',
    register: 'Cadastrar',
    productsCodes: 'Códigos disponiveis',
    noBarCode: 'Sem código de barra',
    addImage: 'Escolha um arquivo',
    total: 'Total: ',
    details: 'Detalhes',
    date: 'Data',
    noData: 'Nenhum produto cadastrado.',
    noPacking: 'Nenhuma embalagem encontrado.',
    name: 'Nome',
    product: 'Produto',
    amount: 'Quantidade',
    actions: 'Mais opções',
    proportions: {
      name: 'Catalisador',
      proportion: 'Proporção',
    },
    options: {
      details: 'Detalhes',
      codeGenerate: 'Gerar Códigos',
      printBox: 'Imprimir Caixa',
      printPartitions: 'Imprimir Colmeias',
      partitionSolicitation: 'Solicitar Códigos nova colmeia',
      aggregatePartition: 'Agregar nova colmeia',
    },
    info: 'Detalhes',
    volumes: {
      drum: 'Tambor',
      barrel: 'Barril',
      bombona: 'Bombona',
      box: 'Caixa',
      bag: 'Saco',
      compositePackaging: 'Embalagem composta',
    },
  },
  Printers: {
    title: 'Impressoras',
    total: 'Total:',
    date: 'Data:',
    name: 'Nome',
    port: 'URL:Porta',
    actions: 'Mais opções',
    noData: 'Nenhuma impressora encontrada.',
    add: 'Adicionar',
    addTitle: 'Adicione uma nova impressora',
    select: 'Selecione se for uma impressora usb',
    noPrinter: 'Nenhuma impressora usb encontrada.',
    edit: 'Editar',
    printer: 'Impressora',
    localPrinter: 'Impressora local',
    delete: {
      part1: 'Tem certeza que deseja',
      part2: 'excluir',
      part3: 'esta impressora?',
      part4: ' Esta é uma ação de',
      part5: 'risco',
      part6: ' Ao confirmar a exclusão, esta ação',
      part7: 'não podera ser desfeita',
    },
  },
  Safety: {
    title: 'Perfil de Acesso',
    list: 'Funções cadastradas',
    details: 'Ver detalhes da função',
    edit: 'Editar função',
    register: 'Cadastrar uma nova função',
    choosePermission: 'Escolha as permissões desta função',
    select: 'Escolha as permissões...',
    noPermission: 'Nenhuma permissão encontrada.',
  },
  Permissions: {
    nothing: 'Nenhuma',
    index: 'Ler',
    store: 'Criar',
    indexStore: 'Ler e criar',
    indexUpdate: 'Ler, atualizar',
    update: 'Ler, atualizar e apagar',
    delete: 'Ler, criar, atualizar e apagar',
  },
  Settings: {
    title: 'Configurações',
    subtitle: 'Atualizar seus dados',
  },
  Report: {
    title: 'Relátorio',
    dateInit: 'Data de início',
    dateEnd: 'Data de fim',
    all: 'Todas',
    codeState: 'Selecione para gerar relatório de estado dos códigos',
    report: 'Gerar relatório',
    reportGenerate: 'Gere um relatório para exibir os dados',
    pdfGenerate: 'Gerar pdf',
  },
  ShippingCompanies: {
    title: 'Transportadoras',
    all: 'Todas',
    register: 'Cadastrar',
    total: 'Total: ',
    type: 'Tipo: ',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    date: 'Data: ',
    details: 'Detalhes',
    noCarriers: 'Nenhuma transportadora cadastrada.',
    newCarrier: 'Cadastre uma nova transportadora',
    company: 'Empresa ou MEI',
    selfuser: 'Autônomo',
    edit: 'Editar',
    companyName: 'Razão Social/Nome',
    document: 'CNPJ/CPF',
    actions: 'Mais opções',
  },
  Tracking: {
    title: 'Rastreabilidade',
    noData: 'Não houveram movimentações recentes.',
  },
  Clients: {
    title: 'Clientes',
    add: 'Cadastrar',
    addClient: 'Cadastre um novo Cliente',
    registerRepresentative: 'Cadastre um Representante',
    representative: 'Representante',
    all: 'Todos',
    noData: 'Nenhum cliente cadastrado',
    date: 'Data: ',
    name: 'Razão Social/Nome',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    edit: 'Editar',
    total: 'Total: ',
    details: 'Detalhes',
    addRepresentative: 'Adicionar representante',
    editRepresentative: 'Editar representante',
    noRepresentative: 'Não adicionado',
    editClient: 'Editar cliente',
    activeClient: 'Ativar cliente',
    deactiveClient: 'Desativar cliente',
  },
  Employee: {
    title: 'Funcionários',
    register: 'Cadastrar',
    noData: 'Nenhum funcionário encontrado',
    add: 'Cadastrar novo funcionário',
    editEmployee: 'Editar funcionário',
    activeEmployee: 'Ativar funcionário',
    deactiveEmployee: 'Desativar funcionário',
  },
  BackEndErrors: {
    // module users errors
    you_do_not_have_permission_to_enable_or_disable_a_user:
      'Você não tem permissão para ativar ou desativar um funcionário.',
    the_company_must_be_activated_in_order_to_register_a_user:
      'A empresa deve estar ativada para cadastrar um funcionário.',
    email_already_registered: 'Email já foi registrado.',
    you_have_exceeded_the_limit_of_active_users_upgrade_your_plan_to_register_more:
      'Você excedeu o limite de funcionários ativos, atualize seu plano para cadastrar mais.',
    the_company_exceeded_the_activate_users_limit_please_upgrade_your_plan:
      'A empresa excedeu o limite de funcionários ativos. Por favor, atualize seu plano.',
    user_with_this_email_already_registered:
      'Funcionário com este e-mail já existe.',
    user_not_found: 'Funcionário não encontrado.',
    you_cant_change_your_password_without_inform_your_old_password:
      'Você não pode alterar sua senha sem informar a sua senha antiga.',
    incorrect_password: 'Senha incorreta.',
    token_already_used: 'Token já foi usado.',
    token_expired: 'Token expirado.',
    token_not_found: 'Token não encontrado.',
    user_disabled: 'Funcionário desativado.',
    // company module
    company_not_found: 'Empresa não encontrada.',
    // solicitation module
    amount_not_available: 'Quantidade não disponível.',
    this_code_is_already_aggregated_to_an_invoice:
      'Este código já foi agregado a uma nota fiscal.',
    code_not_found: 'Código não encontrado.',
    invalid_code: 'Código inválido',
    the_code_does_not_belong_to_a_volume: 'O código não pertence a um volume.',
    solicitation_not_found: 'Solicitação não encontrada',

    'Insufficient partition codes.': 'Códigos de partições insuficientes.',
    'Insufficient volume codes.': 'Códigos de volumes insuficientes.',
    'Insufficient product codes.': 'Códigos de produtos insuficientes.',
    'Internal server error.': 'Erro interno do servidor',
    'Your license expired. Please, upgrade your plan to get access again.':
      'Sua licensa expirou, por favor atualize seu plano de acesso.',
    'Your license offline time expired. Please, connect you server in internet to get access again.':
      'Sua licensa offline expirou, por favor conecte a internet para ter acesso novamente.',
    'Access denied.': 'Acesso negado.',
    'Validation error.': 'Erro na validação.',
    'No invoice aggregate with this code.':
      'Não existe nota fiscal vinculada a este código.',
    'It is not possible to delete an invoice already aggregated.':
      'Não é possível excluir uma nota fiscal já agregada.',
    'Invoice not found.': 'Nota fiscal não encontrada.',
    'Printer not found': 'Impressora não encontrada.',
    'A product with this PCE has already been registered.':
      'Um produto com esse PCE já foi registrado.',
    'A product with this EAN-13 has already been registered.':
      'Um produto com esse ean já esta registrado.',
    'Product not found.': 'Produto não encontrado.',
    'Permission not found.': 'Permissão não encontrada.',
    'A rule with this name has already been created.':
      'Uma regra com este nome já foi criada.',
    'There is already a shipping company with this CNPJ.':
      'Já existe uma transportadora com este CNPJ.',
    'There is already a shipping company with this CPF.':
      'Já existe uma transportadora com este CPF.',
    'Shipping company not found.': 'Transportadora não encontrada.',
    'Codes not found.': 'Códigos não encontrados.',
    'Only products solicitations allowed':
      'Somente solicitações de produto são permitidas.',
    'Solicitation already printed.': 'Solicitação já impressa.',
    'Solicitation not printed.': 'Solicitação não impressa.',
    'A volume with this abbreviation has already been created.':
      'Um volume com essa abreviação já foi criado.',
    'Volume not found.': 'Volume não encontrado.',
    'A volume with this has already been created.':
      'Um volume com esse DUN-14 já foi criado.',
    'No files uploaded': 'Os arquivos não foram enviados.',
    'Unknown permission.': 'Permissão desconhecida.',
    'This invoice was already transferred.':
      'Esta nota fiscal já foi transferida.',
    'Old password is incorrect.': 'Senha antiga incorreta.',
    'Only products requests allowed': 'Apenas pedidos de produtos permitidos',
    'You cannot change your password without informing your old password.':
      'Você não pode alterar sua senha sem informar sua senha antiga.',
    'The company has exceeded the activate users limit. Please, upgrade your plan.':
      'A empresa excedeu o limite de ativação dos funcionários. Por favor, atualize seu plano.',
    'The company must be activated in order to register an user.':
      'A empresa deve ser acionada para registrar um funcionário.',
    'You do not have permission to enable or disable an user.':
      'Você não tem permissão para habilitar ou desativar um funcionário.',
    'Your license expired. Please upgrade your plan to get access again.':
      'Sua licença expirou. Por favor, atualize seu plano para ter acesso novamente.',
    code_not_active: 'Código não ativado',
    code_not_print: 'Código não impresso',
    no_event_found: 'Evento não encontrado',
    code_already_active: 'Código já ativo',
    code_already_active_aggregation: 'Código já ativo para agregação',
    code_not_already_active_aggregation: 'Código não ativo para agregação',
    code_does_not_belong_aggregation: 'Código não pertence a agregação',
    code_is_not_valid: 'Código inexistente',
    code_already_marked: 'Código já impresso',
    client_document_already_exist:
      'Um cliente já está cadastrado com este documento.',
  },
  Modal: {
    userFilter: 'Filtrar funcionários',
    userOrder: 'Ordernar funcionários',
    nameOrder: 'Ordem alfabética',
    mailOrder: 'Ordernar por email',
    dateFilter: 'Filtrar por data: ',
    unBlock: 'Desbloquear',
    registerFilter: 'Filtrar por data de cadastro',
  },
  PrintTest: {
    title: 'Teste de impressão de produtos',
    text: 'Impressão de etiquetas',
  },
  sideBar: {
    moviments: 'Movimentação',
    tracking: 'Rastreabilidade',
    status: 'Status',
    statusList: 'Listar',
    statusAdd: 'Adicionar',
    distributorFuelsAdd: 'Adicionar',
    volumes: 'Volumes',
    volumesList: 'Listar',
    volumesAdd: 'Adicionar novo',
    testPrint: 'Teste de impressão',
    shippingCompanies: 'Transportadoras',
    shippingCompaniesList: 'Listar',
    shippingCompaniesAdd: 'Cadastrar',
    invoices: 'Notas Fiscais',
    invoicesList: 'Listar',
    invoicesAdd: 'Importar',
    printers: 'Impressoras',
    printersList: 'Listar',
    printersAdd: 'Adicionar',
    report: 'Relatório',
    users: 'Usuários',
    distributors: 'Distribuidoras',
    usersList: 'Listar',
    usersAdd: 'Adicionar',
    vehicles: 'Veículos',
    vehiclesList: 'Listar',
    vehiclesAdd: 'Adicionar',
    racks: 'Racks',
    racksList: 'Listar',
    racksAdd: 'Adicionar',
    settingsRacks: 'Configurar Rack',
    conductors: 'Condutores',
    conductorsList: 'Listar',
    conductorsAdd: 'Adicionar',
    distributorsList: 'Listar',
    distributorsAdd: 'Cadastrar',
    company: 'Empresa',
    security: 'Perfil de Acesso',
    settings: 'Configurações',
    clients: 'Clientes',
    clientsAdd: 'Adicionar',
    clientsList: 'Listar usuários de clientes',
    employee: 'Fucionários',
    employeesAdd: 'Adicionar',
    employeesList: 'Listar',
    subsidiaryStock: 'Controle de estoque',
    gasStations: 'Postos de Combustivel',
    resources: 'Recursos',
    resourcesAdd: 'Adicionar',
    resourcesList: 'Listar',
    resourcesEdit: 'Edição',
    clientList: 'Listar',
    clientAdd: 'Adicionar',
    codes: 'Códigos',
    due: 'Vencimento',
    inspection: 'Inspeção',
    events: 'Eventos',
    list: 'Listar',
    add: 'Adicionar',
    gas: 'Matéria Prima',
    gasList: 'Listar',
    gasAdd: 'Adicionar',
    storages: 'Armazenamento',
    storagesList: 'Listar',
    storagesAdd: 'Adicionar',
    settingsPurpose: 'Configurar Finalidade',
  },
  Success: {
    fuelTypeAdd: 'Tipo de combustivel adicionado com sucesso!',
    distributorFuelsAdd: 'Combustivel adicionado com sucesso!',
    companyUpdate: 'Empresa atualizada com sucesso!',
    completeSearch: 'Busca feita com successo',
    registerUser: 'Funcionário cadastrado com sucesso.',
    userUpdate: 'Usuário atualizado.',
    invoiceLinked: 'NF-e agregada.',
    invoiceTransfered: 'Custódia da NF-e tranferida para o veículo de placa',
    invoiceDelete: 'Nota fiscal excluída.',
    moveError: 'Ocorreu um erro ao fazer a movimentação, código não encontrado',
    moveSuccess: 'Movimentação realizada.',
    printDone: 'Impressão realizada.',
    error: 'Erro',
    inCode: 'no código',
    rePrintDone: 'Reimpressão realizada.',
    codesError: 'Erro nos seguintes códigos: ',
    printerCreated: 'Impressora criada.',
    printerUpdate: 'Impressora atualizada.',
    printerDelete: 'Impressora excluída.',
    productAdd: 'Produto adicionado.',
    productUpdate: 'Produto atualizado.',
    newOcupation: 'Função criada.',
    updateRole: 'Regras atualizadas com sucesso.',
    carrierRegister: 'Transportadora cadastrada.',
    carrierUpdate: 'Transportadora atualizada.',
    solicitationDone: 'Solicitação feita.',
    aggregationDone: 'Agregação feita.',
    successUpdate: 'Dados atualizados com sucesso.',
    successRegister: 'Cadastro realizado com sucesso',
    recoveryEmail:
      'Um link de recuperação de senha foi enviado para o seu e-mail.',
    passwordRecovery: 'Senha recuperada.',
    activeLink: 'Um link de ativação foi enviado para seu e-mail.',
    registerActive: 'Conta ativada com sucesso.',
    volumeAdd: 'Volume adicionado.',
    volumeUpdate: 'Volume atualizado.',
    createClient: 'Cliente criado.',
    updateClient: 'Cliente atualizado.',
    createRepresentative: 'Responsável criado.',
    updateRepresentative: 'Responsável atualizado.',
  },
};

export default locale;
