import api from '@/services/api';
import { PageDTO } from '@/dtos/generics';
import { CreateResourceDTO, ResourceDTO } from '@/dtos/resource';
import UpdateResourceDTO from '@/dtos/resource/UpdateResourceDTO';
import SearchQuery from '@/utils/SearchQuery';

const resourceProvider = {
  async list(filters: SearchQuery): Promise<PageDTO<ResourceDTO>> {
    const { data } = await api.get<PageDTO<ResourceDTO>>(`/resources`, {
      params: filters.query,
    });
    return data;
  },
  async getById(id: string): Promise<ResourceDTO> {
    const { data } = await api.get<ResourceDTO>(`/resources/${id}`);
    return data;
  },
  async create(resource: CreateResourceDTO): Promise<ResourceDTO> {
    const { data } = await api.post<ResourceDTO>('/resources', resource);
    return data;
  },
  async update(resource: UpdateResourceDTO): Promise<ResourceDTO> {
    const { data } = await api.put<ResourceDTO>(
      `/resources/${resource.id}`,
      resource
    );
    return data;
  },
  async active(resourceId: string): Promise<void> {
    await api.put(`/resources/${resourceId}/active`);
  },
  async find(resourceId: string): Promise<ResourceDTO> {
    const { data } = await api.get<ResourceDTO>(`/resources/${resourceId}`);
    return data;
  },
  async delete(resource: string): Promise<void> {
    await api.delete(`/resources/${resource}`);
  },
};

export default resourceProvider;
