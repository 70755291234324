import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import rackServices from '@/lib/rack';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import RackDTO from '@/dtos/rack/RackDTO';
import RackFiltersDTO from '@/dtos/rack/RackFiltersDTO';
import RacksActions, { RacksTypes } from './duck';
import { PageDTO } from '@/dtos/generics';
import { RackListResultDTO } from '@/dtos/rack';
import SearchQuery from '@/utils/SearchQuery';

const registerRack = i18next.t('Success.successRegister');
const rackUpdate = i18next.t('Success.successUpdate');

type AddRackParams = ReturnType<typeof RacksActions.addRackRequest>;
export function* addRack({ payload }: AddRackParams): Generator {
  try {
    yield call(rackServices.create, payload.data);
    yield put(RacksActions.addRackSuccess());
    history.push('/racks');
    toast.success(registerRack);
  } catch (err) {
    const error = handleError(err);
    yield put(RacksActions.addRackError(error));
  }
}

type GetRacksParams = ReturnType<typeof RacksActions.getRacksRequest>;
export function* getRacks({ payload }: GetRacksParams): Generator {
  try {
    const data = yield call(rackServices.listWithDTO, payload.filters);
    yield put(RacksActions.getRacksSuccess(data as RackListResultDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(RacksActions.getRacksError(error));
  }
}

type ListParams = ReturnType<typeof RacksActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const data = yield call(rackServices.list, payload.filters);
    yield put(RacksActions.listSuccess(data as PageDTO<RackDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(RacksActions.listError(error));
  }
}

type GetOneRackParams = ReturnType<typeof RacksActions.getOneRackRequest>;
export function* getOneRack({ payload }: GetOneRackParams): Generator {
  try {
    const data = yield call(rackServices.find, payload.id);
    yield put(RacksActions.getOneRackSuccess(data as RackDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(RacksActions.getOneRackError(error));
  }
}

type UpdateRackParams = ReturnType<typeof RacksActions.updateRequest>;
export function* update({ payload }: UpdateRackParams): Generator {
  try {
    const data = yield call(rackServices.update, payload);
    const rack = data as RackDTO;
    yield put(RacksActions.updateSuccess());
    yield put(RacksActions.getRacksRequest({} as RackFiltersDTO));
    yield put(RacksActions.getOneRackRequest(rack.id));
    toast.success(rackUpdate);
  } catch (err) {
    const error = handleError(err);
    yield put(RacksActions.updateError(error));
  }
}

type ChangeActiveParams = ReturnType<typeof RacksActions.changeActiveRequest>;
export function* changeActive({ payload }: ChangeActiveParams): Generator {
  try {
    yield call(rackServices.changeActive, payload);
    const result = yield call(rackServices.list, SearchQuery.build());
    yield put(RacksActions.listSuccess(result as PageDTO<RackDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(RacksActions.changeActiveError(errors));
  }
}

export default all([
  takeLatest(RacksTypes.ADD_RACK_REQUEST, addRack),
  takeLatest(RacksTypes.LIST_REQUEST, list),
  takeLatest(RacksTypes.GET_RACKS_REQUEST, getRacks),
  takeLatest(RacksTypes.GET_ONE_RACK_REQUEST, getOneRack),
  takeLatest(RacksTypes.UPDATE_REQUEST, update),
  takeLatest(RacksTypes.CHANGE_ACTIVE_REQUEST, changeActive),
]);
