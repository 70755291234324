import SendEventDTO from '@/dtos/events/SendEventDTO';

type AddEventParam = Pick<SendEventDTO, 'code' | 'event' | 'name'>;

type GenericEventParam = Partial<SendEventDTO> & Pick<SendEventDTO, 'code'>;

export type SetParams = Partial<SendEventDTO> &
  Pick<SendEventDTO, 'observation' | 'lat' | 'lng'>;

type RegisterEvent = Pick<SendEventDTO, 'name' | 'event'> & {
  call: string;
};

class EventHandler {
  private data: SendEventDTO[];

  public params: SetParams;

  private registerEvents: RegisterEvent[];

  private lineNumber: number;

  constructor() {
    this.registerEvents = [];
    this.data = [];
    this.params = {} as SetParams;
    this.lineNumber = 0;
  }

  get events(): SendEventDTO[] {
    return this.data;
  }

  static build(): EventHandler {
    return new EventHandler();
  }

  setParams(params: SetParams): this {
    this.params = params;
    return this;
  }

  addRegisterEvent(event: RegisterEvent): this {
    this.registerEvents.push(event);
    return this;
  }

  findRegisterEvent(event: string): RegisterEvent | undefined {
    return this.registerEvents.find((item) => item.call === event);
  }

  addEvent(event: AddEventParam): this {
    this.lineNumber += 1;
    this.data.push({
      ...this.params,
      ...event,
      lineNumber: this.lineNumber,
    });
    return this;
  }

  getByLine(lineNumber: number, data = this.data): SendEventDTO | undefined {
    if (lineNumber > this.lineNumber) return undefined;
    return data.find((event) => {
      if (event.lineNumber === lineNumber) return event;
      if (event.events) return this.getByLine(lineNumber, event.events);
      return undefined;
    });
  }

  from(events: SendEventDTO[]): this {
    const eventsUpdatedLines = this.updateLines(events);
    this.data = [...this.data, ...eventsUpdatedLines];
    return this;
  }

  private updateLines(events: SendEventDTO[]): SendEventDTO[] {
    return events.map((item) => {
      this.lineNumber += 1;
      const event = {
        ...item,
        ...this.params,
        lineNumber: this.lineNumber,
      };
      if (event.events?.length) {
        event.events = this.updateLines(event.events);
      }
      return event;
    });
  }

  clone(): EventHandler {
    return EventHandler.build().setParams(this.params).from(this.events);
  }

  print(event: GenericEventParam): this {
    return this.addEvent({
      ...event,
      event: 11,
      name: 'Impressão',
    });
  }

  activate(event: GenericEventParam): this {
    return this.addEvent({
      ...event,
      event: 2,
      name: 'Ativado',
    });
  }

  block(event: GenericEventParam): this {
    return this.addEvent({
      ...event,
      event: 17,
      name: 'Bloqueado',
    });
  }

  receive(event: GenericEventParam): this {
    return this.addEvent({
      ...event,
      event: 18,
      name: 'Recebimento',
    });
  }

  cargoShipment(event: GenericEventParam): this {
    return this.addEvent({
      ...event,
      event: 19,
      name: 'Carga Embarcada',
    });
  }

  linkInvoice(event: GenericEventParam): this {
    return this.addEvent({
      ...event,
      event: 3,
      name: 'Nota Fiscal Vinculada',
    });
  }
}

export default EventHandler;
