import { all, takeLatest, call, put } from 'redux-saga/effects';

import statusServices from '@/lib/status';
import handleError from '@/adapters/httpErrors';
import { StatusDTO, StatusFieldDTO } from '@/dtos/status';
import { PageDTO } from '@/dtos/generics';
import history from '@/services/history';
import SearchQuery from '@/utils/SearchQuery';

import StatusActions, { StatusTypes } from './duck';

export function* getStatus(): Generator {
  try {
    const data = yield call(statusServices.list, SearchQuery.build());
    yield put(StatusActions.listSuccess(data as PageDTO<StatusDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.listError(error));
  }
}

type ListAllParams = ReturnType<typeof StatusActions.listRequest>;
export function* list({ payload }: ListAllParams): Generator {
  try {
    const data = yield call(statusServices.list, payload);
    yield put(StatusActions.listSuccess(data as PageDTO<StatusDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.listError(error));
  }
}

export function* getStatusFields(): Generator {
  try {
    const data = yield call(statusServices.getStatusFields);
    yield put(StatusActions.getStatusFieldsSuccess(data as StatusFieldDTO[]));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.getStatusFieldsError(error));
  }
}

type GetParams = ReturnType<typeof StatusActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const status = yield call(statusServices.get, payload);
    yield put(StatusActions.getSuccess(status as StatusDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.getError(error));
  }
}

type AddParams = ReturnType<typeof StatusActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(statusServices.create, payload);
    yield put(StatusActions.addSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof StatusActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(statusServices.update, payload);
    yield put(StatusActions.updateSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.updateError(error));
  }
}

type ActiveParams = ReturnType<typeof StatusActions.activeRequest>;
export function* active({ payload }: ActiveParams): Generator {
  try {
    yield call(statusServices.active, payload);
    const result = yield call(statusServices.list, SearchQuery.build());
    yield put(StatusActions.listSuccess(result as PageDTO<StatusDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(StatusActions.activeError(errors));
  }
}

type DeleteParams = ReturnType<typeof StatusActions.deleteRequest>;
export function* del({ payload }: DeleteParams): Generator {
  try {
    yield call(statusServices.delete, payload);
    const result = yield call(statusServices.list, SearchQuery.build());
    yield put(StatusActions.listSuccess(result as PageDTO<StatusDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(StatusActions.deleteError(errors));
  }
}

export default all([
  takeLatest(StatusTypes.LIST_REQUEST, list),
  takeLatest(StatusTypes.GET_STATUS_FIELDS_REQUEST, getStatusFields),
  takeLatest(StatusTypes.GET_REQUEST, get),
  takeLatest(StatusTypes.ADD_REQUEST, add),
  takeLatest(StatusTypes.UPDATE_REQUEST, update),
  takeLatest(StatusTypes.ACTIVE_REQUEST, active),
  takeLatest(StatusTypes.DELETE_REQUEST, del),
]);
