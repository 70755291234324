import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { CodesState } from './types';
import CodeDTO from '@/dtos/solicitations/CodeDTO';
import PageDTO from '@/dtos/generics/PageDTO';
import SearchQuery from '@/utils/SearchQuery';

export enum CodesTypes {
  SET_FILTERS = '@codes/SET_FILTERS',
  LIST_REQUEST = '@codes/listRequest',
  LIST_SUCCESS = '@codes/listSuccess',
  LIST_ERROR = '@codes/listError',
  CLEAR_EVENTS = '@codes/clearEvents',
  CLEAR_SELECTED = '@codes/clearSelected',
  GET_CODES_BY_SOLICITATION_REQUEST = '@codes/getCodesBySolicitationRequest',
  GET_CODES_BY_SOLICITATION_SUCCESS = '@codes/getCodesBySolicitationSuccess',
  GET_CODES_BY_SOLICITATION_ERROR = '@codes/getCodesBySolicitationError',
  DELETE_REQUEST = '@codes/DELETE_REQUEST',
  DELETE_SUCCESS = '@codes/DELETE_SUCCESS',
  DELETE_ERROR = '@codes/DELETE_ERROR',
  GET_CODE_REQUEST = '@codes/getCodeRequest',
  GET_CODE_SUCCESS = '@codes/getCodeSuccess',
  GET_CODE_ERROR = '@codes/getCodeError',
  SET_SOLICITATION = '@codes/setSolicitation',
}
const CodesActions = {
  setFilters: (filters: SearchQuery) =>
    action(CodesTypes.SET_FILTERS, { filters }),
  listRequest: (filters: SearchQuery) =>
    action(CodesTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<CodeDTO>) =>
    action(CodesTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(CodesTypes.LIST_ERROR, { error }),
  clearEvents: () => action(CodesTypes.CLEAR_EVENTS),
  clearSelected: () => action(CodesTypes.CLEAR_SELECTED),
  getCodesBySolicitationRequest: (solicitationId: string) =>
    action(CodesTypes.GET_CODES_BY_SOLICITATION_REQUEST, { solicitationId }),
  getCodesBySolicitationSuccess: (data: string[]) =>
    action(CodesTypes.GET_CODES_BY_SOLICITATION_SUCCESS, { data }),
  getCodesBySolicitationError: (error: string) =>
    action(CodesTypes.GET_CODES_BY_SOLICITATION_ERROR, { error }),
  deleteRequest: (id: string) => action(CodesTypes.DELETE_REQUEST, id),
  deleteSuccess: (CodesId: string) =>
    action(CodesTypes.DELETE_SUCCESS, CodesId),
  deleteError: (error: string) => action(CodesTypes.DELETE_ERROR, { error }),
  getCodeRequest: (value: string) =>
    action(CodesTypes.GET_CODE_REQUEST, { value }),
  getCodeSuccess: (code: CodeDTO) =>
    action(CodesTypes.GET_CODE_SUCCESS, { code }),
  getCodeError: (error: string) =>
    action(CodesTypes.GET_CODE_SUCCESS, { error }),
};
export default CodesActions;

const INITIAL_STATE: CodesState = {
  selectedCode: {} as CodeDTO,
  data: [],
  codes: [],
  events: [],
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  next: undefined,
  prev: undefined,
  total: 0,
  users: [],
};

export type CodesReducer<Action extends AnyAction> = Reducer<
  CodesState,
  Action
>;

const getCodesBySolicitationSuccess: CodesReducer<
  ReturnType<typeof CodesActions.getCodesBySolicitationSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  codes: payload.data,
  loading: false,
  error: '',
});

const listSuccess: CodesReducer<ReturnType<typeof CodesActions.listSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const clearEvents: CodesReducer<
  ReturnType<typeof CodesActions.clearEvents>
> = () => INITIAL_STATE;

const setFilters: CodesReducer<ReturnType<typeof CodesActions.setFilters>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  filters: payload.filters,
});

const getCodeSuccess: CodesReducer<
  ReturnType<typeof CodesActions.getCodeSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedCode: payload.code,
  error: '',
  loading: false,
});

const clearSelected: CodesReducer<
  ReturnType<typeof CodesActions.clearSelected>
> = (state = INITIAL_STATE) => ({
  ...state,
  selectedCode: {} as CodeDTO,
});

export const reducer = createReducer<CodesState>(INITIAL_STATE)
  .handleAction(CodesTypes.SET_FILTERS, setFilters)
  .handleAction(CodesTypes.LIST_REQUEST, genericRequest)
  .handleAction(CodesTypes.LIST_SUCCESS, listSuccess)
  .handleAction(CodesTypes.LIST_ERROR, genericError)
  .handleAction(CodesTypes.DELETE_REQUEST, genericRequest)
  .handleAction(CodesTypes.DELETE_SUCCESS, genericSuccess)
  .handleAction(CodesTypes.DELETE_ERROR, genericError)
  .handleAction(CodesTypes.GET_CODE_REQUEST, genericRequest)
  .handleAction(CodesTypes.GET_CODE_SUCCESS, getCodeSuccess)
  .handleAction(CodesTypes.GET_CODE_ERROR, genericError)
  .handleAction(CodesTypes.CLEAR_EVENTS, clearEvents)
  .handleAction(CodesTypes.CLEAR_SELECTED, clearSelected)
  .handleAction(CodesTypes.GET_CODES_BY_SOLICITATION_REQUEST, genericRequest)
  .handleAction(
    CodesTypes.GET_CODES_BY_SOLICITATION_SUCCESS,
    getCodesBySolicitationSuccess
  )
  .handleAction(CodesTypes.GET_CODES_BY_SOLICITATION_ERROR, genericError);
