import { all } from 'redux-saga/effects';

import user from './user/sagas';
import users from './users/sagas';
import codes from './codes/sagas';
import roles from './roles/sagas';
import permissions from './permissions/sagas';
import events from './events/sagas';
import invoices from './invoices/sagas';
import status from './status/sagas';
import resources from './resources/sagas';
import clients from './clients/sagas';
import vehicles from './vehicles/sagas';
import conductors from './conductors/sagas';
import gases from './gas/sagas';
import racks from './racks/sagas';
import storages from './storages/sagas';
import settingRacks from './settingRacks/sagas';
import settingPurposes from './settingPurposes/sagas';
import equipments from './equipments/sagas';
import procedures from './procedures/sagas';
import printers from './printers/sagas';

export default function* rootSaga(): Generator {
  yield all([
    user,
    users,
    codes,
    roles,
    permissions,
    events,
    invoices,
    status,
    resources,
    clients,
    vehicles,
    conductors,
    gases,
    racks,
    storages,
    settingRacks,
    settingPurposes,
    equipments,
    procedures,
    printers,
  ]);
}
