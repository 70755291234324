import styled from 'styled-components';
import ReactSelect, { Theme } from 'react-select';

export type SelectOption<T = string> = {
  label: string;
  value: T;
};

export const Select = styled(ReactSelect).attrs(({ theme, error }) => ({
  theme: (selectTheme: Theme) => ({
    ...selectTheme,
    colors: {
      primary: theme.colors.primary.base,
      primary25: theme.colors.primary.light,
    },
  }),
  styles: {
    control: (base: {}) => ({
      ...base,
      height: 'auto',
      padding: '0 20px',
      fontSize: '1rem',
      borderWidth: '1px',
      borderRadius: theme.sizes.borderRadius,
      borderColor: error ? theme.colors.error : theme.colors.gray.dark, //borda dos campos de seleção
      backgroundColor: theme.colors.background,
      boxShadow: 'unset',
    }),
    valueContainer: (base: {}) => ({
      ...base,
      padding: 0,
      color: theme.colors.black,
    }),
    menu: (base: {}) => ({
      ...base,
      padding: '15px 12px',
      borderWidth: '1px',
      borderRadius: '10px',
      borderColor: theme.colors.gray.base,
      backgroundColor: theme.colors.white,
      zIndex: 99999,
    }),
    menuList: (base: {}) => ({
      ...base,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridAutoRows: 'min-content',
      rowGap: '1rem',
    }),
    option: (base: {}, props: { isSelected: boolean; isFocused: boolean }) => ({
      ...base,
      backgroundColor:
        props.isSelected || props.isFocused
          ? theme.colors.primary.light
          : 'transparent',
      borderRadius: theme.sizes.borderRadius,
      color: theme.colors.primary.base,
      fontWeight: props.isSelected || props.isFocused ? 'bold' : 'normal',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: theme.colors.primary.light,
      },
    }),
  },
}))``;
