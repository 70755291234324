import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import storageProvider from '@/lib/storages';
import { PageDTO } from '@/dtos/generics';
import { StorageDTO } from '@/dtos/storages';
import StorageActions, { StorageTypes } from './duck';
import history from '@/services/history';
import SearchQuery from '@/utils/SearchQuery';

type SignUpParams = ReturnType<typeof StorageActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(storageProvider.list, payload.filters);
    yield put(StorageActions.listSuccess(result as PageDTO<StorageDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(StorageActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof StorageActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(storageProvider.create, payload.data);
    yield put(StorageActions.addSuccess());
    history.push('/storages');
  } catch (err) {
    const errors = handleError(err);
    yield put(StorageActions.addError(errors));
  }
}

type DeleteParams = ReturnType<typeof StorageActions.deleteRequest>;
export function* del({ payload }: DeleteParams): Generator {
  try {
    yield call(storageProvider.delete, payload);
    const result = yield call(storageProvider.list, SearchQuery.build());
    yield put(StorageActions.listSuccess(result as PageDTO<StorageDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(StorageActions.deleteError(errors));
  }
}

type UpdateParams = ReturnType<typeof StorageActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(storageProvider.update, payload);
    yield put(StorageActions.updateSuccess());
    history.push('/storages');
  } catch (err) {
    const errors = handleError(err);
    yield put(StorageActions.updateError(errors));
  }
}

type ActiveParams = ReturnType<typeof StorageActions.activeRequest>;
export function* active({ payload }: ActiveParams): Generator {
  try {
    yield call(storageProvider.active, payload);
    const result = yield call(storageProvider.list, SearchQuery.build());
    yield put(StorageActions.listSuccess(result as PageDTO<StorageDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(StorageActions.activeError(errors));
  }
}

type GetParams = ReturnType<typeof StorageActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(storageProvider.getById, payload);
    yield put(StorageActions.getSuccess(data as StorageDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(StorageActions.getError(errors));
  }
}

export default all([
  takeLatest(StorageTypes.LIST_REQUEST, list),
  takeLatest(StorageTypes.ADD_REQUEST, add),
  takeLatest(StorageTypes.UPDATE_REQUEST, update),
  takeLatest(StorageTypes.ACTIVE_REQUEST, active),
  takeLatest(StorageTypes.DELETE_REQUEST, del),
  takeLatest(StorageTypes.GET_REQUEST, get),
]);
