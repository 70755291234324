import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import UserDTO from '@/dtos/user/UserDTO';
import UserListResultDTO from '@/dtos/user/UserListResultDTO';
import UserFiltersDTO from '@/dtos/user/UserFiltersDTO';
import { AddUserDTO, ChangeActiveDTO, UpdateUserDTO } from '@/dtos/user';
import { genericError, genericRequest, genericSuccess } from '../utils';
import { UsersState } from './types';

export enum UsersTypes {
  ADD_USER_REQUEST = '@users/addUserRequest',
  ADD_USER_SUCCESS = '@users/addUserSuccess',
  ADD_USER_ERROR = '@users/addUserError',
  GET_ONE_USER_REQUEST = '@users/getOneUserRequest',
  GET_ONE_USER_SUCCESS = '@users/getOneUserSuccess',
  GET_ONE_USER_ERROR = '@users/getOneUserError',
  UPDATE_REQUEST = '@users/updateRequest',
  UPDATE_SUCCESS = '@users/updateSuccess',
  UPDATE_ERROR = '@users/updateError',
  GET_USERS_REQUEST = '@users/getUsersRequest',
  GET_USERS_SUCCESS = '@users/getUsersSuccess',
  GET_USERS_ERROR = '@users/getUsersError',
  GET_CLIENTS_REQUEST = '@users/getClientsRequest',
  GET_CLIENTS_SUCCESS = '@users/getClientsSuccess',
  GET_CLIENTS_ERROR = '@users/getClientsError',
  CHANGE_ACTIVE_REQUEST = '@users/changeActiveRequest',
  CHANGE_ACTIVE_SUCCESS = '@users/changeActiveSuccess',
  CHANGE_ACTIVE_ERROR = '@users/changeActiveError',
  SET_PAGE = '@users/setPage',
  CLEAR_USER = '@users/clearUser',
  SET_FILTERS_USERS = '@users/setFiltersUsers',
}

const UsersActions = {
  addUserRequest: (data: AddUserDTO) =>
    action(UsersTypes.ADD_USER_REQUEST, { data }),
  addUserSuccess: () => action(UsersTypes.ADD_USER_SUCCESS),
  addUserError: (error: string) => action(UsersTypes.ADD_USER_ERROR, { error }),
  getOneUserRequest: (id: string) =>
    action(UsersTypes.GET_ONE_USER_REQUEST, { id }),
  getOneUserSuccess: (user: UserDTO) =>
    action(UsersTypes.GET_ONE_USER_SUCCESS, { user }),
  getOneUserError: (error: string) =>
    action(UsersTypes.GET_ONE_USER_ERROR, { error }),
  getUsersRequest: (filters: UserFiltersDTO) =>
    action(UsersTypes.GET_USERS_REQUEST, { filters }),
  getUsersSuccess: (data: UserListResultDTO) =>
    action(UsersTypes.GET_USERS_SUCCESS, data),
  getUsersError: (error: string) =>
    action(UsersTypes.GET_USERS_ERROR, { error }),
  getClientsRequest: (filters: UserFiltersDTO) =>
    action(UsersTypes.GET_CLIENTS_REQUEST, { filters }),
  getClientsSuccess: (data: UserListResultDTO) =>
    action(UsersTypes.GET_CLIENTS_SUCCESS, data),
  getClientsError: (error: string) =>
    action(UsersTypes.GET_CLIENTS_ERROR, { error }),
  updateRequest: (user: UpdateUserDTO) =>
    action(UsersTypes.UPDATE_REQUEST, user),
  updateSuccess: () => action(UsersTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(UsersTypes.UPDATE_ERROR, { error }),
  changeActiveRequest: (data: ChangeActiveDTO) =>
    action(UsersTypes.CHANGE_ACTIVE_REQUEST, data),
  changeActiveSuccess: () => action(UsersTypes.CHANGE_ACTIVE_SUCCESS),
  changeActiveError: (error: string) =>
    action(UsersTypes.CHANGE_ACTIVE_ERROR, { error }),
  setPage: (page: number) => action(UsersTypes.SET_PAGE, { page }),
  clearUser: () => action(UsersTypes.CLEAR_USER),
  setFiltersUsers: (filters: UserFiltersDTO) =>
    action(UsersTypes.SET_FILTERS_USERS, { filters }),
};

export default UsersActions;

const INITIAL_STATE: UsersState = {
  data: [],
  selected: {} as UserDTO,
  loading: false,
  error: '',
  page: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: {
    page: 1,
  } as UserFiltersDTO,
  totalActive: {
    activated: 0,
    disabled: 0,
  },
};

type UsersReducer<Action extends AnyAction> = Reducer<UsersState, Action>;
const getUsersSuccess: UsersReducer<
  ReturnType<typeof UsersActions.getUsersSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  page: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
  totalActive: payload.totalActive,
  // filters: { ...state.filters, page: payload.currentPage },
});

const getClientsSuccess: UsersReducer<
  ReturnType<typeof UsersActions.getClientsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  page: state.page + 1,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
  totalActive: payload.totalActive,
});

const getOneUserSuccess: UsersReducer<
  ReturnType<typeof UsersActions.getOneUserSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  error: '',
  selected: payload.user,
});

const setPage: UsersReducer<ReturnType<typeof UsersActions.setPage>> = (
  state = INITIAL_STATE,
  { payload }
) => {
  return { ...state, page: payload.page };
};

const clearUser: UsersReducer<any> = () => INITIAL_STATE;

// const updateSuccess: UsersReducer<
//   ReturnType<typeof UsersActions.updateSuccess>
// > = (state = INITIAL_STATE, { payload }) => ({
//   ...state,
//   selectedUser: payload.data,
//   loading: false,
//   error: '',
// });

const setFiltersUsers: UsersReducer<
  ReturnType<typeof UsersActions.setFiltersUsers>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<UsersState>(INITIAL_STATE)
  .handleAction(UsersTypes.GET_USERS_REQUEST, genericRequest)
  .handleAction(UsersTypes.GET_USERS_SUCCESS, getUsersSuccess)
  .handleAction(UsersTypes.GET_USERS_ERROR, genericError)
  .handleAction(UsersTypes.GET_CLIENTS_REQUEST, genericRequest)
  .handleAction(UsersTypes.GET_CLIENTS_SUCCESS, getClientsSuccess)
  .handleAction(UsersTypes.GET_CLIENTS_ERROR, genericError)
  .handleAction(UsersTypes.GET_ONE_USER_REQUEST, genericRequest)
  .handleAction(UsersTypes.GET_ONE_USER_SUCCESS, getOneUserSuccess)
  .handleAction(UsersTypes.GET_ONE_USER_ERROR, genericError)
  .handleAction(UsersTypes.ADD_USER_REQUEST, genericRequest)
  .handleAction(UsersTypes.ADD_USER_SUCCESS, genericSuccess)
  .handleAction(UsersTypes.ADD_USER_ERROR, genericError)
  .handleAction(UsersTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(UsersTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(UsersTypes.UPDATE_ERROR, genericError)
  .handleAction(UsersTypes.CHANGE_ACTIVE_REQUEST, genericRequest)
  .handleAction(UsersTypes.CHANGE_ACTIVE_SUCCESS, genericSuccess)
  .handleAction(UsersTypes.CHANGE_ACTIVE_ERROR, genericError)
  .handleAction(UsersTypes.SET_PAGE, setPage)
  .handleAction(UsersTypes.CLEAR_USER, clearUser)
  .handleAction(UsersTypes.SET_FILTERS_USERS, setFiltersUsers);
