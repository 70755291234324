import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import gasProvider from '@/lib/gas';
import { PageDTO } from '@/dtos/generics';
import { GasDTO } from '@/dtos/gas';
import GasActions, { GasTypes } from './duck';
import history from '@/services/history';
import SearchQuery from '@/utils/SearchQuery';
import i18next from 'i18next';
import { toast } from 'react-toastify';

const registerGas = i18next.t('Success.successRegister');
const gasUpdate = i18next.t('Success.successUpdate');

type SignUpParams = ReturnType<typeof GasActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(gasProvider.list, payload.filters);
    yield put(GasActions.listSuccess(result as PageDTO<GasDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(GasActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof GasActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(gasProvider.create, payload.data);
    yield put(GasActions.addSuccess());
    history.push('/gases');
    toast.success(registerGas);
  } catch (err) {
    const errors = handleError(err);
    yield put(GasActions.addError(errors));
  }
}

type DeleteParams = ReturnType<typeof GasActions.deleteRequest>;
export function* del({ payload }: DeleteParams): Generator {
  try {
    yield call(gasProvider.delete, payload);
    const result = yield call(gasProvider.list, SearchQuery.build());
    yield put(GasActions.listSuccess(result as PageDTO<GasDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(GasActions.deleteError(errors));
  }
}

type UpdateParams = ReturnType<typeof GasActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(gasProvider.update, payload);
    yield put(GasActions.updateSuccess());
    history.push('/gases');
    toast.success(gasUpdate);
  } catch (err) {
    const errors = handleError(err);
    yield put(GasActions.updateError(errors));
  }
}

type ActiveParams = ReturnType<typeof GasActions.activeRequest>;
export function* active({ payload }: ActiveParams): Generator {
  try {
    yield call(gasProvider.active, payload);
    const result = yield call(gasProvider.list, SearchQuery.build());
    yield put(GasActions.listSuccess(result as PageDTO<GasDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(GasActions.activeError(errors));
  }
}

type GetParams = ReturnType<typeof GasActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(gasProvider.getById, payload);
    yield put(GasActions.getSuccess(data as GasDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(GasActions.getError(errors));
  }
}

export default all([
  takeLatest(GasTypes.LIST_REQUEST, list),
  takeLatest(GasTypes.ADD_REQUEST, add),
  takeLatest(GasTypes.UPDATE_REQUEST, update),
  takeLatest(GasTypes.ACTIVE_REQUEST, active),
  takeLatest(GasTypes.DELETE_REQUEST, del),
  takeLatest(GasTypes.GET_REQUEST, get),
]);
