import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isBefore, parseISO } from 'date-fns';

import { ApplicationState } from '@/store/types';
import { checkMenuItems } from '@/store/modules/sidebar/duck';
import UserActions from '@/store/modules/user/duck';
import Sidebar from '@/components/Layout/Sidebar';
import Loader from '@/components/Loader';
import Route from '@/routes/Route';
import useCheckPermissionCallback from '@/hooks/useCheckPermissionCallback';

import { Container, Content, Main } from './styles';

// event
const Tracking = lazy(() => import('@/pages/Tracking'));
const AddEvent = lazy(() => import('@/pages/AddEvent'));
const EventsList = lazy(() => import('@/pages/Events/List'));
const EventDetails = lazy(() => import('@/pages/Events/Details'));
// status
const StatusList = lazy(() => import('@/pages/Status/List'));
const StatusSave = lazy(() => import('@/pages/Status/Save'));
const StatusDetails = lazy(() => import('@/pages/Status/Details'));

// users
const UsersList = lazy(() => import('@/pages/Users/List'));
const UsersSave = lazy(() => import('@/pages/Users/Save'));
const UsersEdit = lazy(() => import('@/pages/Users/Edit'));
// vehicles
const VehiclesList = lazy(() => import('@/pages/Vehicles/List'));
const VehiclesSave = lazy(() => import('@/pages/Vehicles/Save'));
const VehiclesEdit = lazy(() => import('@/pages/Vehicles/Edit'));
// Racks
const RacksList = lazy(() => import('@/pages/Racks/List'));
const RacksSave = lazy(() => import('@/pages/Racks/Save'));
const RacksEdit = lazy(() => import('@/pages/Racks/Edit'));
const SettingsRacks = lazy(() => import('@/pages/Racks/SettingsRacks'));
const SettingsPurpose = lazy(() => import('@/pages/Racks/SettingsPurpose'));
// conductors
const ConductorsList = lazy(() => import('@/pages/Conductors/List'));
// const ConductorsSave = lazy(() => import('@/pages/Conductors/Save'));
const ConductorsEdit = lazy(() => import('@/pages/Conductors/Edit'));
// gas
const GasList = lazy(() => import('@/pages/Gas/List'));
const GasSave = lazy(() => import('@/pages/Gas/Save'));
const GasEdit = lazy(() => import('@/pages/Gas/Edit'));
// storage
const StorageList = lazy(() => import('@/pages/Storages/List'));
const StorageSave = lazy(() => import('@/pages/Storages/Save'));
const StorageEdit = lazy(() => import('@/pages/Storages/Edit'));
// clients
const ClientsList = lazy(() => import('@/pages/Clients/List'));
const ClientsSave = lazy(() => import('@/pages/Clients/Save'));
const ClientsEdit = lazy(() => import('@/pages/Clients/Edit'));
// roles
const SafetySave = lazy(() => import('@/pages/Safety/Save'));
const SafetyEdit = lazy(() => import('@/pages/Safety/Edit'));
// invoices
const InvoiceList = lazy(() => import('@/pages/Invoices/List'));
const InvoiceSave = lazy(() => import('@/pages/Invoices/Save'));
const InvoiceDetails = lazy(() => import('@/pages/Invoices/Details'));
// settings
const Settings = lazy(() => import('@/pages/Settings'));

//resources
const ResourceSave = lazy(() => import('@/pages/Resources/Save'));
const ResourceList = lazy(() => import('@/pages/Resources/List'));
const ResourceEdit = lazy(() => import('@/pages/Resources/Edit'));

//codes
const CodesList = lazy(() => import('@/pages/Codes/List'));
const CodesDue = lazy(() => import('@/pages/Codes/Due'));
const CodesInspection = lazy(() => import('@/pages/Codes/Inspection'));
const CodeDetails = lazy(() => import('@/pages/Codes/Details'));

//printer
const PrintersEdit = lazy(() => import('@/pages/Printers/Edit'));
const PrintersList = lazy(() => import('@/pages/Printers/List'));
const PrintersSave = lazy(() => import('@/pages/Printers/Save'));

const AppRoute: React.FC = () => {
  const { t: translation } = useTranslation();
  const checkPermission = useCheckPermissionCallback();
  const items = useSelector((store: ApplicationState) =>
    checkMenuItems(store.sidebar.items, checkPermission)
  );
  const dispatch = useDispatch();
  const sessionDue = useSelector(
    ({ user }: ApplicationState) => user.sessionDue
  );

  const firstPageWithPermission = (): string => {
    const firstPage = items[0];
    const firstPageSubItem = firstPage?.items && firstPage?.items[0];
    return firstPage?.to || firstPageSubItem?.to || '';
  };

  useEffect(() => {
    if (isBefore(new Date(), parseISO(sessionDue))) return;
    dispatch(UserActions.logoutRequest());
  }, [dispatch, sessionDue]);

  return (
    <Container>
      <Sidebar />
      <Main>
        <Content id="main-content">
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to={firstPageWithPermission()} />}
              />
              <Route
                exact
                path="/tracking"
                permission="index:event"
                component={Tracking}
              />
              <Route
                exact
                path="/events/add"
                permission="index:event"
                component={AddEvent}
              />
              <Route
                path="/events/:value/details"
                component={EventDetails}
                permission=""
              />

              <Route exact path="/status" component={StatusList} />
              <Route exact path="/status/add" component={StatusSave} />
              <Route exact path="/status/edit/:id" component={StatusSave} />

              <Route
                exact
                path="/status/details/:id"
                component={StatusDetails}
              />

              <Route exact path="/events" component={EventsList} />

              <Route
                exact
                path="/users"
                permission="index:user"
                component={UsersList}
              />
              <Route
                exact
                path="/users/add"
                permission="store:user"
                component={UsersSave}
              />
              <Route
                exact
                path="/users/:id/edit"
                permission="update:user"
                component={UsersEdit}
              />
              <Route
                exact
                path="/vehicles"
                permission="index:vehicle"
                component={VehiclesList}
              />
              <Route
                exact
                path="/vehicles/add"
                permission="store:vehicle"
                component={VehiclesSave}
              />
              <Route
                exact
                path="/vehicles/:id/edit"
                permission="update:vehicle"
                component={VehiclesEdit}
              />
              <Route
                exact
                path="/racks"
                permission="index:rack"
                component={RacksList}
              />
              <Route
                exact
                path="/racks/add"
                permission="store:rack"
                component={RacksSave}
              />
              <Route
                exact
                path="/racks/settingRacks"
                permission="store:rack"
                component={SettingsRacks}
              />
              <Route
                exact
                path="/racks/settingPurpose"
                permission="store:rack"
                component={SettingsPurpose}
              />
              <Route
                exact
                path="/racks/:id/edit"
                permission="update:rack"
                component={RacksEdit}
              />
              <Route
                exact
                path="/gases"
                permission="index:gas"
                component={GasList}
              />
              <Route
                exact
                path="/gases/add"
                permission="store:gas"
                component={GasSave}
              />
              <Route
                exact
                path="/gases/:id/edit"
                permission="update:gas"
                component={GasEdit}
              />
              <Route
                exact
                path="/storages"
                permission="index:storage"
                component={StorageList}
              />
              <Route
                exact
                path="/storages/add"
                permission="store:storage"
                component={StorageSave}
              />
              <Route
                exact
                path="/storages/:id/edit"
                permission="update:storage"
                component={StorageEdit}
              />
              <Route
                exact
                path="/conductors"
                permission="index:conductor"
                component={ConductorsList}
              />
              <Route
                exact
                path="/conductors/:id/edit"
                permission="update:conductor"
                component={ConductorsEdit}
              />
              <Route
                exact
                path="/printers/add"
                permission="store:printers"
                component={PrintersSave}
              />
              <Route
                exact
                path="/printers"
                permission="store:printers"
                component={PrintersList}
              />
              <Route
                exact
                path="/printers/edit/:id"
                permission="store:printers"
                component={PrintersEdit}
              />
              <Route
                exact
                path="/client"
                permission="index:client"
                component={ClientsList}
              />
              <Route
                path="/client/add"
                permission="store:client"
                component={ClientsSave}
              />
              <Route
                exact
                path="/client/edit/:id"
                component={ClientsEdit}
                permission="update:client"
              />

              <Route path="/codes/due" permission="" component={CodesDue} />
              <Route
                exact
                path="/codes/list"
                component={CodesList}
                permission=""
              />
              <Route
                exact
                path="/codes/inspection"
                component={CodesInspection}
                permission=""
              />
              <Route
                path="/codes/:value/details"
                component={CodeDetails}
                permission=""
              />
              <Route
                exact
                path="/invoices"
                permission="index:invoice"
                component={InvoiceList}
              />
              <Route
                exact
                path="/invoices/:id/details"
                permission="show:invoice"
                component={InvoiceDetails}
              />
              <Route
                exact
                path="/invoices/import"
                permission="store:invoice"
                component={InvoiceSave}
              />
              <Route
                path="/invoices/import/:id"
                permission="store:invoice"
                component={InvoiceSave}
              />
              <Route
                exact
                path="/security"
                permission="index:role"
                component={SafetySave}
              />
              <Route
                path="/security/role/edit/:id"
                permission="update:role"
                component={SafetyEdit}
              />
              <Route exact path="/settings" component={Settings} />
              <Route
                exact
                path="/resources"
                permission="index:resource"
                component={ResourceList}
              />
              <Route
                exact
                path="/resources/add"
                permission="store:resource"
                component={ResourceSave}
              />
              <Route
                exact
                path="/resources/edit/:id"
                component={ResourceEdit}
                permission="update:resource"
              />
              <Route
                component={() => {
                  toast.error(translation('error.validation.page_not_found'));

                  return <Redirect to={firstPageWithPermission()} />;
                }}
              />
            </Switch>
          </Suspense>
        </Content>
      </Main>
    </Container>
  );
};

export default AppRoute;
