import { PageDTO } from '@/dtos/generics';
import ResourceFieldDTO from '@/dtos/resourceField/ResourceFieldDTO';
import { api } from '@/services';

const resourceFieldProvider = {
  async list(): Promise<PageDTO<ResourceFieldDTO>> {
    const { data } = await api.get<PageDTO<ResourceFieldDTO>>(
      `/resource-fields`
    );
    return data;
  },
};
export default resourceFieldProvider;
