import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { SidebarState, SideBarItem } from './types';

export enum SidebarTypes {
  SET_OPEN_ON_MOBILE = '@sidebar/setOpenOnMobile',
  SET_MENU_ITEM = '@sidebar/setMenuItem',
  SET_ITEMS = '@sidebar/setItems',
}
const SidebarActions = {
  setOpenOnMobile: (open: boolean) =>
    action(SidebarTypes.SET_OPEN_ON_MOBILE, { open }),
  setMenuItem: (itemKey: string, parentItemKey?: string) =>
    action(SidebarTypes.SET_MENU_ITEM, { itemKey, parentItemKey }),
};
export default SidebarActions;

const INITIAL_STATE: SidebarState = {
  items: [
    {
      key: 'tracking',
      icon: 'map',
      label: 'sideBar.tracking',
      to: '/tracking',
      permission: 'index:event',
      feature: 'tracking.index',
      active: false,
    },
    /* {
      key: 'event',
      icon: 'layers',
      label: 'sideBar.moviments',
      to: '/events/add',
      permission: 'index:event',
      feature: 'event.index',
      active: false,
       },
    */
    {
      key: 'eventList',
      icon: 'clipboard',
      label: 'sideBar.events',
      permission: '',
      active: false,
      items: [
        {
          key: 'eventsList',
          label: 'sideBar.list',
          to: '/events',
          permission: 'index:event',
          active: false,
        },
      ],
    },
    {
      key: 'codes',
      icon: 'code',
      label: 'sideBar.codes',
      to: '/codes',
      permission: '',
      active: false,
      items: [
        {
          key: 'codesList',
          label: 'sideBar.list',
          to: '/codes/list',
          permission: '',
          active: false,
        },
        {
          key: 'due',
          label: 'sideBar.due',
          to: '/codes/due',
          permission: '',
          feature: 'code.index',
          active: false,
        },
        {
          key: 'inspection',
          label: 'sideBar.inspection',
          to: '/codes/inspection',
          permission: '',
          feature: 'code.index',
          active: false,
        },
      ],
    },
    {
      key: 'invoices',
      icon: 'file-text',
      label: 'sideBar.invoices',
      to: '/invoices',
      permission: '',
      active: false,
      items: [
        {
          key: 'invoicesList',
          label: 'sideBar.invoicesList',
          to: '/invoices',
          permission: 'index:invoice',
          feature: 'invoice.index',
          active: false,
        },
        {
          key: 'invoicesAdd',
          label: 'sideBar.invoicesAdd',
          to: '/invoices/import',
          permission: 'store:invoice',
          feature: 'invoice.store',
          active: false,
        },
      ],
    },
    /*{
      key: 'status',
      icon: 'columns',
      label: 'sideBar.status',
      active: false,
      permission: '',
      items: [
        {
          key: 'statusList',
          label: 'sideBar.statusList',
          to: '/status',
          permission: '',
          feature: 'status.index',
          active: false,
        },
        {
          key: 'statusAdd',
          label: 'sideBar.statusAdd',
          to: '/status/add',
          permission: 'store:resource',
          feature: 'status.store',
          active: false,
        },
      ],
    },
    */
    {
      key: 'resources',
      icon: 'shopping-bag',
      label: 'sideBar.resources',
      to: '/resources',
      permission: 'index:user',
      active: false,
      items: [
        {
          key: 'resourcesList',
          label: 'sideBar.resourcesList',
          to: '/resources',
          permission: 'index:resource',
          feature: 'resource.index',
          active: false,
        },
        {
          key: 'resourcesAdd',
          label: 'sideBar.resourcesAdd',
          to: '/resources/add',
          permission: 'store:resource',
          feature: 'resource.store',
          active: false,
        },
      ],
    },
    {
      key: 'racks',
      icon: 'droplet',
      label: 'sideBar.racks',
      to: '/racks',
      permission: 'index:rack',
      active: false,
      items: [
        {
          key: 'racksList',
          label: 'sideBar.racksList',
          to: '/racks',
          permission: 'index:rack',
          active: true,
        },
        {
          key: 'racksAdd',
          label: 'sideBar.racksAdd',
          to: '/racks/add',
          permission: 'store:rack',
          active: true,
        },
        {
          key: 'settingsRack',
          label: 'sideBar.settingsRacks',
          to: '/racks/settingRacks',
          permission: 'store:rack',
          active: false,
        },
        {
          key: 'settingsPurpose',
          label: 'sideBar.settingsPurpose',
          to: '/racks/settingPurpose',
          permission: 'store:rack',
          active: true,
        },
      ],
    },
    {
      key: 'gases',
      icon: 'wind',
      label: 'sideBar.gas',
      to: '/gases',
      permission: 'index:gas',
      active: false,
      items: [
        {
          key: 'gasesList',
          label: 'sideBar.gasList',
          to: '/gases',
          permission: 'index:gas',
          active: false,
        },
        {
          key: 'gasesAdd',
          label: 'sideBar.gasAdd',
          to: '/gases/add',
          permission: 'store:gas',
          active: false,
        },
      ],
    },
    {
      key: 'storages',
      icon: 'package',
      label: 'sideBar.storages',
      to: '/storages',
      permission: 'index:storage',
      active: false,
      items: [
        {
          key: 'storagesList',
          label: 'sideBar.storagesList',
          to: '/storages',
          permission: 'index:storage',
          active: false,
        },
        {
          key: 'storagesAdd',
          label: 'sideBar.storagesAdd',
          to: '/storages/add',
          permission: 'store:storage',
          active: false,
        },
      ],
    },
    {
      key: 'printer',
      icon: 'printer',
      label: 'page.printers.headerTitle',
      to: '/printers',
      permission: 'index:storage',
      active: false,
      items: [
        {
          key: 'printersList',
          label: 'sideBar.list',
          to: '/printers',
          permission: 'store:printers',
          active: false,
        },
        {
          key: 'printersAdd',
          label: 'sideBar.add',
          to: '/printers/add',
          permission: 'store:printers',
          active: false,
        },
      ],
    },
    {
      key: 'clients',
      icon: 'user-check',
      label: 'sideBar.clients',
      to: '/clients',
      permission: 'index:user',
      active: false,
      items: [
        {
          key: 'clientList',
          label: 'sideBar.clientList',
          to: '/client',
          permission: 'index:client',
          feature: 'client.index',
          active: false,
        },
        {
          key: 'clientAdd',
          label: 'sideBar.clientAdd',
          to: '/client/add',
          permission: 'store:client',
          feature: 'client.store',
          active: false,
        },
      ],
    },
    {
      key: 'vehicles',
      icon: 'truck',
      label: 'sideBar.vehicles',
      to: '/vehicles',
      permission: 'index:vehicle',
      active: false,
      items: [
        {
          key: 'vehiclesList',
          label: 'sideBar.vehiclesList',
          to: '/vehicles',
          permission: 'index:vehicle',
          active: false,
        },
        {
          key: 'vehiclesAdd',
          label: 'sideBar.vehiclesAdd',
          to: '/vehicles/add',
          permission: 'store:vehicle',
          active: false,
        },
      ],
    },
    {
      key: 'conductors',
      icon: 'user-plus',
      label: 'sideBar.conductors',
      to: '/conductors',
      permission: 'index:conductor',
      active: false,
      items: [
        {
          key: 'conductorsList',
          label: 'sideBar.conductorsList',
          to: '/conductors',
          permission: 'index:conductor',
          active: false,
        },
        // {
        //  key: 'conductorsAdd',
        //  label: 'sideBar.conductorsAdd',
        //  to: '/conductors/add',
        //  permission: 'store:conductor',
        //  active: false,
        // },
      ],
    },
    // {
    //   key: 'company',
    //   icon: FiBriefcase,
    //   label: 'sideBar.company',
    //   to: '/company',
    //   permission: 'show:company',
    //   active: false,
    // },
    {
      key: 'security',
      icon: 'shield',
      label: 'sideBar.security',
      to: '/security',
      permission: 'store:role',
      feature: 'security.store',
      active: false,
    },
    {
      key: 'users',
      icon: 'users',
      label: 'sideBar.users',
      to: '/users',
      permission: 'index:user',
      active: false,
      items: [
        {
          key: 'usersList',
          label: 'sideBar.usersList',
          to: '/users',
          permission: 'index:user',
          feature: 'user.index',
          active: false,
        },
        {
          key: 'usersAdd',
          label: 'sideBar.usersAdd',
          to: '/users/add',
          permission: 'store:user',
          feature: 'user.store',
          active: false,
        },
      ],
    },
    {
      key: 'settings',
      icon: 'settings',
      label: 'sideBar.settings',
      to: '/settings',
      permission: '',
      active: false,
    },
  ],
  openOnMobile: false,
  loading: false,
  error: '',
};

export function checkMenuItems(
  items: SideBarItem[],
  checkPermission: (permission: string) => boolean
): SideBarItem[] {
  return items.reduce((accumulator: SideBarItem[], menuItem: SideBarItem) => {
    const notHavePermission =
      menuItem.permission && !checkPermission(menuItem.permission);
    if (notHavePermission) return accumulator;
    if (!menuItem.items) return [...accumulator, menuItem];
    if (!checkMenuItems(menuItem.items, checkPermission).length)
      return accumulator;

    return [
      ...accumulator,
      {
        ...menuItem,
        items: checkMenuItems(menuItem.items, checkPermission),
      },
    ];
  }, [] as SideBarItem[]);
}

type SidebarReducer<Action extends AnyAction> = Reducer<SidebarState, Action>;

const setMenuItem: SidebarReducer<
  ReturnType<typeof SidebarActions.setMenuItem>
> = (state = INITIAL_STATE, { payload }) => {
  const key = payload.parentItemKey || payload.itemKey;
  return {
    ...state,
    items: state.items.map((item) => ({
      ...item,
      active: key === item.key,
      items: item?.items?.map((subItem) => ({
        ...subItem,
        active: subItem.key === payload.itemKey,
      })),
    })),
  };
};

const setOpenOnMobile: SidebarReducer<
  ReturnType<typeof SidebarActions.setOpenOnMobile>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  openOnMobile: payload.open,
  loading: false,
  error: '',
});

export const reducer = createReducer(INITIAL_STATE)
  .handleAction(SidebarTypes.SET_OPEN_ON_MOBILE, setOpenOnMobile)
  .handleAction(SidebarTypes.SET_MENU_ITEM, setMenuItem);
