import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import clientProvider from '@/lib/clients';
import { PageDTO } from '@/dtos/generics';
import { ClientDTO } from '@/dtos/clients';
import { history } from '@/services';

import ClientActions, { ClientTypes } from './duck';
import SearchQuery from '@/utils/SearchQuery';

type ListParams = ReturnType<typeof ClientActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const result = yield call(clientProvider.list, payload.filters);
    yield put(ClientActions.listSuccess(result as PageDTO<ClientDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ClientActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof ClientActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(clientProvider.create, payload);
    yield put(ClientActions.addSuccess());
    history.push('/client');
  } catch (err) {
    const errors = handleError(err);
    yield put(ClientActions.addError(errors));
  }
}

type UpdateParams = ReturnType<typeof ClientActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(clientProvider.update, payload);
    yield put(ClientActions.updateSuccess());
    history.push('/client');
  } catch (err) {
    const errors = handleError(err);
    yield put(ClientActions.updateError(errors));
  }
}

type GetParams = ReturnType<typeof ClientActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const result = yield call(clientProvider.find, payload);
    yield put(ClientActions.getSuccess(result as ClientDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(ClientActions.getError(errors));
  }
}
type DeleteParams = ReturnType<typeof ClientActions.deleteRequest>;
export function* del({ payload }: DeleteParams): Generator {
  try {
    yield call(clientProvider.delete, payload);
    const result = yield call(clientProvider.list, SearchQuery.build());
    yield put(ClientActions.listSuccess(result as PageDTO<ClientDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ClientActions.deleteError(errors));
  }
}

type ActiveParams = ReturnType<typeof ClientActions.activeRequest>;
export function* active({ payload }: ActiveParams): Generator {
  try {
    yield call(clientProvider.active, payload);
    const result = yield call(clientProvider.list, SearchQuery.build());
    yield put(ClientActions.listSuccess(result as PageDTO<ClientDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ClientActions.activeError(errors));
  }
}
export default all([
  takeLatest(ClientTypes.LIST_REQUEST, list),
  takeLatest(ClientTypes.ADD_REQUEST, add),
  takeLatest(ClientTypes.UPDATE_REQUEST, update),
  takeLatest(ClientTypes.GET_REQUEST, get),
  takeLatest(ClientTypes.DELETE_REQUEST, del),
  takeLatest(ClientTypes.ACTIVE_REQUEST, active),
]);
