import { PageDTO } from '@/dtos/generics';
import {
  AddStatusDTO,
  UpdateStatusDTO,
  StatusDTO,
  StatusFieldDTO,
} from '@/dtos/status';
import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';

class StatusServices {
  async create(status: AddStatusDTO): Promise<StatusDTO> {
    const { data } = await api.post<StatusDTO>('/status', status);
    return data;
  }
  async update(status: UpdateStatusDTO): Promise<StatusDTO> {
    const { data } = await api.put<StatusDTO>(`/status/${status.id}`, status);
    return data;
  }

  async list(filters: SearchQuery): Promise<PageDTO<StatusDTO>> {
    const { data } = await api.get<PageDTO<StatusDTO>>(`/status`, {
      params: filters.query,
    });
    return data;
  }

  async get(id: string): Promise<StatusDTO> {
    const { data } = await api.get<StatusDTO>(`/status/${id}`);
    return data;
  }
  async getStatusFields(): Promise<StatusFieldDTO[]> {
    const { data } = await api.get<StatusFieldDTO[]>('/status-fields');
    return data;
  }
  async active(statusId: string): Promise<void> {
    await api.put(`/status/${statusId}/active`);
  }
  async delete(status: string): Promise<void> {
    await api.delete(`/status/${status}/delete`);
  }
}

const statusServices = new StatusServices();

export default statusServices;
