import { Link, LinkProps } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

type ContainerParams = {
  open?: boolean;
};
export const Container = styled.aside<ContainerParams>`
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  padding: 1.8rem 1rem;
  background-color: ${({ theme }) => theme.colors.gray.light};
  z-index: 100;

  @media screen and (max-width: 767px) {
    position: absolute;
    top: 0;
    left: ${(props) => (props.open ? '0%' : '-100%')};
    width: 80vw;
    height: 100vh;
    transition: left 0.2s;

    ${(props) => props.open && 'left: 0%'};
  }
`;

export const Menu = styled.nav`
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  row-gap: 0.6rem;
  overflow-y: scroll;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.sizes.borderRadius};
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f8fc;
  }
`;

type ActiveParams = {
  active?: boolean;
  dropdownOpen?: boolean;
};
const itemsColorsScheme = css<ActiveParams>`
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary.light : 'transparent'};
  color: ${({ active, theme }) =>
    active ? theme.colors.primary.base : theme.colors.black};
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.primary.dark};
    svg {
      color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary.base : theme.colors.black};
  }
`;
export const MenuLink = styled(Link)<ActiveParams>`
  ${itemsColorsScheme}
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: min-content 1fr;
  column-gap: 15px;
  justify-items: left;
  align-items: center;
  padding: 0.6rem;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 16px;
  font-weight: ${({ active }) => (active ? 700 : 400)};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  transition: background-color 0.2s ease-in;
`;

export const DropdownButton = styled.button<ActiveParams>`
  ${itemsColorsScheme}
  width: 100%;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: min-content 1fr min-content;
  column-gap: 15px;
  justify-items: left;
  align-items: center;
  padding: 0.6rem;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 16px;
  font-weight: ${({ active }) => (active ? 700 : 400)};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  transition: background-color 0.2s ease-in;
  > div:last-child > svg {
    transition: 0.1s ease-out;
    transform: ${({ dropdownOpen }) =>
      dropdownOpen ? 'rotate(90deg)' : 'rotate(0)'};
  }
`;

export const DropdownMenu = styled.ul<ActiveParams>`
  display: ${({ active }) => (active ? 'grid' : 'none')};
  grid-template-rows: min-content;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  row-gap: 0.1rem;
  padding: 0;
  margin: 0.3rem 0 0;
  list-style: none;
  animation: ${slideUp} 0.2s ease-in-out;
`;

type DropdownLinkParams = LinkProps & {
  active?: boolean;
};
export const DropdownLink = styled(Link)<DropdownLinkParams>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2px;
  padding: 0.6rem;
  text-decoration: none;
  color: ${(props) =>
    props.active ? props.theme.colors.primary.base : props.theme.colors.black};
  font-size: 14px;
  font-weight: 700;
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  transition: margin-left 0.2s ease-in-out;
  &:hover {
    margin-left: 4px;
  }
`;

export const CopyrightContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;

  img {
    max-width: 60%;
  }
`;
