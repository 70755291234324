import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import handleError from '@/adapters/httpErrors';
import history from '@/services/history';
import printerProvider from '@/lib/printers';
import { PageDTO } from '@/dtos/generics';
import { PrinterDTO } from '@/dtos/printers';
import SearchQuery from '@/utils/SearchQuery';

import PrinterActions, { PrinterTypes } from './duck';
import i18next from 'i18next';

type ListParams = ReturnType<typeof PrinterActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const data = yield call(printerProvider.list, payload.filters);
    yield put(PrinterActions.listSuccess(data as PageDTO<PrinterDTO>));
  } catch (err) {
    const error = handleError(err);
    yield put(PrinterActions.listError(error));
  }
}

type GetParams = ReturnType<typeof PrinterActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(printerProvider.getById, payload);
    yield put(PrinterActions.getSuccess(data as PrinterDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(PrinterActions.getError(error));
  }
}

type AddParams = ReturnType<typeof PrinterActions.addRequest>;
export function* addPrinter({ payload }: AddParams): Generator {
  try {
    yield call(printerProvider.create, payload.data);
    yield put(PrinterActions.addSuccess());
    toast.success(i18next.t('Success.printerCreated'));
    history.push('/printers');
  } catch (err) {
    const error = handleError(err);
    yield put(PrinterActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof PrinterActions.updateRequest>;
export function* updatePrinter({ payload }: UpdateParams): Generator {
  try {
    yield call(printerProvider.update, payload);
    yield put(PrinterActions.updateSuccess());
    yield put(PrinterActions.listRequest(SearchQuery.build()));
    toast.success(i18next.t('Success.printerUpdate'));
  } catch (err) {
    const error = handleError(err);
    yield put(PrinterActions.updateError(error));
  }
}

type DeleteParams = ReturnType<typeof PrinterActions.deleteRequest>;
export function* deletePrinter({ payload }: DeleteParams): Generator {
  try {
    yield call(printerProvider.delete, payload);
    yield put(PrinterActions.deleteSuccess());
    yield put(PrinterActions.listRequest(SearchQuery.build()));
    toast.success(i18next.t('Success.printerDelete'));
  } catch (err) {
    const error = handleError(err);
    yield put(PrinterActions.deleteError(error));
  }
}

export default all([
  takeLatest(PrinterTypes.LIST_REQUEST, list),
  takeLatest(PrinterTypes.ADD_REQUEST, addPrinter),
  takeLatest(PrinterTypes.GET_REQUEST, get),
  takeLatest(PrinterTypes.UPDATE_REQUEST, updatePrinter),
  takeLatest(PrinterTypes.DELETE_REQUEST, deletePrinter),
]);
