import api from '@/services/api';
import { PageDTO } from '@/dtos/generics';
import { AddGasDTO, GasDTO } from '@/dtos/gas';
import UpdateGasDTO from '@/dtos/gas/UpdateGasDTO';
import SearchQuery from '@/utils/SearchQuery';

const gasProvider = {
  async list(filters: SearchQuery): Promise<PageDTO<GasDTO>> {
    const { data } = await api.get<PageDTO<GasDTO>>(`/gases`, {
      params: filters.query,
    });
    return data;
  },
  async getById(id: string): Promise<GasDTO> {
    const { data } = await api.get<GasDTO>(`/gases/${id}`);
    return data;
  },
  async create(gas: AddGasDTO): Promise<GasDTO> {
    const { data } = await api.post<GasDTO>('/gases', gas);
    return data;
  },
  async update(gas: UpdateGasDTO): Promise<GasDTO> {
    const { data } = await api.put<GasDTO>(`/gases/${gas.id}`, gas);
    return data;
  },
  async active(gasId: string): Promise<void> {
    await api.put(`/gases/${gasId}/active`);
  },
  async delete(gas: string): Promise<void> {
    await api.delete(`/gases/${gas}`);
  },
};

export default gasProvider;
