import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { Position } from '@/services/position';

import {
  InvoiceDTO,
  InvoiceFilterDTO,
  InvoiceListResultDTO,
} from '@/dtos/invoices';

import { InvoicesState } from './types';
import { genericError, genericRequest, genericSuccess } from '../utils';

export enum InvoicesTypes {
  GET_INVOICES_REQUEST = '@invoices/getInvoicesRequest',
  GET_INVOICES_SUCCESS = '@invoices/getInvoicesSuccess',
  GET_INVOICES_ERROR = '@invoices/getInvoicesError',
  GET_INVOICE_REQUEST = '@invoices/getInvoiceRequest',
  GET_INVOICE_SUCCESS = '@invoices/getInvoiceSuccess',
  GET_INVOICE_ERROR = '@invoices/getInvoiceError',
  GET_INVOICE_BY_ACCESS_KEY_REQUEST = '@invoices/getInvoiceByAccessKeyRequest',
  GET_INVOICE_BY_ACCESS_KEY_SUCCESS = '@invoices/getInvoiceByAccessKeySuccess',
  GET_INVOICE_BY_ACCESS_KEY_ERROR = '@invoices/getInvoiceByAccessKeyError',
  AGGREGATE_INVOICE_REQUEST = '@invoices/aggregateInvoiceRequest',
  AGGREGATE_INVOICE_SUCCESS = '@invoices/aggregateInvoiceSuccess',
  AGGREGATE_INVOICE_ERROR = '@invoices/aggregateInvoiceError',
  TRANSFER_CUSTODY_REQUEST = '@invoices/transferCustodyRequest',
  TRANSFER_CUSTODY_SUCCESS = '@invoices/transferCustodySuccess',
  TRANSFER_CUSTODY_ERROR = '@invoices/transferCustodyError',
  CANCEL_INVOICE_REQUEST = '@invoices/cancelInvoiceRequest',
  CANCEL_INVOICE_SUCCESS = '@invoices/cancelInvoiceSuccess',
  CANCEL_INVOICE_ERROR = '@invoices/cancelInvoiceError',
  SET_FILTERS_INVOICES = '@invoices/setFiltersInvoices',
  CLEAR_INVOICES = '@invoices/clearInvoices',
  CLEAR_SELECTED = '@invoices/clearSelected',
}

const InvoiceActions = {
  getInvoicesRequest: (filters: InvoiceFilterDTO) =>
    action(InvoicesTypes.GET_INVOICES_REQUEST, { filters }),
  getInvoicesSuccess: (invoices: InvoiceListResultDTO) =>
    action(InvoicesTypes.GET_INVOICES_SUCCESS, invoices),
  getInvoicesError: (error: string) =>
    action(InvoicesTypes.GET_INVOICES_ERROR, { error }),
  getInvoiceRequest: (id: string) =>
    action(InvoicesTypes.GET_INVOICE_REQUEST, { id }),
  getInvoiceSuccess: (invoice: InvoiceDTO) =>
    action(InvoicesTypes.GET_INVOICE_SUCCESS, { invoice }),
  getInvoiceError: (error: string) =>
    action(InvoicesTypes.GET_INVOICE_ERROR, { error }),
  getInvoiceByAccessKeyRequest: (accessKey: string) =>
    action(InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_REQUEST, { accessKey }),
  getInvoiceByAccessKeySuccess: (invoice: InvoiceDTO) =>
    action(InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_SUCCESS, { invoice }),
  getInvoiceByAccessKeyError: (error: string) =>
    action(InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_ERROR, { error }),
  // aggregateInvoiceRequest: (events, invoiceId) =>
  //   action(InvoicesTypes.AGGREGATE_INVOICE_REQUEST, { events, invoiceId }),
  aggregateInvoiceSuccess: () =>
    action(InvoicesTypes.AGGREGATE_INVOICE_SUCCESS),
  aggregateInvoiceError: (error: string) =>
    action(InvoicesTypes.AGGREGATE_INVOICE_ERROR, { error }),
  transferCustodyRequest: (
    invoiceId: string,
    registrationKey: string,
    onBack: () => void
  ) =>
    action(InvoicesTypes.TRANSFER_CUSTODY_REQUEST, {
      invoiceId,
      registrationKey,
      onBack,
    }),
  transferCustodySuccess: () => action(InvoicesTypes.TRANSFER_CUSTODY_SUCCESS),
  transferCustodyError: (error: string) =>
    action(InvoicesTypes.TRANSFER_CUSTODY_ERROR, { error }),
  cancelInvoiceRequest: (
    invoiceId: string,
    data: Position,
    onBack: () => void
  ) =>
    action(InvoicesTypes.CANCEL_INVOICE_REQUEST, {
      invoiceId,
      data,
      onBack,
    }),
  cancelInvoiceSuccess: () => action(InvoicesTypes.CANCEL_INVOICE_SUCCESS),
  cancelInvoiceError: (error: string) =>
    action(InvoicesTypes.CANCEL_INVOICE_ERROR, { error }),
  setFiltersInvoices: (filters: InvoiceFilterDTO) =>
    action(InvoicesTypes.SET_FILTERS_INVOICES, { filters }),
  clearInvoices: () => action(InvoicesTypes.CLEAR_INVOICES),
  clearSelected: () => action(InvoicesTypes.CLEAR_SELECTED),
};
export default InvoiceActions;

const INITIAL_STATE: InvoicesState = {
  data: [],
  selectedInvoice: {} as InvoiceDTO,
  currentPage: 1,
  filters: {},
  selectedProduct: {},
  prev: undefined,
  next: undefined,
  total: 0,
  error: '',
  loading: false,
};

type InvoiceReducer<Action extends AnyAction> = Reducer<InvoicesState, Action>;

const getInvoicesSuccess: InvoiceReducer<
  ReturnType<typeof InvoiceActions.getInvoicesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  prev: payload.prev,
  next: payload.next,
  total: payload.total,
  error: '',
  loading: false,
  totalCurrentStates: payload.totalCurrentStates,
});

// const getInvoiceProductSuccess: InvoiceReducer<
//   ReturnType<typeof InvoiceActions.getInvoiceProductSuccess>
// > = (state = INITIAL_STATE, { payload }) => ({
//   ...state,
//   selectedProduct: payload.product,
//   error: '',
//   loading: false,
// });

const getInvoiceSuccess: InvoiceReducer<
  ReturnType<typeof InvoiceActions.getInvoiceSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedInvoice: payload.invoice,
  error: '',
  loading: false,
});

const clearInvoices: InvoiceReducer<
  ReturnType<typeof InvoiceActions.clearInvoices>
> = () => INITIAL_STATE;

const clearSelected: InvoiceReducer<
  ReturnType<typeof InvoiceActions.clearSelected>
> = (state = INITIAL_STATE) => ({
  ...state,
  selectedInvoice: {} as InvoiceDTO,
});

const setFiltersInvoices: InvoiceReducer<
  ReturnType<typeof InvoiceActions.setFiltersInvoices>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<InvoicesState>(INITIAL_STATE)
  .handleAction(InvoicesTypes.GET_INVOICES_REQUEST, genericRequest)
  .handleAction(InvoicesTypes.GET_INVOICES_SUCCESS, getInvoicesSuccess)
  .handleAction(InvoicesTypes.GET_INVOICES_ERROR, genericError)
  .handleAction(InvoicesTypes.GET_INVOICE_REQUEST, genericRequest)
  .handleAction(InvoicesTypes.GET_INVOICE_SUCCESS, getInvoiceSuccess)
  .handleAction(InvoicesTypes.GET_INVOICE_ERROR, genericError)
  .handleAction(InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_REQUEST, genericRequest)
  .handleAction(
    InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_SUCCESS,
    getInvoiceSuccess
  )
  .handleAction(InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_ERROR, genericError)
  .handleAction(InvoicesTypes.AGGREGATE_INVOICE_REQUEST, genericRequest)
  .handleAction(InvoicesTypes.AGGREGATE_INVOICE_SUCCESS, genericSuccess)
  .handleAction(InvoicesTypes.AGGREGATE_INVOICE_ERROR, genericError)
  .handleAction(InvoicesTypes.TRANSFER_CUSTODY_REQUEST, genericRequest)
  .handleAction(InvoicesTypes.TRANSFER_CUSTODY_SUCCESS, genericSuccess)
  .handleAction(InvoicesTypes.TRANSFER_CUSTODY_ERROR, genericError)
  .handleAction(InvoicesTypes.CANCEL_INVOICE_REQUEST, genericRequest)
  .handleAction(InvoicesTypes.CANCEL_INVOICE_SUCCESS, genericSuccess)
  .handleAction(InvoicesTypes.CANCEL_INVOICE_ERROR, genericError)
  .handleAction(InvoicesTypes.CLEAR_INVOICES, clearInvoices)
  .handleAction(InvoicesTypes.SET_FILTERS_INVOICES, setFiltersInvoices)
  .handleAction(InvoicesTypes.CLEAR_SELECTED, clearSelected);
