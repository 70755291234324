import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import resourceProvider from '@/lib/resources';
import { PageDTO } from '@/dtos/generics';
import { ResourceDTO } from '@/dtos/resource';
import ResourceActions, { ResourceTypes } from './duck';
import history from '@/services/history';
import ResourceFieldDTO from '@/dtos/resourceField/ResourceFieldDTO';
import resourceFieldProvider from '@/lib/resourceField';
import SearchQuery from '@/utils/SearchQuery';

type SignUpParams = ReturnType<typeof ResourceActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(resourceProvider.list, payload.filters);
    yield put(ResourceActions.listSuccess(result as PageDTO<ResourceDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof ResourceActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(resourceProvider.create, payload.data);
    yield put(ResourceActions.addSuccess());
    history.push('/resources');
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.addError(errors));
  }
}

type UpdateParams = ReturnType<typeof ResourceActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(resourceProvider.update, payload);
    yield put(ResourceActions.updateSuccess());
    history.push('/resources');
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.updateError(errors));
  }
}

type DeleteParams = ReturnType<typeof ResourceActions.deleteRequest>;
export function* del({ payload }: DeleteParams): Generator {
  try {
    yield call(resourceProvider.delete, payload);
    const result = yield call(resourceProvider.list, SearchQuery.build());
    yield put(ResourceActions.listSuccess(result as PageDTO<ResourceDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.deleteError(errors));
  }
}

type ActiveParams = ReturnType<typeof ResourceActions.activeRequest>;
export function* active({ payload }: ActiveParams): Generator {
  try {
    yield call(resourceProvider.active, payload);
    const result = yield call(resourceProvider.list, SearchQuery.build());
    yield put(ResourceActions.listSuccess(result as PageDTO<ResourceDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.activeError(errors));
  }
}

type GetOneResourceParams = ReturnType<
  typeof ResourceActions.getOneResourceRequest
>;
export function* getOneUser({ payload }: GetOneResourceParams): Generator {
  try {
    const data = yield call(resourceProvider.find, payload.id);
    yield put(ResourceActions.getOneResourceSuccess(data as ResourceDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(ResourceActions.getOneResourceError(error));
  }
}

type GetParams = ReturnType<typeof ResourceActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(resourceProvider.getById, payload);
    yield put(ResourceActions.getSuccess(data as ResourceDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.getError(errors));
  }
}

export function* getListFields(): Generator {
  try {
    const result = yield call(resourceFieldProvider.list);
    yield put(
      ResourceActions.getListFieldsSuccess(result as PageDTO<ResourceFieldDTO>)
    );
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.getListFieldsError(errors));
  }
}

export default all([
  takeLatest(ResourceTypes.LIST_REQUEST, list),
  takeLatest(ResourceTypes.ADD_REQUEST, add),
  takeLatest(ResourceTypes.UPDATE_REQUEST, update),
  takeLatest(ResourceTypes.DELETE_REQUEST, del),
  takeLatest(ResourceTypes.ACTIVE_REQUEST, active),
  takeLatest(ResourceTypes.GET_REQUEST, get),
  takeLatest(ResourceTypes.GET_LIST_FIELDS_REQUEST, getListFields),
]);
