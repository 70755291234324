import api from '@/services/api';
import {
  AddConductorDTO,
  ChangeActiveDTO,
  UpdateConductorDTO,
  ConductorFiltersDTO,
  ConductorListResultDTO,
  ConductorDTO,
} from '@/dtos/conductor';

class ConductorServices {
  async create(conductor: AddConductorDTO): Promise<ConductorDTO> {
    const { data } = await api.post<ConductorDTO>('/conductors', conductor);
    return data;
  }
  async find(conductorId: string): Promise<ConductorDTO> {
    const { data } = await api.get<ConductorDTO>(`/conductors/${conductorId}`);
    return data;
  }
  async update(conductor: UpdateConductorDTO): Promise<ConductorDTO> {
    const { data } = await api.put<ConductorDTO>(
      `/conductors/${conductor.id}`,
      conductor
    );
    return data;
  }
  async changeActive(params: ChangeActiveDTO): Promise<ConductorDTO> {
    const { data } = await api.put<ConductorDTO>(
      `/conductors/${params.id}`,
      params
    );
    return data;
  }
  async list(filters: ConductorFiltersDTO): Promise<ConductorListResultDTO> {
    const { data } = await api.get<ConductorListResultDTO>('/conductors', {
      params: filters,
    });
    return data;
  }
}

const conductorServices = new ConductorServices();
export default conductorServices;
