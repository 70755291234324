import api from '@/services/api';
import {
  AddProcedureDTO,
  ProcedureDTO,
  UpdateProcedureDTO,
} from '@/dtos/procedures';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

class ProceduresServices {
  async list(filters: SearchQuery): Promise<PageDTO<ProcedureDTO>> {
    const { data } = await api.get<PageDTO<ProcedureDTO>>(`/procedures`, {
      params: filters.query,
    });
    return data;
  }

  async getById(id: string): Promise<ProcedureDTO> {
    const { data } = await api.get<ProcedureDTO>(`/procedures/${id}`);
    return data;
  }

  async create(procedure: AddProcedureDTO): Promise<ProcedureDTO> {
    const { data } = await api.post<ProcedureDTO>('/procedures', procedure);
    return data;
  }

  async update(procedure: UpdateProcedureDTO): Promise<ProcedureDTO> {
    const { data } = await api.put<ProcedureDTO>(
      `/procedures/${procedure.id}`,
      procedure
    );
    return data;
  }
}

const procedureServices = new ProceduresServices();
export default procedureServices;
