import api from '@/services/api';
import {
  AddEquipmentDTO,
  EquipmentDTO,
  UpdateEquipmentDTO,
} from '@/dtos/equipments';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

class EquipmentsServices {
  async list(filters: SearchQuery): Promise<PageDTO<EquipmentDTO>> {
    const { data } = await api.get<PageDTO<EquipmentDTO>>(`/equipments`, {
      params: filters.query,
    });
    return data;
  }

  async getById(id: string): Promise<EquipmentDTO> {
    const { data } = await api.get<EquipmentDTO>(`/equipments/${id}`);
    return data;
  }

  async create(equipment: AddEquipmentDTO): Promise<EquipmentDTO> {
    const { data } = await api.post<EquipmentDTO>('/equipments', equipment);
    return data;
  }

  async update(equipment: UpdateEquipmentDTO): Promise<EquipmentDTO> {
    const { data } = await api.put<EquipmentDTO>(
      `/equipments/${equipment.id}`,
      equipment
    );
    return data;
  }
}

const equipmentServices = new EquipmentsServices();
export default equipmentServices;
