import React from 'react';
import { Link } from 'react-router-dom';

import { Row, IconButton, Icon } from '@/components';

import LanguageMenu from './components/LanguageMenu';
import MyAccountMenu from './components/MyAccountMenu';
import { LayoutParams } from '../types';
import { Container, TitleSection, HeaderTitle, ActionsSection } from './styles';

const Layout: React.FC<LayoutParams> = ({
  title,
  setSearchOpen,
  isSearchOpen,
  setLangMenuOpen,
  isLangMenuOpen,
  setSettingsMenuOpen,
  isSettingsMenuOpen,
  languageMenuRef,
  settingsMenuRef,
  goBack,
}) => {
  return (
    <Container>
      <TitleSection>
        {goBack && (
          <Link to={goBack}>
            <Icon name="arrow-left" size={22} />
          </Link>
        )}
        <HeaderTitle>
          {title.main}
          {title.breadcrumb && <span>{title.breadcrumb}</span>}
        </HeaderTitle>
      </TitleSection>

      <Row justifyContent="space-between" gap={1.8}>
        {setSearchOpen && (
          <IconButton
            onClick={() => {
              setLangMenuOpen(false);
              setSettingsMenuOpen(false);
              setSearchOpen(true);
            }}
          >
            <Icon
              name="search"
              size={27}
              active={isSearchOpen}
              title="search"
            />
          </IconButton>
        )}

        <ActionsSection>
          <IconButton
            onClick={() => {
              setSettingsMenuOpen(false);
              setLangMenuOpen(!isLangMenuOpen);
            }}
          >
            <Icon
              name="globe"
              size={22}
              active={isLangMenuOpen}
              title="language"
            />
          </IconButton>
          <LanguageMenu
            isLangMenuOpen={isLangMenuOpen}
            languageMenuRef={languageMenuRef}
          />
        </ActionsSection>

        <ActionsSection>
          <IconButton
            onClick={() => {
              setLangMenuOpen(false);
              setSettingsMenuOpen(!isSettingsMenuOpen);
            }}
          >
            <Icon
              name="user"
              size={22}
              active={isSettingsMenuOpen}
              title="myAccount"
            />
          </IconButton>
          <MyAccountMenu
            isSettingsMenuOpen={isSettingsMenuOpen}
            setSettingsMenuOpen={setSettingsMenuOpen}
            settingsMenuRef={settingsMenuRef}
          />
        </ActionsSection>
      </Row>
    </Container>
  );
};

export default Layout;
