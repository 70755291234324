import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { CreateResourceDTO, ResourceDTO } from '@/dtos/resource';
import { PageDTO } from '@/dtos/generics';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { ResourceState } from './types';
import UpdateResourceDTO from '@/dtos/resource/UpdateResourceDTO';
import ResourceFieldDTO from '@/dtos/resourceField/ResourceFieldDTO';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: ResourceState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as ResourceDTO,
  fields: [],
};

export enum ResourceTypes {
  SET_LOADING = '@resources/SET_LOADING',
  SET_FILTERS = '@resources/SET_FILTERS',
  LIST_REQUEST = '@resources/LIST_REQUEST',
  LIST_SUCCESS = '@resources/LIST_SUCCESS',
  LIST_ERROR = '@resources/LIST_ERROR',
  GET_ONE_RESOURCE_REQUEST = '@resources/getOneResourceRequest',
  GET_ONE_RESOURCE_SUCCESS = '@resources/getOneResourceSuccess',
  GET_ONE_RESOURCE_ERROR = '@resources/getOneResourceError',
  UPDATE_REQUEST = '@resources/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@resources/UPDATE_SUCCESS',
  UPDATE_ERROR = '@resources/UPDATE_ERROR',
  GET_REQUEST = '@resources/GET_REQUEST',
  GET_SUCCESS = '@resources/GET_SUCCESS',
  GET_ERROR = '@resources/GET_ERROR',
  ADD_REQUEST = '@resources/ADD_REQUEST',
  ADD_SUCCESS = '@resources/ADD_SUCCESS',
  ADD_ERROR = '@resources/ADD_ERROR',
  CLEAR = '@resources/CLEAR',
  GET_LIST_FIELDS_REQUEST = 'GET_LIST_FIELDS_REQUEST',
  GET_LIST_FIELDS_SUCCESS = 'GET_LIST_FIELDS_SUCCESS',
  GET_LIST_FIELDS_ERROR = 'GET_LIST_FIELDS_ERROR',
  DELETE_REQUEST = '@resources/DELETE_REQUEST',
  DELETE_SUCCESS = '@resources/DELETE_SUCCESS',
  DELETE_ERROR = '@resources/DELETE_ERROR',
  ACTIVE_REQUEST = '@resources/ACTIVE_REQUEST',
  ACTIVE_SUCCESS = '@resources/ACTIVE_SUCCESS',
  ACTIVE_ERROR = '@resources/ACTIVE_ERROR',
}

const ResourcesActions = {
  setLoading: (loading: boolean) =>
    action(ResourceTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(ResourceTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(ResourceTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<ResourceDTO>) =>
    action(ResourceTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(ResourceTypes.LIST_ERROR, { error }),

  updateRequest: (resources: UpdateResourceDTO) =>
    action(ResourceTypes.UPDATE_REQUEST, resources),
  updateSuccess: () => action(ResourceTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(ResourceTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(ResourceTypes.GET_REQUEST, id),
  getSuccess: (resource: ResourceDTO) =>
    action(ResourceTypes.GET_SUCCESS, resource),
  getError: (error: string) => action(ResourceTypes.GET_ERROR, { error }),

  deleteRequest: (id: string) => action(ResourceTypes.DELETE_REQUEST, id),
  deleteSuccess: () => action(ResourceTypes.DELETE_SUCCESS),
  deleteError: (error: string) => action(ResourceTypes.DELETE_ERROR, { error }),

  activeRequest: (id: string) => action(ResourceTypes.ACTIVE_REQUEST, id),
  activeSuccess: () => action(ResourceTypes.ACTIVE_SUCCESS),
  activeError: (error: string) => action(ResourceTypes.ACTIVE_ERROR, { error }),

  getListFieldsRequest: () => action(ResourceTypes.GET_LIST_FIELDS_REQUEST),
  getListFieldsSuccess: (page: PageDTO<ResourceFieldDTO>) =>
    action(ResourceTypes.GET_LIST_FIELDS_SUCCESS, page),
  getListFieldsError: (error: string) =>
    action(ResourceTypes.GET_LIST_FIELDS_ERROR, { error }),

  addRequest: (data: CreateResourceDTO) =>
    action(ResourceTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(ResourceTypes.ADD_SUCCESS),
  addError: (error: string) => action(ResourceTypes.ADD_ERROR, { error }),

  getOneResourceRequest: (id: string) =>
    action(ResourceTypes.GET_ONE_RESOURCE_REQUEST, { id }),
  getOneResourceSuccess: (resource: ResourceDTO) =>
    action(ResourceTypes.GET_ONE_RESOURCE_SUCCESS, { resource }),
  getOneResourceError: (error: string) =>
    action(ResourceTypes.GET_ONE_RESOURCE_ERROR, { error }),

  clear: () => action(ResourceTypes.CLEAR),
};
export default ResourcesActions;

type ResourcesReducer<Action extends AnyAction> = Reducer<
  ResourceState,
  Action
>;

const setLoading: ResourcesReducer<
  ReturnType<typeof ResourcesActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});
const setFilters: ResourcesReducer<
  ReturnType<typeof ResourcesActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const getListFieldsSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.getListFieldsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fields: payload.data,
  loading: false,
});

const getOneResourceSuccess: ResourcesReducer<
  ReturnType<typeof ResourcesActions.getOneResourceSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  error: '',
  selected: payload.resource,
});

const clear: ResourcesReducer<ReturnType<typeof ResourcesActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<ResourceState>(INITIAL_STATE)
  .handleAction(ResourceTypes.SET_LOADING, setLoading)
  .handleAction(ResourceTypes.SET_FILTERS, setFilters)
  .handleAction(ResourceTypes.LIST_REQUEST, genericRequest)
  .handleAction(ResourceTypes.LIST_SUCCESS, listSuccess)
  .handleAction(ResourceTypes.LIST_ERROR, genericError)
  .handleAction(ResourceTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(ResourceTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.UPDATE_ERROR, genericError)
  .handleAction(ResourceTypes.DELETE_REQUEST, genericRequest)
  .handleAction(ResourceTypes.DELETE_SUCCESS, getSuccess)
  .handleAction(ResourceTypes.DELETE_ERROR, genericError)
  .handleAction(ResourceTypes.ACTIVE_REQUEST, genericRequest)
  .handleAction(ResourceTypes.ACTIVE_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.ACTIVE_ERROR, genericError)
  .handleAction(ResourceTypes.GET_REQUEST, genericRequest)
  .handleAction(ResourceTypes.GET_SUCCESS, getSuccess)
  .handleAction(ResourceTypes.GET_ERROR, genericError)
  .handleAction(ResourceTypes.ADD_REQUEST, genericRequest)
  .handleAction(ResourceTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(ResourceTypes.ADD_ERROR, genericError)
  .handleAction(ResourceTypes.GET_ONE_RESOURCE_REQUEST, genericRequest)
  .handleAction(ResourceTypes.GET_ONE_RESOURCE_SUCCESS, getOneResourceSuccess)
  .handleAction(ResourceTypes.GET_ONE_RESOURCE_ERROR, genericError)
  .handleAction(ResourceTypes.GET_LIST_FIELDS_REQUEST, genericRequest)
  .handleAction(ResourceTypes.GET_LIST_FIELDS_SUCCESS, getListFieldsSuccess)
  .handleAction(ResourceTypes.GET_LIST_FIELDS_ERROR, genericError)
  .handleAction(ResourceTypes.CLEAR, clear);
