import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { AddStorageDTO, StorageDTO } from '@/dtos/storages';
import { PageDTO } from '@/dtos/generics';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { StorageState } from './types';
import UpdateStorageDTO from '@/dtos/storages/UpdateStorageDTO';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: StorageState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as StorageDTO,
};

export enum StorageTypes {
  SET_LOADING = '@storages/SET_LOADING',
  SET_FILTERS = '@storages/SET_FILTERS',
  LIST_REQUEST = '@storages/LIST_REQUEST',
  LIST_SUCCESS = '@storages/LIST_SUCCESS',
  LIST_ERROR = '@storages/LIST_ERROR',
  UPDATE_REQUEST = '@storages/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@storages/UPDATE_SUCCESS',
  UPDATE_ERROR = '@storages/UPDATE_ERROR',
  GET_REQUEST = '@storages/GET_REQUEST',
  GET_SUCCESS = '@storages/GET_SUCCESS',
  GET_ERROR = '@storages/GET_ERROR',
  ADD_REQUEST = '@storages/ADD_REQUEST',
  ADD_SUCCESS = '@storages/ADD_SUCCESS',
  ADD_ERROR = '@storages/ADD_ERROR',
  CLEAR = '@storages/CLEAR',
  ACTIVE_REQUEST = '@storages/ACTIVE_REQUEST',
  ACTIVE_SUCCESS = '@storages/ACTIVE_SUCCESS',
  ACTIVE_ERROR = '@storages/ACTIVE_ERROR',
  DELETE_REQUEST = '@storages/DELETE_REQUEST',
  DELETE_SUCCESS = '@storages/DELETE_SUCCESS',
  DELETE_ERROR = '@storages/DELETE_ERROR',
}

const StorageActions = {
  setLoading: (loading: boolean) =>
    action(StorageTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(StorageTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(StorageTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<StorageDTO>) =>
    action(StorageTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(StorageTypes.LIST_ERROR, { error }),

  updateRequest: (storage: UpdateStorageDTO) =>
    action(StorageTypes.UPDATE_REQUEST, storage),
  updateSuccess: () => action(StorageTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(StorageTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(StorageTypes.GET_REQUEST, id),
  getSuccess: (storage: StorageDTO) =>
    action(StorageTypes.GET_SUCCESS, storage),
  getError: (error: string) => action(StorageTypes.GET_ERROR, { error }),

  activeRequest: (id: string) => action(StorageTypes.ACTIVE_REQUEST, id),
  activeSuccess: () => action(StorageTypes.ACTIVE_SUCCESS),
  activeError: (error: string) => action(StorageTypes.ACTIVE_ERROR, { error }),

  deleteRequest: (id: string) => action(StorageTypes.DELETE_REQUEST, id),
  deleteSuccess: () => action(StorageTypes.DELETE_SUCCESS),
  deleteError: (error: string) => action(StorageTypes.DELETE_ERROR, { error }),

  addRequest: (data: AddStorageDTO) =>
    action(StorageTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(StorageTypes.ADD_SUCCESS),
  addError: (error: string) => action(StorageTypes.ADD_ERROR, { error }),

  clear: () => action(StorageTypes.CLEAR),
};
export default StorageActions;

type StorageReducer<Action extends AnyAction> = Reducer<StorageState, Action>;

const setLoading: StorageReducer<ReturnType<typeof StorageActions.setLoading>> =
  (state = INITIAL_STATE, { payload }) => ({
    ...state,
    loading: payload.loading,
  });
const setFilters: StorageReducer<ReturnType<typeof StorageActions.setFilters>> =
  (state = INITIAL_STATE, { payload }) => ({
    ...state,
    filters: payload.filters,
  });

const listSuccess: StorageReducer<
  ReturnType<typeof StorageActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: StorageReducer<ReturnType<typeof StorageActions.getSuccess>> =
  (state = INITIAL_STATE, { payload }) => ({
    ...state,
    selected: payload,
    loading: false,
  });

const clear: StorageReducer<ReturnType<typeof StorageActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<StorageState>(INITIAL_STATE)
  .handleAction(StorageTypes.SET_LOADING, setLoading)
  .handleAction(StorageTypes.SET_FILTERS, setFilters)
  .handleAction(StorageTypes.LIST_REQUEST, genericRequest)
  .handleAction(StorageTypes.LIST_SUCCESS, listSuccess)
  .handleAction(StorageTypes.LIST_ERROR, genericError)
  .handleAction(StorageTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(StorageTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(StorageTypes.UPDATE_ERROR, genericError)
  .handleAction(StorageTypes.DELETE_REQUEST, genericRequest)
  .handleAction(StorageTypes.DELETE_SUCCESS, getSuccess)
  .handleAction(StorageTypes.DELETE_ERROR, genericError)
  .handleAction(StorageTypes.GET_REQUEST, genericRequest)
  .handleAction(StorageTypes.GET_SUCCESS, getSuccess)
  .handleAction(StorageTypes.GET_ERROR, genericError)
  .handleAction(StorageTypes.ACTIVE_REQUEST, genericRequest)
  .handleAction(StorageTypes.ACTIVE_SUCCESS, genericSuccess)
  .handleAction(StorageTypes.ACTIVE_ERROR, genericError)
  .handleAction(StorageTypes.ADD_REQUEST, genericRequest)
  .handleAction(StorageTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(StorageTypes.ADD_ERROR, genericError)
  .handleAction(StorageTypes.CLEAR, clear);
