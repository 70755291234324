import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import vehicleServices from '@/lib/vehicle';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import VehicleListResultDTO from '@/dtos/vehicle/VehicleListResultDTO';
import VehicleDTO from '@/dtos/vehicle/VehicleDTO';
import VehicleFiltersDTO from '@/dtos/vehicle/VehicleFiltersDTO';
import VehiclesActions, { VehiclesTypes } from './duck';

const registerVehicle = i18next.t('Success.successRegister');
const vehicleUpdate = i18next.t('Success.successUpdate');

type AddVehicleParams = ReturnType<typeof VehiclesActions.addVehicleRequest>;
export function* addVehicle({ payload }: AddVehicleParams): Generator {
  try {
    yield call(vehicleServices.create, payload.data);
    yield put(VehiclesActions.addVehicleSuccess());
    history.push('/vehicles');
    toast.success(registerVehicle);
  } catch (err) {
    const error = handleError(err);
    yield put(VehiclesActions.addVehicleError(error));
  }
}

type GetVehiclesParams = ReturnType<typeof VehiclesActions.getVehiclesRequest>;
export function* getVehicles({ payload }: GetVehiclesParams): Generator {
  try {
    const data = yield call(vehicleServices.list, payload.filters);
    yield put(VehiclesActions.getVehiclesSuccess(data as VehicleListResultDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(VehiclesActions.getVehiclesError(error));
  }
}

type GetOneVehicleParams = ReturnType<
  typeof VehiclesActions.getOneVehicleRequest
>;
export function* getOneVehicle({ payload }: GetOneVehicleParams): Generator {
  try {
    const data = yield call(vehicleServices.find, payload.id);
    yield put(VehiclesActions.getOneVehicleSuccess(data as VehicleDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(VehiclesActions.getOneVehicleError(error));
  }
}

type UpdateVehicleParams = ReturnType<typeof VehiclesActions.updateRequest>;
export function* update({ payload }: UpdateVehicleParams): Generator {
  try {
    const data = yield call(vehicleServices.update, payload);
    const vehicle = data as VehicleDTO;
    yield put(VehiclesActions.updateSuccess());
    yield put(VehiclesActions.getVehiclesRequest({} as VehicleFiltersDTO));
    yield put(VehiclesActions.getOneVehicleRequest(vehicle.id));
    toast.success(vehicleUpdate);
  } catch (err) {
    const error = handleError(err);
    yield put(VehiclesActions.updateError(error));
  }
}

type ChangeActiveParams = ReturnType<
  typeof VehiclesActions.changeActiveRequest
>;
export function* changeActive({ payload }: ChangeActiveParams): Generator {
  try {
    const data = yield call(vehicleServices.changeActive, payload);
    const vehicle = data as VehicleDTO;
    yield put(VehiclesActions.updateSuccess());
    yield put(VehiclesActions.getVehiclesRequest({} as VehicleFiltersDTO));
    yield put(VehiclesActions.getOneVehicleRequest(vehicle.id));
  } catch (err) {
    const error = handleError(err);
    yield put(VehiclesActions.updateError(error));
  }
}

export default all([
  takeLatest(VehiclesTypes.ADD_VEHICLE_REQUEST, addVehicle),
  takeLatest(VehiclesTypes.GET_VEHICLES_REQUEST, getVehicles),
  takeLatest(VehiclesTypes.GET_ONE_VEHICLE_REQUEST, getOneVehicle),
  takeLatest(VehiclesTypes.UPDATE_REQUEST, update),
  takeLatest(VehiclesTypes.CHANGE_ACTIVE_REQUEST, changeActive),
]);
