import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import procedureServices from '@/lib/procedures';
import { PageDTO } from '@/dtos/generics';
import { ProcedureDTO } from '@/dtos/procedures';
import history from '@/services/history';
import ProceduresActions, { ProcedureTypes } from './duck';

type SignUpParams = ReturnType<typeof ProceduresActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(procedureServices.list, payload.filters);
    yield put(ProceduresActions.listSuccess(result as PageDTO<ProcedureDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ProceduresActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof ProceduresActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(procedureServices.create, payload.data);
    yield put(ProceduresActions.addSuccess());
    history.push('/procedures');
  } catch (err) {
    const errors = handleError(err);
    yield put(ProceduresActions.addError(errors));
  }
}

type UpdateParams = ReturnType<typeof ProceduresActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(procedureServices.update, payload);
    yield put(ProceduresActions.updateSuccess());
    history.push('/procedures');
  } catch (err) {
    const errors = handleError(err);
    yield put(ProceduresActions.updateError(errors));
  }
}

type GetParams = ReturnType<typeof ProceduresActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(procedureServices.getById, payload);
    yield put(ProceduresActions.getSuccess(data as ProcedureDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(ProceduresActions.getError(errors));
  }
}

export default all([
  takeLatest(ProcedureTypes.LIST_REQUEST, list),
  takeLatest(ProcedureTypes.ADD_REQUEST, add),
  takeLatest(ProcedureTypes.UPDATE_REQUEST, update),
  takeLatest(ProcedureTypes.GET_REQUEST, get),
]);
