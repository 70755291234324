import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import settingPurposeServices from '@/lib/settingPurpose';
import { PageDTO } from '@/dtos/generics';
import { SettingPurposeDTO } from '@/dtos/rack/settingPurpose';
import SettingPurposeActions, { SettingPurposeTypes } from './duck';
import history from '@/services/history';

type SignUpParams = ReturnType<typeof SettingPurposeActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(settingPurposeServices.list, payload.filters);
    yield put(
      SettingPurposeActions.listSuccess(result as PageDTO<SettingPurposeDTO>)
    );
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingPurposeActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof SettingPurposeActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(settingPurposeServices.create, payload.data);
    yield put(SettingPurposeActions.addSuccess());
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingPurposeActions.addError(errors));
  }
}

type UpdateParams = ReturnType<typeof SettingPurposeActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(settingPurposeServices.update, payload);
    yield put(SettingPurposeActions.updateSuccess());
    history.push('/settingPurposes');
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingPurposeActions.updateError(errors));
  }
}

type GetParams = ReturnType<typeof SettingPurposeActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(settingPurposeServices.getById, payload);
    yield put(SettingPurposeActions.getSuccess(data as SettingPurposeDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingPurposeActions.getError(errors));
  }
}

export default all([
  takeLatest(SettingPurposeTypes.LIST_REQUEST, list),
  takeLatest(SettingPurposeTypes.ADD_REQUEST, add),
  takeLatest(SettingPurposeTypes.UPDATE_REQUEST, update),
  takeLatest(SettingPurposeTypes.GET_REQUEST, get),
]);
