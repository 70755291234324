import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { AddSettingRackDTO, SettingRackDTO } from '@/dtos/rack/settingRack';
import { PageDTO } from '@/dtos/generics';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { SettingRackState } from './types';
import UpdateSettingRackDTO from '@/dtos/rack/settingRack/UpdateSettingRackDTO';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: SettingRackState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as SettingRackDTO,
};

export enum SettingRackTypes {
  SET_LOADING = '@settingRacks/SET_LOADING',
  SET_FILTERS = '@settingRacks/SET_FILTERS',
  LIST_REQUEST = '@settingRacks/LIST_REQUEST',
  LIST_SUCCESS = '@settingRacks/LIST_SUCCESS',
  LIST_ERROR = '@settingRacks/LIST_ERROR',
  UPDATE_REQUEST = '@settingRacks/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@settingRacks/UPDATE_SUCCESS',
  UPDATE_ERROR = '@settingRacks/UPDATE_ERROR',
  GET_REQUEST = '@settingRacks/GET_REQUEST',
  GET_SUCCESS = '@settingRacks/GET_SUCCESS',
  GET_ERROR = '@settingRacks/GET_ERROR',
  ADD_REQUEST = '@settingRacks/ADD_REQUEST',
  ADD_SUCCESS = '@settingRacks/ADD_SUCCESS',
  ADD_ERROR = '@settingRacks/ADD_ERROR',
  CLEAR = '@settingRacks/CLEAR',
}

const SettingRackActions = {
  setLoading: (loading: boolean) =>
    action(SettingRackTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(SettingRackTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(SettingRackTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<SettingRackDTO>) =>
    action(SettingRackTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(SettingRackTypes.LIST_ERROR, { error }),

  updateRequest: (settingRack: UpdateSettingRackDTO) =>
    action(SettingRackTypes.UPDATE_REQUEST, settingRack),
  updateSuccess: () => action(SettingRackTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(SettingRackTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(SettingRackTypes.GET_REQUEST, id),
  getSuccess: (settingRack: SettingRackDTO) =>
    action(SettingRackTypes.GET_SUCCESS, settingRack),
  getError: (error: string) => action(SettingRackTypes.GET_ERROR, { error }),

  addRequest: (data: AddSettingRackDTO) =>
    action(SettingRackTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(SettingRackTypes.ADD_SUCCESS),
  addError: (error: string) => action(SettingRackTypes.ADD_ERROR, { error }),

  clear: () => action(SettingRackTypes.CLEAR),
};
export default SettingRackActions;

type SettingRackReducer<Action extends AnyAction> = Reducer<
  SettingRackState,
  Action
>;

const setLoading: SettingRackReducer<
  ReturnType<typeof SettingRackActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});
const setFilters: SettingRackReducer<
  ReturnType<typeof SettingRackActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: SettingRackReducer<
  ReturnType<typeof SettingRackActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: SettingRackReducer<
  ReturnType<typeof SettingRackActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: SettingRackReducer<any> = () => INITIAL_STATE;

export const reducer = createReducer<SettingRackState>(INITIAL_STATE)
  .handleAction(SettingRackTypes.SET_LOADING, setLoading)
  .handleAction(SettingRackTypes.SET_FILTERS, setFilters)
  .handleAction(SettingRackTypes.LIST_REQUEST, genericRequest)
  .handleAction(SettingRackTypes.LIST_SUCCESS, listSuccess)
  .handleAction(SettingRackTypes.LIST_ERROR, genericError)
  .handleAction(SettingRackTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(SettingRackTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(SettingRackTypes.UPDATE_ERROR, genericError)
  .handleAction(SettingRackTypes.GET_REQUEST, genericRequest)
  .handleAction(SettingRackTypes.GET_SUCCESS, getSuccess)
  .handleAction(SettingRackTypes.GET_ERROR, genericError)
  .handleAction(SettingRackTypes.ADD_REQUEST, genericRequest)
  .handleAction(SettingRackTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(SettingRackTypes.ADD_ERROR, genericError)
  .handleAction(SettingRackTypes.CLEAR, clear);
