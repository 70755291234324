import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { ClientDTO, CreateClientDTO, UpdateClientDTO } from '@/dtos/clients';
import { PageDTO } from '@/dtos/generics';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { ClientState } from './types';
import { DocumentTypes } from '@/enums';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: ClientState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: SearchQuery.build(),
  loading: false,
  error: '',
  selected: {
    name: '',
    document: '',
    documentType: DocumentTypes.CNPJ,
    address: {
      city: '',
      neighborhood: '',
      number: '',
      state: '',
      street: '',
      zipCode: '',
      complement: '',
    },
  } as ClientDTO,
};

export enum ClientTypes {
  SET_LOADING = '@clients/SET_LOADING',
  SET_FILTERS = '@clients/SET_FILTERS',

  LIST_REQUEST = '@clients/LIST_REQUEST',
  LIST_SUCCESS = '@clients/LIST_SUCCESS',
  LIST_ERROR = '@clients/LIST_ERROR',

  ADD_REQUEST = '@clients/ADD_REQUEST',
  ADD_SUCCESS = '@clients/ADD_SUCCESS',
  ADD_ERROR = '@clients/ADD_ERROR',

  UPDATE_REQUEST = '@clients/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@clients/UPDATE_SUCCESS',
  UPDATE_ERROR = '@clients/UPDATE_ERROR',

  GET_REQUEST = '@clients/GET_REQUEST',
  GET_SUCCESS = '@clients/GET_SUCCESS',
  GET_ERROR = '@clients/GET_ERROR',

  DELETE_REQUEST = '@clients/DELETE_REQUEST',
  DELETE_SUCCESS = '@clients/DELETE_SUCCESS',
  DELETE_ERROR = '@clients/DELETE_ERROR',

  ACTIVE_REQUEST = '@clients/ACTIVE_REQUEST',
  ACTIVE_SUCCESS = '@clients/ACTIVE_SUCCESS',
  ACTIVE_ERROR = '@clients/ACTIVE_ERROR',

  CLEAR = '@clients/CLEAR',
}

const ClientsActions = {
  setLoading: (loading: boolean) =>
    action(ClientTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(ClientTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(ClientTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<ClientDTO>) =>
    action(ClientTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(ClientTypes.LIST_ERROR, { error }),

  updateRequest: (resources: UpdateClientDTO) =>
    action(ClientTypes.UPDATE_REQUEST, resources),
  updateSuccess: () => action(ClientTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(ClientTypes.UPDATE_ERROR, { error }),

  addRequest: (data: CreateClientDTO) => action(ClientTypes.ADD_REQUEST, data),
  addSuccess: () => action(ClientTypes.ADD_SUCCESS),
  addError: (error: string) => action(ClientTypes.ADD_ERROR, { error }),

  getRequest: (id: string) => action(ClientTypes.GET_REQUEST, id),
  getSuccess: (client: ClientDTO) => action(ClientTypes.GET_SUCCESS, client),
  getError: (error: string) => action(ClientTypes.GET_ERROR, { error }),

  deleteRequest: (id: string) => action(ClientTypes.DELETE_REQUEST, id),
  deleteSuccess: (ClientId: string) =>
    action(ClientTypes.DELETE_SUCCESS, ClientId),
  deleteError: (error: string) => action(ClientTypes.DELETE_ERROR, { error }),

  activeRequest: (id: string) => action(ClientTypes.ACTIVE_REQUEST, id),
  activeSuccess: () => action(ClientTypes.ACTIVE_SUCCESS),
  activeError: (error: string) => action(ClientTypes.ACTIVE_ERROR, { error }),

  clear: () => action(ClientTypes.CLEAR),
};
export default ClientsActions;

type ClientsReducer<Action extends AnyAction> = Reducer<ClientState, Action>;

const setLoading: ClientsReducer<ReturnType<typeof ClientsActions.setLoading>> =
  (state = INITIAL_STATE, { payload }) => ({
    ...state,
    loading: payload.loading,
  });

const setFilters: ClientsReducer<ReturnType<typeof ClientsActions.setFilters>> =
  (state = INITIAL_STATE, { payload }) => ({
    ...state,
    filters: payload.filters,
  });

const listSuccess: ClientsReducer<
  ReturnType<typeof ClientsActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: ClientsReducer<ReturnType<typeof ClientsActions.getSuccess>> =
  (state = INITIAL_STATE, { payload }) => ({
    ...state,
    selected: payload,
    loading: false,
    error: '',
  });

const clear: ClientsReducer<ReturnType<typeof ClientsActions.clear>> = () =>
  INITIAL_STATE;

export const reducer = createReducer<ClientState>(INITIAL_STATE)
  .handleAction(ClientTypes.SET_LOADING, setLoading)
  .handleAction(ClientTypes.SET_FILTERS, setFilters)
  .handleAction(ClientTypes.LIST_REQUEST, genericRequest)
  .handleAction(ClientTypes.LIST_SUCCESS, listSuccess)
  .handleAction(ClientTypes.LIST_ERROR, genericError)
  .handleAction(ClientTypes.ADD_REQUEST, genericRequest)
  .handleAction(ClientTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(ClientTypes.ADD_ERROR, genericError)
  .handleAction(ClientTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(ClientTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(ClientTypes.UPDATE_ERROR, genericError)
  .handleAction(ClientTypes.GET_REQUEST, genericRequest)
  .handleAction(ClientTypes.GET_SUCCESS, getSuccess)
  .handleAction(ClientTypes.GET_ERROR, genericError)
  .handleAction(ClientTypes.DELETE_REQUEST, genericRequest)
  .handleAction(ClientTypes.DELETE_SUCCESS, getSuccess)
  .handleAction(ClientTypes.DELETE_ERROR, genericError)
  .handleAction(ClientTypes.ACTIVE_REQUEST, genericRequest)
  .handleAction(ClientTypes.ACTIVE_SUCCESS, genericSuccess)
  .handleAction(ClientTypes.ACTIVE_ERROR, genericError)
  .handleAction(ClientTypes.CLEAR, clear);
