import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import {
  AddProcedureDTO,
  ProcedureDTO,
  UpdateProcedureDTO,
} from '@/dtos/procedures';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { ProcedureState } from './types';

const INITIAL_STATE: ProcedureState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as ProcedureDTO,
};

export enum ProcedureTypes {
  SET_LOADING = '@procedures/SET_LOADING',
  SET_FILTERS = '@procedures/SET_FILTERS',
  LIST_REQUEST = '@procedures/LIST_REQUEST',
  LIST_SUCCESS = '@procedures/LIST_SUCCESS',
  LIST_ERROR = '@procedures/LIST_ERROR',
  UPDATE_REQUEST = '@procedures/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@procedures/UPDATE_SUCCESS',
  UPDATE_ERROR = '@procedures/UPDATE_ERROR',
  GET_REQUEST = '@procedures/GET_REQUEST',
  GET_SUCCESS = '@procedures/GET_SUCCESS',
  GET_ERROR = '@procedures/GET_ERROR',
  ADD_REQUEST = '@procedures/ADD_REQUEST',
  ADD_SUCCESS = '@procedures/ADD_SUCCESS',
  ADD_ERROR = '@procedures/ADD_ERROR',
  CLEAR = '@procedures/CLEAR',
}

const ProceduresActions = {
  setLoading: (loading: boolean) =>
    action(ProcedureTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(ProcedureTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(ProcedureTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<ProcedureDTO>) =>
    action(ProcedureTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(ProcedureTypes.LIST_ERROR, { error }),

  updateRequest: (procedure: UpdateProcedureDTO) =>
    action(ProcedureTypes.UPDATE_REQUEST, procedure),
  updateSuccess: () => action(ProcedureTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(ProcedureTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(ProcedureTypes.GET_REQUEST, id),
  getSuccess: (procedure: ProcedureDTO) =>
    action(ProcedureTypes.GET_SUCCESS, procedure),
  getError: (error: string) => action(ProcedureTypes.GET_ERROR, { error }),

  addRequest: (data: AddProcedureDTO) =>
    action(ProcedureTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(ProcedureTypes.ADD_SUCCESS),
  addError: (error: string) => action(ProcedureTypes.ADD_ERROR, { error }),

  clear: () => action(ProcedureTypes.CLEAR),
};
export default ProceduresActions;

type ProceduresReducer<Action extends AnyAction> = Reducer<
  ProcedureState,
  Action
>;

const setLoading: ProceduresReducer<
  ReturnType<typeof ProceduresActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});

const setFilters: ProceduresReducer<
  ReturnType<typeof ProceduresActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: ProceduresReducer<
  ReturnType<typeof ProceduresActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: ProceduresReducer<
  ReturnType<typeof ProceduresActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: ProceduresReducer<
  ReturnType<typeof ProceduresActions.clear>
> = () => INITIAL_STATE;

export const reducer = createReducer<ProcedureState>(INITIAL_STATE)
  .handleAction(ProcedureTypes.SET_LOADING, setLoading)
  .handleAction(ProcedureTypes.SET_FILTERS, setFilters)
  .handleAction(ProcedureTypes.LIST_REQUEST, genericRequest)
  .handleAction(ProcedureTypes.LIST_SUCCESS, listSuccess)
  .handleAction(ProcedureTypes.LIST_ERROR, genericError)
  .handleAction(ProcedureTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(ProcedureTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(ProcedureTypes.UPDATE_ERROR, genericError)
  .handleAction(ProcedureTypes.GET_REQUEST, genericRequest)
  .handleAction(ProcedureTypes.GET_SUCCESS, getSuccess)
  .handleAction(ProcedureTypes.GET_ERROR, genericError)
  .handleAction(ProcedureTypes.ADD_REQUEST, genericRequest)
  .handleAction(ProcedureTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(ProcedureTypes.ADD_ERROR, genericError)
  .handleAction(ProcedureTypes.CLEAR, clear);
