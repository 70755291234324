import { TypePrevious } from '@/enums';
import Locale from '../types';

const locale: Locale = {
  systemName: 'Meta Track',
  systemCopyright: 'Desarrollado por Meta Globaltech',
  input: {
    email: 'Correo electrónico',
    password: 'Contraseña',
    search: {
      name: 'Buscar por nombre',
      nameConductors: 'Nombre, documento, licencia de conducir o empresa',
      nameOrDocument: 'Buscar por nombre o documento',
      code: 'Código',
      board: 'Informar a la placa',
      nameOrEmail: 'Nombre o correo electrónico',
    },
    bondTypes: {
      others: 'Subcontratado',
      employee: 'Empleado',
      autonomous: 'Autónomo',
    },
    driversLicenseType: {
      categoryA: 'Categoría A',
      categoryB: 'Categoría B',
      categoryC: 'Categoría C',
      categoryD: 'Categoría D',
      categoryE: 'Categoría E',
      categoryACC: 'Categoría ACC',
    },
    receiptAnalysis: {
      yes: 'SÍ',
      no: 'NO',
    },
    receiptFinality: {
      medicinal: 'Medicinal',
      industrial: 'Industrial',
    },
    createdAt: 'Creado en',
    updatedAt: 'Actualizado en',
    name: 'Nombre',
    phone: 'Teléfono',
    confirmPassword: 'Confirmación de contraseña',
    date: 'Data',
    ncm: 'NCM',
    ean: 'EAN-13',
    fuelAmount: 'Cantidade de combustible (en litros',
    registrationKey: 'Llave del conductor',
    oldPassword: 'Contraseña anterior',
    newPassword: 'Nueva contraseña',
    code: 'Código',
    defaultValue: 'Valor por defecto',
    documentType: 'Tipo de Documento',
    city: 'Ciudad',
    state: 'Estado',
    zipCode: 'Código postal',
    neighborhood: 'Vecindario',
    street: 'Dirección',
    number: 'Número',
    complement: 'Complemento',
    document: 'Documento',
    description: 'Descripción',
    externalId: 'Identificador externo',
    observation: 'Observación',
    lot: 'Lote',
    resourceId: 'Recurso',
    intrinsicMarking: 'Marca intrínseca',
    manufacturing: 'Fabricación',
    due: 'Vencimiento',
    block: 'Cuadra',
    inspection: 'Fecha de inspección',
    days: 'Días',
    nickname: 'Nombre completo',
    equals: 'Es igual',
    not: 'Es diferente',
    like: 'Contiene',
    moreThan: 'Más grande que',
    lessThan: 'Menos que',
    board: 'Junta',
    brand: 'Marca',
    model: 'Modelo',
    driversDocument: 'Documento de identificación',
    bondType: 'Tipo de Vínculo',
    company: 'Empresa',
    driversLicense: 'Licencia de conducir',
    driversLicenseValidity: 'Validez de la licencia de conducir',
    unassignedResource: 'Recurso no asignado',
    licensePlate: 'informe a la junta',
    racks: 'Nombre del rack',
    min: 'Cantidad mínima',
    max: 'Cantidad máxima',
    capacity: 'Capacidad (litros)',
    rackId: 'Rack',
    equipmentId: 'Equipos',
    procedureId: 'Pops',
    serialNumber: 'Numero de serie',
    amountTag: 'Cantidad de etiquetas',
    occupation: 'Ocupación (litros)',
    occupationStorage: 'Ocupación del Tanque',
    url: 'URL',
    port: 'Puerto TCP',
    isConductor: 'Es un conductor',
    gasFirstId: 'Primera materia prima',
    gasSecondId: 'Segunda materia prima',
    gasFirstPorcentage: 'Primer porcentaje de materia prima',
    gasSecondPorcentage: 'Porcentaje segunda materia prima',
    isMixture: 'Es un gas mixto',
  },
  select: {
    placeholder: {
      role: 'Ocupación en el sistema',
      fuelType: 'Tipo de combustible',
      subsidiary: 'Subsidiaria',
      invoiceTypes: 'Tipo de factura',
      invoiceStatus: 'Estado de la factura',
      selectId: 'Seleccione',
      selectLink: 'Selecciona tu enlace',
      selectCategory: 'Seleccione la categoría de su licencia de conducir',
      selectAnalyze: 'Requiere análisis al recibir',
      selectFinality: 'Finalidad',
      conductor: 'Conductor',
      selectEquipments: 'Equipo',
      pops: 'Pops',
      noRawMaterialFound: 'No se encontró materia prima',
      noEquipmentFound: 'No se encontró equipo',
      noProcedureFound: 'No se encontró ningún procedimiento',
    },
    loading: 'Cargando...',
    noOptions: {
      noRole: 'No se encontraron ocupaciones.',
      noFuelType: 'No se encontró ningún tipo de combustible.',
      noSubsidiary: 'No se encontró ninguna subsidiaria.',
      noInvoiceTypes: 'No se encontró ningún tipo de factura.',
      noInvoiceStatus: 'No se encontró el estado de la factura.',
      noConductor: 'No se encontraron controladores.',
    },
  },
  button: {
    login: 'Acceso',
    filter: 'Filtrar',
    previous: 'Anterior',
    next: 'Próximo',
    register: 'Registrar',
    cleanFilters: 'Filtros limpios',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    import: 'Importar',
    add: 'Agregar',
    resendCode: 'Reenviar codigo',
    addCode: 'Agregar codigo',
    update: 'Actualizar',
  },
  listing: {
    name: 'Nombre',
    creationDate: 'Fecha de creación',
    quickActions: 'Acciones rápidas',
    email: 'Correo electrónico',
    role: 'Ocupación en el sistema',
    client: 'Cliente',
    ncm: 'NCM',
    ean: 'EAN-13',
    proportion: 'Proporción',
    fuelAvailable: 'Gasolina disponible',
    accessKey: 'Llave de acceso',
    productAmount: 'Cantidad de productos',
    state: 'Expresar',
    document: 'Documento',
    documentType: 'Tipo de documento',
    codeValue: 'Valor de código',
    due: 'Caducidad',
    resource: 'Recurso',
    inspection: 'Inspección',
    generated: 'Generado',
    amount: 'Cantidad',
    responsible: 'Responsable',
    identifier: 'Identificador',
    currentState: 'Estado actual',
    user: 'Usuario',
    event: 'Evento',
    idEvent: 'Id evento',
    cylinder: 'Cilindro',
    board: 'Junta',
    brand: 'Marca',
    model: 'Modelo',
    driversDocument: 'Documento de identificación',
    bondType: 'Tipo de Vínculo',
    company: 'Empresa',
    driversLicense: 'Licencia de conducir',
    driversLicenseType: 'Categoría',
    time: 'Tiempo',
    min: 'Cantidad mínima',
    max: 'Camntidad máxima',
    printerAddress: 'Dirección de la impresora',
    eventCurrent: 'Evento Actual',
    generatedCode: 'Código generado por el sistema',
  },
  accessibility: {
    iconTitle: {
      deleteCode: 'Eliminar código',
      details: 'Detalles',
      edit: 'Editar',
      importInvoice: 'Importar factura',
      increaseFuelStock: 'Agregar combustible',
      delete: 'Borrar',
      cancel: 'Cancelar',
      dispatch: 'Enviar',
      close: 'Cerrar',
      clearSearch: 'Borrar búsqueda',
      search: 'Buscar',
      language: 'Idioma del sitio',
      myAccount: 'Mi cuenta',
      filterDate: 'Filtrar por fecha',
      activeUser: 'Activar empleado',
      deactiveUser: 'Desactivar empleado',
      activeVehicle: 'Activar vehículo',
      deactiveVehicle: 'Desactivar vehículo',
      activeConductor: 'Activar conductor',
      deactiveConductor: 'Desactivar conductor',
      deactive: 'Desactivar',
      active: 'Activar',
      block: 'Bloquear',
      activeRack: 'Activar rack',
      deactiveRack: 'Desactivar rack',
      printer: '',
      sendPrinter: '',
    },
  },
  invoiceFields: {
    access_key: 'Clave de acceso',
    address: 'Dirección',
    number: 'Número',
    neighborhood: 'Barrio',
    city_name: 'Ciudad',
    city_code: 'Código de ciudad',
    state: 'Estado',
    zip_code: 'ZIP Code ',
    nation_name: 'País',
    nation_code: 'Código de país',
    phone: 'Teléfono',
    destination_name: 'Nombre del destinatario',
    document: 'Documento',
    amount: 'Cantidad',
    unit: 'Unidad',
    price: 'Precio',
    name: 'Nombre del producto',
  },
  page: {
    generic: {
      list: {
        filter: {
          title: 'Filtros',
          total: 'Total',
          filterAnyData: 'Filtrar datos',
          others: 'Otros filtros',
          role: 'Ocupación',
          noFilterMessage: 'Ningún filtro seleccionado',
        },
        noDataMessage: 'Sin datos existentes',
        download: 'Download',
      },
      save: {
        headerTitle: 'Agregar',
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar {{identifier}}',
      },
      details: {
        headerTitle: 'Detalles',
        notAssociated: 'No asociado',
        uninformed: 'No informado',
      },
    },
    cylinder: {
      actived: 'string',
      new: 'Cilindros nuevos',
    },
    login: {
      formLabel: 'Ingrese',
      formTitle: 'a su cuenta',
      button: {
        forgotPassword: 'Olvide mi contraseña',
      },
    },
    events: {
      headerTitle: 'Eventos',
    },
    users: {
      headerTitle: 'Usuarios',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Activado',
          disabled: 'Discapacitado',
        },
        noDataMessage: 'No se encontraron usuarios',
        modal: {
          search: {
            title: 'Busca en usuarios',
          },
          filter: {
            title: 'Filtrar usuarios',
          },
          confirmStatusChange: {
            title: 'Confirmar cambio',
            activate: 'activar',
            disable: 'desactivar',
            warning:
              'Estás intentando {{action}} el usuario {{name}}. ¿Está usted seguro de eso?',
          },
        },
      },
      save: {
        headerTitle: 'Registrarse',
        title: 'Registrar un usuario',
        error: {
          cant_add_user: 'No tienes permiso para registrar usuarios.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar un usuario',
        error: {
          cant_edit_user: 'No tiene permiso para modificar usuarios.',
        },
      },
    },
    racks: {
      headerTitle: 'Racks',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Activado',
          disabled: 'Discapacitado',
        },
        noDataMessage: 'No se encontraron racks',
        modal: {
          search: {
            title: 'Pesquise em racks',
          },
          filter: {
            title: 'Filtrar racks',
          },
          confirmStatusChange: {
            title: 'Confirmar cambio',
            activate: 'activar',
            disable: 'desactivar',
            warning:
              'Estás intentando {{action}} el rack {{name}}. ¿Está usted seguro de eso?',
          },
        },
      },
      save: {
        headerTitle: 'Registrar',
        title: 'Registrar un rack',
        error: {
          cant_add_racks: 'No tienes permiso para registrar rack.',
          cant_add_purpose: 'No tienes permiso para configurar finalidad.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar un rack',
        error: {
          cant_edit_racks: 'No tiene permiso para modificar rack.',
        },
      },
      configRacks: {
        subTitle: 'Configurar',
        headerTitle: 'Configurar',
        title: 'Configurar Racks',
        error: {
          cant_edit_equipment: 'No tiene permiso para modificar equipo.',
        },
      },
      purposeRacks: {
        subTitle: 'Configurar Finalidad',
        headerTitle: 'Finalidad',
        title: 'Finalidad',
        error: {
          cant_edit_equipment:
            'Você não possui permissão para editar equipamentos.',
        },
      },
    },
    vehicles: {
      headerTitle: '  Vehículos',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Activado',
          disabled: 'Discapacitado',
        },
        noDataMessage: 'No se encontraron vehículos',
        modal: {
          search: {
            title: 'Pesquise em vehículos',
          },
          filter: {
            title: 'Filtrar vehículos',
          },
          confirmStatusChange: {
            title: 'Confirmar cambio',
            activate: 'activar',
            disable: 'desactivar',
            warning:
              'Estás intentando {{action}} el vehículo {{name}}. ¿Está usted seguro de eso?',
          },
        },
      },
      save: {
        headerTitle: 'Registrar',
        title: 'Registrar un vehículo',
        error: {
          cant_add_vehicles: 'No tienes permiso para registrar vehículo.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar un vehículo',
        error: {
          cant_edit_vehicles: 'No tiene permiso para modificar vehículo.',
        },
      },
    },
    conductors: {
      headerTitle: 'Conductores',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Activado',
          disabled: 'Discapacitado',
        },
        noDataMessage: 'No se encontraron conductores',
        modal: {
          search: {
            title: 'Pesquise em conductores',
          },
          filter: {
            title: 'Filtrar conductores',
          },
          confirmStatusChange: {
            title: 'Confirmar cambio',
            activate: 'activar',
            disable: 'desactivar',
            warning:
              'Estás intentando {{action}} el conductore {{name}}. ¿Está usted seguro de eso?',
          },
        },
      },
      save: {
        headerTitle: 'Registrar',
        title: 'Registrar un conductor',
        error: {
          cant_add_conductors: 'No tienes permiso para registrar conductor.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Detalles del conductor',
        error: {
          cant_edit_conductors: 'No tiene permiso para modificar conductor.',
        },
      },
    },
    gas: {
      headerTitle: 'Materia Prima',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Activado',
          disabled: 'Discapacitado',
        },
        noDataMessage: 'No se encontraron materia prima',
        modal: {
          search: {
            title: 'Pesquise em materia prima',
          },
          filter: {
            title: 'Filtrar materia prima',
          },
          confirmStatusChange: {
            title: 'Confirmar cambio',
            activate: 'activar',
            disable: 'desactivar',
            warning:
              'Estás intentando {{action}} el gas {{name}}. ¿Está usted seguro de eso?',
          },
        },
      },
      save: {
        headerTitle: 'Registrar',
        title: 'Registrar Materia Prima',
        error: {
          cant_add_gas: 'No tienes permiso para registrar materia prima.',
        },
      },
      edit: {
        headerTitle: 'Materia Prima',
        title: 'Editar',
        error: {
          cant_edit_gas: 'No tiene permiso para modificar materia prima.',
        },
      },
    },
    storage: {
      headerTitle: 'Almacenamiento',
      list: {
        filter: {
          type: 'Tipo',
          activated: 'Activado',
          disabled: 'Discapacitado',
        },
        noDataMessage: 'No se encontraron almacenamiento',
        modal: {
          search: {
            title: 'Pesquise em almacenamiento',
          },
          filter: {
            title: 'Filtrar almacenamiento',
          },
          confirmStatusChange: {
            title: 'Confirmar cambio',
            activate: 'activar',
            disable: 'desactivar',
            warning:
              'Estás intentando {{action}} el almacenamiento {{name}}. ¿Está usted seguro de eso?',
          },
        },
      },
      save: {
        headerTitle: 'Registrar',
        title: 'Registrar Almacenamiento',
        error: {
          cant_add_storage: 'No tienes permiso para registrar almacenamiento.',
        },
      },
      edit: {
        headerTitle: 'Editar',
        title: 'Editar almacenamiento',
        error: {
          cant_edit_storage: 'No tiene permiso para modificar almacenamiento.',
        },
      },
    },
    printers: {
      headerTitle: 'Impresoras',
      tag: {
        headerTitle: 'Etiqueta de impresión',
      },
    },
    distributors: {
      headerTitle: 'Distribuidores',
      list: {
        filter: {
          total: 'Total',
        },
        noDataMessage: 'No se encontraron distribuidores',
        modal: {
          search: {
            title: 'Busca en distribuidores',
          },
          filter: {
            title: 'Filtrar distribuidores',
          },
        },
      },
      save: {
        headerTitle: 'Registrarse',
        title: 'Registrar distribuidor',
        subTitle: 'Registrar usuario administrador',
        error: {
          cant_add_distributor:
            'No tiene permiso para registrar distribuidores.',
        },
      },
      edit: {
        error: {
          cant_edit_distributor:
            'No tienes permiso para editar un distribuidor.',
        },
      },
      details: {
        headerTitle: 'Detalles',
        title: 'Subsidiarias',
        noSubsidiariesFound: 'No se encontraron subsidiarias',
        noStockFound: 'No hay stock disponible',
        stockItem: 'Tiene {{amount}}L (litros) de {{name}}',
      },
    },
    invoices: {
      headerTitle: 'Facturas',
      list: {
        noData: 'No factura importado.',
        modal: {
          search: {
            title: 'Busca en usuarios',
          },
          transferCustody: {
            title: 'Transferencia de custodia',
          },
          confirmCancel: {
            title: 'Cancelar factura',
            warning:
              'Esta acción es IRREVERSIBLE. ¿Está seguro de que desea cancelar esta factura?',
          },
          filter: {
            title: 'Filtrar facturas',
          },
        },
        types: {
          company: 'Meta',
          distributor: 'Distribuidores',
        },
        status: {
          created: 'Creada/Sin movimiento',
          dispatched: 'Carga enviada',
          received: 'Carga recibida',
          canceled: 'Cancelado',
        },
      },
      save: {
        headerTitle: 'Facturas',
        error: {
          cant_import_invoices: 'No tienes permiso para importar facturas.',
          no_files_inserted: 'No se insertó ningún archivo.',
        },
        xFilesInserted: '{{length}} archivos insertados.',
        uploadBoxLabel: 'Haga clic o suelte archivos aquí.',
      },
      details: {
        label: {
          accessKey: 'Llave de acceso',
          importDate: 'Fecha de importación (en el sistema)',
          dispatchDate: 'Fecha de envio',
          receiverName: 'Nombre del destinatario',
          receiverDocument: 'Documento del destinatario',
          documentType: 'Tipo de Documento',
          city: 'Ciudad',
          state: 'Estado',
          zipCode: 'Código postal',
          neighborhood: 'Vecindario',
          address: 'Dirección',
          phone: 'Teléfono',
          driverName: 'Nombre del conductor',
          driverEmail: 'E-mail',
          registrationKey: 'Llave de registro',
          ean: 'EAN-13: ',
          ncm: 'NCM: ',
          price: 'Precio: ',
          racks: 'Nombre del rack',
          min: 'Cantidad mínima',
          max: 'Cantidad máxima',
        },
        value: {
          notDispatched: 'Não despachada',
        },
        productsTitle: 'Productos',
      },
    },
    subsidiaries: {
      headerTitle: 'Subsidiarias',
      list: {
        noDataMessage: 'No se encontraron subsidiarias',
        modal: {
          search: {
            title: 'Busca en subsidiarias',
          },
          filter: {
            title: 'Filtrar subsidiarias',
          },
        },
      },
    },
    fuelTypes: {
      headerTitle: 'Tipos de combustibles',
      list: {
        noDataMessage: 'No se encontraron tipos',
        modal: {
          search: {
            title: 'Busca en tipos de combustibles',
          },
          filter: {
            title: 'Filtrar tipos de combustibles',
          },
        },
      },
      save: {
        headerTitle: 'Agregar',
        title: 'Agregar un tipo de combustible',
        subTitle: 'Proporción en litros (Combustible x Catalizador)',
        error: {
          cant_add_fuel_types:
            'No está permitido agregar tipos de combustible.',
        },
      },
      edit: {
        error: {
          cant_edit_fuel_types:
            'No está permitido editar tipos de combustible.',
        },
      },
    },
    distributorFuels: {
      headerTitle: 'Combustibles',
      list: {
        noDataMessage: 'No se encontraron combustibles',
        modal: {
          search: {
            title: 'Busca en combustibles',
          },
          filter: {
            title: 'Filtrar combustibles',
          },
          increaseFuelStocks: {
            title: 'Agregar combustibles al invetario',
          },
        },
      },
      save: {
        headerTitle: 'Agregar',
        title: 'Agregar un combustible',
        error: {
          cant_add_distributorFuels: 'No está permitido agregar combustibles.',
        },
      },
      edit: {
        error: {
          cant_edit_distributorFuels: 'No está permitido editar combustibles.',
        },
      },
    },
    activateAccount: {
      title: 'Activa tu cuenta',
    },
    addEvent: {
      headerTitle: 'Actualizar el estado del producto',
      noCodeOnList: 'No se agregó código a la lista.',
      events: {
        updated: 'Actualizado',
        canceled: 'Cancelado',
        misplaced: 'Extraviado',
        stolen: 'Robado',
        damaged: 'Roto',
      },
      error: {
        code_already_added: 'El código {{code}} ya se ha agregado a la lista.',
        no_code_added: 'No se agregó código.',
        unknow_user: 'Usuario no encontrado',
        system_generated: 'Sistema generado',
      },
    },
    passwordRecovery: {
      title: 'Recuperación de contraseña',
    },
    passwordUpdate: {
      title: 'Actualiza contraseña',
    },
    safety: {
      headerTitle: 'Reglas',
      title: 'Todas las reglas',
      add: {
        title: 'Agregar regla',
      },
      edit: {
        title: 'Editar rol',
        information: 'Información para este rol',
      },
      permissionsLabel: 'Permisos',
      error: {
        no_permissions_selected: 'No se seleccionaron permisos',
      },
      permission: {
        module: {
          distributor: 'Distribuidor',
          distributorFuel: 'Distribuidor de combustible',
          distributorInvoice: 'Factura de distribuidor',
          event: 'Eventos',
          fuelStock: 'Inventario de combustible',
          fuelType: 'Tipo de combustible',
          gasStation: 'Gasolinera',
          invoice: 'Nota fiscal',
          role: 'Regla',
          subsidiary: 'Subsidiario',
          user: 'Usuario',
          company: 'Empresa',
          client: 'Cliente',
          resource: 'Recurso',
          catalyst: 'Catalizador',
          conductor: 'Conductores',
          vehicle: 'Vehículos',
          gas: 'Materia Prima',
          rack: 'Racks',
          storage: 'Almacenamiento',
          receive: 'Recebimiento',
          settingRack: 'Configuración de Bastidor',
          settingPurpose: 'Propósito de la Configuración',
          updateStock: 'Actualizar Stock',
          delivery: 'Embarque',
          printers: 'Impressora',
          landing: 'Desembarque',
          manifest: 'Manifiesto de carga',
          movement: 'Movimienot',
          accessProfile: 'Perfil de acceso',
        },
        type: {
          nothing: 'Ninguno',
          index: 'Leer',
          store: 'Leer y crear',
          index_update: 'Leer, actualizar',
          update: 'Leer, actualizar y eliminar',
          delete: 'Leer, crear, actualizar y eliminar',
          index_event: 'Lista de eventos',
          store_event_transfer_custody: 'Transferencia de custódia',
          store_event_update: 'Actualizar',
          store_event_cancel: 'Cancelar',
          store_event_misplaced: 'Perdió',
          store_event_damaged: 'Dañado',
          store_event_receipt_confirmed: 'Acuse de recibo',
          available: 'Disponible',
          hidden: 'Oculto',
        },
      },
    },
    tracking: {
      headerTitle: 'Trazabilidad',
      noEvents: 'Ningún movimiento realizado.',
      modal: {
        search: {
          title: 'Busca en movimientos',
        },
      },
      popupLabel: {
        name: 'Nombre',
        realizedAt: 'Llevado a cabo en',
      },
      filterLabel: {
        all: 'Todos',
        write: 'Escritura',
        read: 'Lectura',
      },
    },
    settings: {
      headerTitle: 'Ajustes',
    },
    status: {
      previousStatus: 'Estado anterior',
      headerTitle: 'Estado',
      detailsHeaderTitle: 'Detalles',
      save: {
        headerTitle: 'Agregar estado',
        [TypePrevious.ONE_OF]: 'Uno de los',
        editable: 'Editable',
        statusFields: 'Campos do estado',
      },
    },
    clients: {
      headerTitle: 'Clientes',
      save: {
        title: 'Agregar clientes',
      },
      edit: {
        title: 'Editar cliente',
      },
    },
    resources: {
      headerTitle: 'Recursos',
      save: {
        headerTitle: 'Agregar característica',
        title: 'Cilindro de registro',
      },
      edit: {
        headerTitle: 'Edición De Recursos',
        title: 'Para Editar',
      },
    },
    codes: {
      headerTitle: 'Códigos',
      due: {
        headerTitle: 'Vencimiento',
        list: {
          noDataMessage: 'Sin datos existentes',
          filter: {
            type: 'Filtrar Vencimiento',
          },
          modal: {
            filter: {
              title: 'Filtrar Dias',
            },
          },
        },
      },
      inspection: {
        headerTitle: 'Inspección',
        list: {
          noDataMessage: 'Sin datos existentes',
          filter: {
            type: 'Filtrar Vencimiento',
          },
          modal: {
            filter: {
              title: 'Filtrar Dias',
            },
          },
        },
      },
      details: {
        label: {
          code: 'Código',
          updatedAt: 'Última actualización',
          accessKey: 'Llave de acceso',
          resourceName: 'Recurso',
          currentState: 'Evento Actual',
          externalCode: 'Código Externo',
          lot: 'Lote',
          aggregatorCode: 'Código agregador',
        },
      },
    },
  },
  error: {
    validation: {
      email_invalid: 'Correo electrónico inválido',
      email_required: 'Ingrese un correo electrónico',
      password_required: 'Ingrese una contraseña',
      name_required: 'Introduzca su nombre',
      description_required: 'Informar la descripción',
      phone_required: 'Informar al teléfono',
      max_char_limit_reached: 'Se alcanzó el máximo de caracteres',
      min_password_char_required:
        'La contraseña debe tener al menos 8 caracteres',
      confirm_password_required: 'Confirme la contraseña',
      password_dont_match_pattern:
        'Utilice ocho o más caracteres con una combinación de letras, números y símbolos.',
      passwords_dont_match: 'Las contraseñas no coinciden',
      distributor_name_required: 'Ingrese el nombre del distribuidor',
      location_permission_required: 'Precisamos ter acesso a sua localização.',
      ncm_required: 'Ingrese el NCM',
      ean_required: 'Ingrese el EAN-13',
      fuel_type_required: 'Ingrese el tipos de combustible',
      event_required: 'Ingresse el evento',
      observation_required: 'Ingrese una observación',
      fuel_amount_required: 'Ingrese la cantidad de combustible',
      access_denied: 'Acceso denegado.',
      page_not_found: 'Página no encontrada.',
      missing_code: 'No se ingresó ningún código.',
      role_required: 'Introduzca una función',
      document_required: 'Ingrese el documento seleccionado',
      zip_code_required: 'Código postal obligatorio',
      state_required: 'Campo de estado obligatorio',
      city_required: 'Ciudad obligatoria',
      neighborhood_required: 'Barrio obligatorio',
      street_required: 'Calle obligatoria',
      number_required: 'Numero obligatorio',
      driversDocument_required:
        'Ingresa tu documento para completar el registro',
      bondType_required: 'Enlace obligatorio',
      company_required: 'Empresa obligatorio',
      board_required: 'Informar la matrícula del vehículo',
      brand_required: 'Marca obligatorio',
      model_required: 'Modelo obligatorio',
      digit: 'Se ingresaron letras en el campo - Documento inválido',
      max: 'El documento de la CNH consta de 11 dígitos - CNH no válido',
      min: 'El documento de la CNH consta de 11 dígitos - CNH no válido',
      driversLicense_required: 'Informar la licencia de conducir',
      driversLicenseValidity_required:
        'Informar la vigencia de la licencia de conducir',
      driversLicenseType_required:
        'Ingrese la categoría de su licencia de conducir',
      invalid_date_format: 'Formato de fecha inválido, usar DD/MM/AAAA',
      receiptAnalysis_required: 'Seleccione una opción',
      receiptFinality_required: 'Seleccione una opción',
      racks_required: 'Ingresse el nombre del rack',
      min_required: 'Informar la cantidad mínima',
      max_required: 'Informar la cantidad máxima',
      gas_required: 'Ingresse el nombre de la materia prima',
      select: 'Seleccione una materia prima',
      capacity:
        'La capacidad de almacenamiento debe ser un valor entero mayor que cero',
      capacity0:
        'La capacidad de almacenamiento debe ser un valor entero mayor que cero',
      equipmentId_required: 'Informar al equipos',
      rackId_required: 'Infomar el rack',
      procedure_required: 'informar los procedimientos',
      value_min: 'La cantidad mínima debe ser un valor entero mayor que 0',
      value_max: 'El monto máximo debe ser un valor entero mayor que el mínimo',
      just_numbers: 'Campo exclusivo para números',
      just_numberIntegerMin:
        'Este campo solo acepta números enteros mayores que 0',
      just_numberIntegerMax:
        'Este campo solo acepta números enteros mayores que el mínimo',
      url_required: 'Reportar una URL',
      port_required: 'Reportar un puerto',
      just_numbersInteger: 'Ingrese solo números enteros',
      selectRack: 'Selecione um rack',
      purpose_finality: 'Informar el propósito',
      nameRequired: 'Inforar un nombre',
      gasFirstPorcentage: 'Primer porcentaje de materia prima',
      gasSecondPorcentage: 'Porcentaje segunda materia prima',
      gasNotFound: 'No se ha encontrado materia prima',
      gasFirstId: 'Seleccione una materia prima',
      gasFirstPorcentagem:
        'El porcentaje mínimo debe ser un valor entero mayor que 0',
      gasSecondPorcentagem:
        'El porcentaje mínimo debe ser un valor entero mayor que 0',
      unitMeasurement: 'Ingrese la unidad de medida',
      value0: 'Debe ser un número entero mayor que 0',
      ncm: 'Ingrese a la nomenclatura común del mercosur (NCM)',
      fullSize: 'Introduzca el peso del paquete completo',
      gasCapacity: 'Introduzca la capacidad de gas',
      litersCapacity: 'Introduzca la capacidad en litros',
      taxCodeCest: 'Introduzca el código de sustitución de impuestos (CEST)',
      emptySize: 'Introduzca el peso del embalaje vacío.',
      currencyPackageValue: 'Ingrese el valor del paquete en moneda local',
    },
    api: {
      // server
      internal_server_error: 'Error de servidor interno',
      communication_failure: 'Falta de comunicación',
      // company
      company_not_found:
        'No se encontraron datos de compania. Informe al administrador del sistema. ',
      consumer_document_already_register:
        'Un consumidor ya está registrado con este documento.',
      consumer_email_already_exist:
        'Un consumidor ya está registrado con este correo electrónico.',
      consumer_not_found:
        'Consumidor no encontrado. {{identifier}} no encontrado. ',
      consumer_password_incorrect: 'Contraseña incorrecta.',
      consumer_phone_already_register:
        'Un consumidor con este teléfono ya está registrado.',
      distributor_fuel_not_found: 'Combustible no encontrado.',
      missing_fuel_ean_and_ncm: 'EAN-13 y NCM no encontrados.',
      distributor_already_register: 'Distribuidor ya registrado',
      distributor_not_found: 'Distribuidor no encontrado',
      driver_client_not_found: 'Cliente no encontrado.',
      driver_document_already_register:
        'Ya hay un controlador registrado con este documento.',
      driver_profile_not_found: 'Driver no encontrado.',
      driver_work_already_delivered:
        'Este servicio ya se ha completado. Carga entregada. ',
      driver_work_not_found: 'Servicio no encontrado.',
      gas_station_not_found: 'Publicación no encontrada.',
      gas_station_stock_blocked: 'El stock de esta estación está bloqueado.',
      gas_station_stock_not_available:
        'Stock de la estación no disponible: no hay stock suficiente para la acción.',
      gas_station_stock_not_found: 'Stock station not found.',
      error_when_import_invoice:
        'Error al intentar importar la factura, inténtelo de nuevo.',
      invoice_already_dispatched: 'La factura ya ha sido enviada.',
      invoice_cancel_failed:
        'Error al intentar cancelar la factura. Inténtalo de nuevo.',
      invoice_not_able_to_cancel: 'La factura ya no se puede cancelar.',
      invoice_not_able_to_work: 'Factura no apta para trabajos.',
      invoice_not_found: 'Factura no encontrada.',
      invoice_products_identifiers_not_found:
        'EAN-13 y NCM del producto {{productName}} de la factura no encontrado.',
      missing_field_in_xml_invoice:
        'No se encontró el campo {{field}} de la factura.',
      no_fiels_uploaded: 'Ningún archivo subido.',
      user_not_allowed_to_import_invoice:
        'No tiene permiso para importar una factura.',
      fuel_type_already_register: 'Tipo de combustible ya registrado.',
      fuel_type_not_found: 'Tipo de combustible no encontrado.',
      access_key_receipt_not_found: 'Recibo no encontrado.',
      consumer_invoice_not_found: 'Factura de consumidor no encontrada.',
      permission_not_allowed:
        'El rol que está intentando crear tiene permisos que no están permitidos para su usuario.',
      permission_not_found: 'Permiso no encontrado.',
      role_already_exist: 'Función ya creada.',
      role_not_found: 'Función no encontrada.',
      amount_not_available: 'No hay suficientes códigos. Inténtalo de nuevo.',
      code_not_found: 'Código no encontrado.',
      movement_permission_denied: 'No se le permite realizar este evento.',
      solicitation_not_found: 'Solicitar códigos no encontrados.',
      cant_disable_user: 'No se le permite activar o desactivar a un empleado.',
      company_deactivated_for_add:
        'La empresa debe estar activada para dar de alta a un empleado.',
      email_already_registered:
        'Un usuario con este correo electrónico ya está registrado.',
      inform_old_password: 'Ingrese la contraseña anterior.',
      incorrect_password: 'Contraseña incorrecta.',
      password_are_the_same: 'Las contraseñas son las mismas.',
      passwords_dont_check: 'Las contraseñas no coinciden',
      role_not_allowed: 'Función del sistema no permitida.',
      token_already_used: 'Token ya usado.',
      token_not_found: 'Token no encontrado.',
      token_not_provided: 'Token no ingresado.',
      user_active_limit:
        'Has excedido tu límite de usuarios activos, actualiza tu plan para activar más.',
      user_add_limit:
        'Has excedido el límite de usuarios registrados, actualiza tu plan para registrarte más.',
      user_already_exist: 'Usuario ya registrado.',
      user_disabled: 'Usuario deshabilitado.',
      user_unauthorized: 'Usuario no autorizado.',
      user_not_found: 'Usuario no encontrado.',
      // session
      token_expired: 'Sesión expirada.',
      conductor_Name_Already_Registered: 'Conductor ya registrado',
      conductor_Not_Found: 'Conductor no encontrado',
      conductor_Drivers_Document_Already_Registered: 'Documento ya registrado',
      client_name_already_exist: 'Cliente ya registrado',
      client_document_already_exist: 'Documento ya registrado',
      client_document_is_invalid: 'CPF o CNPJ no es válido',
      conductor_Drivers_License_Already_Registered:
        'Licencia de conducir ya registrado',
      conductor_Drivers_Document_Is_Invalid: 'CPF no es válido',
      conductor_validity_date_invalid:
        'Fecha de caducidad del permiso de conducir no válida',
      blank_driver_fields:
        'Los campos relacionados con el conductor no pueden estar en blanco',
      blank_gas: 'Por favor complete todos los campos de materia prima',
      conductor_validity_after_the_date:
        'La fecha de caducidad del permiso de conducir debe ser posterior a la fecha actual',
      gas_not_found: 'Materia prima no encontrado',
      gas_double: 'No se permite el registro de gases idénticos.',
      gas_name_already_registered: 'Materia prima ya registrada',
      storage_name_already_registered: 'Nombre ya registrado',
      storage_not_found: 'Registro no encontrado',
      gas_percentage_not_completed:
        'Los porcentajes de gas no están completos. Asegúrese de que la suma de los porcentajes sea igual al 100 % para ambos gases.',
    },
  },
  success: {
    updatedData: 'Datos actualizados con éxito.',
    passwordRecoveryLinkSent:
      'Se envió el enlace de recuperación de contraseña.',
    passwordRecovered: 'Contraseña recuperada.',
    successfullyImportedInvoices: 'Notas Fiscais importadas com sucesso.',
    distributorRegistered: 'Distribuidor registrado exitosamente.',
    transferCustodySuccess: 'Custodia transferida',
    increaseFuelStock: 'Stock de combustible añadido',
  },

  // olds
  Site: {
    initialMessage:
      'Con el sistema de trazabilidad MetaTrack, tiene mayor estabilidad, inteligencia y facilidad para administrar y mover sus productos.',
    accountActive: 'Activa tu cuenta',
    passwordRecovery: '¿Olvidó su contraseña?',
    passwordRecoveryTitle: 'Recupera tu contraseña',
    newPasswordTitle: 'Establezca su nueva contraseña',
  },
  Label: {
    fuelAmount: 'Cantidad de combustible',
    distributorName: 'Nome do cliente',
    ptBR: 'Português (BRL)',
    enUS: 'English (USA)',
    esES: 'Español (ESP)',
    password: 'Contraseña',
    email: 'Correo electrónico',
    verifyCode: 'Código de producto',
    confirmPassword: 'Confirma tu contraseña',
    code: 'Código',
    region: 'Región',
    fuel: 'Cantidad de combustible en litros',
    trade: 'Denominación',
    companyName: 'Nombre de la empresa',
    municipalRegistration: 'Registro municipal',
    municipalExempt: 'Exento',
    stateRegistration: 'Registro estatal',
    stateExempt: 'Exento',
    state: 'Estado',
    city: 'Ciudad',
    address: 'Dirección',
    neighborhood: 'Bairro',
    complement: 'Complemento',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    cep: 'Código postal',
    name: 'Nombre completo',
    phone: 'Teléfono',
    office: 'Cargo',
    contactType: 'Sector',
    ownFleet: 'Flota propria',
    driverName: 'Nombre del controlador',
    destiny: 'Destino',
    licensePlate: 'Placa del vehículo',
    observation: 'Observación',
    aggregateCode: 'Código de volumen / producto',
    aggregationCode: 'Código de volumen',
    nickname: 'Nombre',
    url: 'URL',
    port: 'Puerto TCP',
    productImage: 'Imagen del producto',
    amount: 'Cantidad',
    partitions: 'Colmeias',
    pce: 'PCE',
    country: 'País',
    factory: 'Fábrica',
    weight: 'Peso',
    unit: 'Unidad de medida',
    ean: 'EAN-13',
    dun: 'DUN-14',
    productIdentifier: 'ID de producto',
    stackingLimit: 'Límite de apilamiento',
    warning: 'Advertencia',
    description: 'Descripción',
    perishable: 'Perecedero',
    oldPassword: 'Contraseña anterior',
    newPassword: 'Nueva contraseña',
    shippingCompanyName: 'Nombre o nombre de la empresa',
    productCode: 'Código de producto',
    solicitationDescription: 'Descripción de la solicitud',
    manufacturing: 'Fecha de fabricación',
    due: 'Fecha de vencimiento',
    lot: 'Lote',
    intrinsicMarking: 'Marcado intrínseco',
    partitionsAmount: 'Cantidad de colmenas',
    abbrev: 'Abreviatura',
    un: 'Número ONU',
    netWeight: 'Peso neto',
    netUnit: 'Unidad (Kg, g, ...)',
    grossWeight: 'Peso bruto',
    grossUnit: 'Unidad (Kg, g, ...)',
    productSearch: 'Buscar por nombre o PCE',
    nameSearch: 'Buscar por nombre',
    invoiceSearch: 'Buscar por nombre',
    searchPrinter: 'Buscar por nombre o url',
    carriersSearch: 'Buscar por nombre, CNPJ o CPF',
    activitySearch: 'Pega el código aquí',
    tr: 'TR',
    store: 'Tienda',
    clientSearch: 'Buscar por nombre',
    client: 'Cliente',
    gasId: 'Nombre',
    unitMeasurement: 'Unidad de medida',
    ncm: 'Nomenclatura común del mercosur (NCM)',
    fullSize: 'Peso del paquete completo',
    gasCapacity: 'Capacidad de gas',
    litersCapacity: 'Litros de capacidad',
    taxCodeCest: 'Código de reemplazo de impuestos (CEST)',
    emptySize: 'Peso de embalaje vacío',
    currencyPackageValue: 'Valor del paquete en moneda',
  },
  Button: {
    unBlock: 'Desbloquear',
    confirm: 'Confirmar',
    add: 'Adicionar',
    cancel: 'Cancelar',
    submit: 'Enviar',
    update: 'Actualizar',
    transfer: 'Transferir',
    register: 'Registrarse',
    import: 'Importar',
    addCode: 'Agregar código',
    filter: 'Filtro',
    order: 'Ordenar',
    reprint: 'Reimpresión',
    previous: 'Anterior',
    next: 'Siguiente',
    newRequest: 'Nueva solicitud',
    downloadCodes: 'Códigos de descarga',
    requestPartitionCodes: 'Solicitar códigos de partición',
    aggregateNewPartition: 'Agregar nueva partición',
    printRequests: 'Solicitudes de impresión',
    printPartitionRequests: 'Solicitudes de partición de impresión',
    makeRequest: 'Haga una solicitud',
    makeAggregation: 'Agregado',
    print: 'Imprimir',
    printedRequests: 'Impreso',
    unprintedRequests: 'Sin imprimir',
    product: 'Produto',
    volume: 'Volumen',
    cleanFilters: 'Filtros claros',
    olderRequests: 'Solicitudes más antiguas',
    latestRequests: 'Ultimas solicitudes',
    backToDefault: 'Volver al valor predeterminado',
    dProduction: 'Producción diaria',
    choosePrinter: 'Elija una impresora',
    linkInvoice: 'Enlace NF-e',
    transferInvoice: 'Custodia de transferencia',
    download: 'Descargar',
    edit: 'Editar',
    delete: 'Eliminar',
    details: 'Detalles',
    addRep: 'Añadir delegado',
    moreOptions: 'Más opciones',
  },
  SolicitationPage: {
    title: 'Solicitudes de código',
    products: 'Productos',
    partitions: 'Particiones',
    volumes: 'Volúmenes',
    impressPrint: 'Impresión',
    tableProduct: {
      requestByProduct: 'Solicitud por producto',
      favorites: 'Favoritos',
      products: 'Productos',
      available: 'Disponible',
      print: 'Imprimir',
      generateCodes: 'Generar códigos',
      more: 'Mas',
    },
    tableVolume: {
      volumeRequest: 'Solicitud de volumen',
      amount: 'Cantidad:',
    },
    addSolicitation: {
      subTitle: 'Nueva solicitud',
      cardTitleProduct: 'Generar más código para',
      cardTitlePartition: 'Nueva solicitud de colmena',
      labelProduct: 'Producto',
      labelVolume: 'Volumen',
      selectProduct: 'Seleccionar producto ...',
      selectVolume: 'Seleccione el volumen ...',
      noProductsFound: 'No se encontraron productos',
      noVolumeFound: 'No se encontraron volúmenes',
      yup: {
        noObservation: 'Ingrese la descripción de la solicitud.',
        required: 'Ingrese el número de códigos a generar.',
        amount: 'Ingrese un número para el número de códigos de volumen.',
      },
    },
    addSolicitationModal: {
      title: 'Hacer nueva solicitud a ',
    },
    partitionAggregationModal: {
      title: 'Agregación de colmenas',
      yup: {
        volumeRequired: 'Introduzca un código de volumen.',
        productRequired: 'Introduzca un código de producto',
      },
    },
    addSolicitationPartitionModal: {
      title: 'Solicitud de partición',
    },
    print: {
      selectPrinter: 'Seleccionar impresora ...',
      noPrinterFound: 'No se encontró impresora.',
      noCodeAdded: 'No se agregó código.',
      productPrinting: 'Impresión de producto',
      partitionPrinting: 'Impresión de colmena',
      volumePrinting: 'Impresión de volumen',
      available: 'disponible',
      volumeOf: 'Volumen de',
      availableCode: 'Código (s) disponible (s)',
      yupProduct: {
        requiredLot: 'Ingrese el lote.',
        requiredObservation: 'Informar a la observación de esta operación.',
        amountRequired: 'Ingrese la cantidad de productos a imprimir.',
        manufacturingRequired: 'Ingrese la fecha de fabricación del producto.',
        dueRequired:
          'El producto es perecedero, informar la fecha de vencimiento.',
        intrinsicMarkingRequired: 'Ingrese el marcado intrínseco.',
        printerRequired: 'Elija una impresora.',
      },
      error: {
        description: 'Debes escribir código.',
        codeLimit: 'No puede exceder el límite de agregación.',
        thisCodeOne: 'El Código',
        thisCodeTwo: 'ya está en la lista de códigos.',
        notBelongProduct: 'Este producto no pertenece a este volumen.',
        notBelongVolume: 'Ese volumen no pertenece a este volumen.',
        exceedingLimitCodes: 'La cantidad excede el límite de',
      },
    },
    filterModal: {
      title: 'Filtrar solicitudes',
      subTitleOne: 'Filtrar por estado de impresión',
      subTitleTwo: 'Filtrar por tipo',
      subTitleThree: 'Filtrar por fecha',
    },
    orderModal: {
      title: 'Ordenar solicitudes',
      subTitleOne: 'Ordenar por nombre',
      subTitleTwo: 'Ordenar por fecha',
    },
    reprint: {
      title: 'Reimprimir etiqueta de volumen',
      yup: {
        codeRequest: 'Ingrese el código de volumen.',
        printerRequest: 'Elija una impresora.',
      },
    },
  },

  Volume: {
    title: 'Volúmenes',
    total: 'Total: ',
    selectVolumeContent: 'Seleccionar contenido de volumen',
    all: 'Todos',
    addVolumes: {
      add: 'Agregar',
      edit: 'Detalles',
      cardTitle: 'Agregar un nuevo volumen',
      products: 'Productos',
      volumes: 'Volúmenes',
      selectProduct: 'Seleccionar producto ...',
      selectVolume: 'Seleccione el volumen ...',
      selectBarcode: 'Seleccione el tipo de barras ...',
    },
    name: 'Nombre',
    abbrev: 'Abreviatura',
    amount: 'Cantidad',
    codes: 'Códigos disponibles',
    content: 'Contenido',
    date: 'Fecha',
    actions: 'Más opciones',
    options: {
      details: 'Detalles',
      codeGenerate: 'Generar códigos',
      printBox: 'Cuadro de impresión',
      rePrint: 'Reimprimir caja',
    },
  },
  Locale: {
    role: 'Reglas',
    user: 'Empleados',
    company: 'Empresa',
    license: 'Licencias',
    shippingCompany: 'Transportista',
    solicitation: 'Solicitación',
    code: 'Código',
    invoice: 'Nota Fiscal',
    event: 'Movimiento',
    product: 'Producto',
    printer: 'Impresora',
    report: 'Informe',
    distributor: 'Distribuidores',
    gasStation: 'Gasolinera',
    distributorFuel: 'Combustibles de distribuidor',
    subsidiary: 'Sucursales',
    fuelStock: 'Inventario de combustible',
    employee: 'Empleados',
    gasStationStock: 'Inventario de la gasolinera',
    driverClient: 'Clientes de operador',
    driverWork: 'Servicios de operador',
    driverProfile: 'Perfil de operador',
  },
  Error: {
    register: 'Registrarse',
    previous: 'Anterior',
    next: 'Siguiente',
    dProduction: 'Producción diaria',
    linkInvoice: 'Enlace NF-e',
    transferInvoice: 'Transferir custodia',
    productPermission: 'No tienes permiso para agregar un producto.',
    noDataSolicitation: 'No se encontraron solicitudes.',
    noDataVolume: 'No se encontraron volúmenes',
    noDataProduct: 'No se encontraron ventas',
    locationPermission: 'Debes permitir el acceso a tu ubicación.',
    minorDate:
      'La fecha de vencimiento no puede ser menor que la fecha de fabricación.',
    noExistCode: 'Este código no existe en el sistema.',
    unableCode: 'Este código no se puede imprimir.',
    codeNeverPrinted: 'Este código nunca se imprimió.',
    choosePrinter: 'Elija una impresora',
    userAddPermission: 'No tienes permiso para registrar un empleado.',
    volumeAddPermission: 'No tienes permiso para agregar un volumen.',
    addShippingCompanyAddPermission:
      'No tienes permiso para registrar un transportista.',
    dailyProductionPermission:
      'No se le permite generar un informe sobre la producción diaria.',
    editShippingCompanyAddPermission:
      'No tienes permiso para registrar un transportista.',
    clientAddPermission: 'No tiene permiso para agregar un cliente.',
    employeeAddPermission: 'No tiene permiso para agregar un empleado.',
    representativeAddPermission: 'No tiene permiso para agregar un delegado.',
    clientEditPermission: 'No tiene permiso para editar un cliente.',
    representativeEditPermission: 'No tienes permiso para editar un delegado.',
    volumeEditPermission: 'No tienes permiso para editar un volumen.',
    printerEditPermission: 'No tiene permiso para editar una impresora.',
    userEditPermission: 'No tienes permiso para editar un usuario.',
    employeeEditPermission: 'No tienes permiso para editar un empleado.',
    typePackageProdcut: 'Informar el tipo de embalaje',
    noBarCode: 'Ingrese el tipo de código de barras.',
    existProductIdentifier: 'Ya existe un producto con ese identificador.',
    amountProductSolicitation: 'El monto solicitado no está disponible',
    notFoundBarcode: 'No se encontró ningún tipo de código de barras.',
    serverCommunication: 'Hubo un error al comunicarse con el servidor.',
    noAddress: 'Dirección no encontrada',
    noState: 'No se encontró ningún estado',
    noCity: 'No se encontraron ciudades',
    downloadSucess: 'Archivo descargado correctamente',
    oneField: 'Por favor, complete al menos un campo.',
    deletePermission: 'No tienes permiso para eliminar este elemento.',
    errorPassword:
      'La contraseña debe tener una letra minúscula, una letra mayúscula, un número y un carácter especial.',
  },
  Select: {
    noSubsidiaries: 'noSubsidiaries',
    subsidiaries: 'subsidiaries',
    function: 'Seleccionar ocupación ...',
    statesList: 'Seleccione el estado ...',
    solicitationSearch: 'Buscar por descripción',
    nameSearch: 'Buscar por nombre o correo electrónico',
    noFunction: 'No se encontró ocupación.',
    select: 'Seleccionar ...',
    selectPacking: 'Seleccionar el paquete',
    selectBarCode: 'Seleccione el tipo de código de barras ...',
    selectCity: 'Seleccione la ciudad ...',
    noState: 'No se encontró ningún estado.',
    noCity: 'No se encontraron ciudades.',
    fuelType: 'Seleccione el tipo de combustible',
    noFuelType: 'No hay tipo de combustible disponible, registre al menos uno',
  },
  Navbar: {
    settings: 'Configuración',
    exit: 'Salir',
  },
  Yup: {
    empty: 'Este campo no puede estar vacío',
    noName: 'Ingrese el nombre',
    noEmail: 'Ingrese su correo electrónico',
    validEmail: 'Correo electrónico debe ser válido',
    noPhone: 'Ingrese el teléfono',
    noObservation: 'Ingrese una nota para esta operación.',
    noCode: 'Informar el código de enlace',
    noDistributorName: 'Ingrese el nombre de la organización que se registrará',
    noAmount: 'Ingrese la cantidad.',
    noEan: 'Informar el EAN-13 del producto.',
    noDun: 'Informar al DUN-14 del embalaje.',
    noPositive: 'Este número debe ser positivo',
    noInteger: 'Este número debe ser un entero',
    noWarning: 'Ingrese la advertencia para este volumen',
    noStackingLimit: 'Ingrese el límite de apilamiento para este volumen',
    noDivisible:
      'La cantidad de productos debe ser divisible por la cantidad de colmenas.',
    noBarCode: 'Ingrese el tipo de código de barras.',
    noPartitions: 'Ingrese el número de colmenas.',
    noPacking: 'Informar el tipo de embalaje.',
    noCountry: 'Ingrese el código del país.',
    noFactory: 'Ingrese el código de fábrica.',
    noWeight: 'Informar la masa del producto.',
    noUnity: 'Ingrese la unidad de medida de masa.',
    noId: 'Ingrese el ID del producto',
    noUrl: 'Ingrese la URL de la impresora',
    noPort: 'Ingrese el puerto de la impresora.',
    noAbbrev: 'La abreviatura es obligatoria.',
    noNetWeight: 'Ingrese el peso neto.',
    noGrossWeight: 'Ingrese el peso bruto.',
    noProduct: 'Elija el producto.',
    noVolume: 'Elija el volumen.',
    minFixed3: 'El tamaño debe ser de 3 caracteres.',
    minFixed4: 'La longitud debe ser de 4 caracteres.',
    minFixed5: 'El tamaño debe ser de 5 caracteres.',
    min4: 'La longitud mínima debe ser de 4 caracteres',
    min5: 'El tamaño mínimo debe ser de 5 caracteres',
    max5: 'La longitud máxima debe ser de 5 caracteres',
    min8: 'La longitud mínima debe ser de 8 caracteres',
    maxFixed15: 'El número ingresado excede el límite de 15 caracteres.',
    netUnit: 'Introduzca la unidad de medida del peso neto.',
    grossUnit: 'Introduzca la unidad de medida del peso bruto.',
    noCnpj: 'Informar al CNPJ.',
    noCpf: 'Informar al CPF.',
    sizeCnpj: 'CNPJ no válido, vuelva a escribir.',
    sizeCPF: 'CPF no válido, vuelva a escribir',
    noMunicipalRegistration: 'Informar al padrón municipal.',
    noStateRegistration: 'Informar al registro estatal.',
    noCep: 'Informar al CEP',
    noTargetUf: 'Informar al Estado',
    noTargetCity: 'Informar a la ciudad.',
    noNeighborhood: 'Informar al vecindario.',
    noAddress: 'Ingrese la dirección.',
    noOffice: 'Informar la posición',
    noContactType: 'Informar al sector',
    noPassword: 'Ingrese una contraseña',
    confirmPassword: 'Las contraseñas no coinciden',
    passwordsDontCheck: 'Las contraseñas no son las mismas',
    minPassword: 'La contraseña debe tener al menos 8 caracteres',
    matchesPassword:
      'La contraseña debe tener al menos una letra minúscula, una letra, mayúscula, un número y un carácter especial.',
    requiredField: 'Campo obligatorio',
    noTrade: 'Ingrese su nombre elegante.',
    companyName: 'Introduzca el nombre de su empresa.',
    oldPassword: 'Ingrese la contraseña anterior',
    noDriver: 'Introduzca el nombre del conductor.',
    errorEAN: 'No se permiten letras en un EAN.',
    driverCPF: 'Informar al CPF del conductor.',
    errorCPF: 'CPF no válido, vuelva a escribir.',
    errorCNPJ: 'CNPJ no válido, vuelva a escribir.',
    noDestiny: 'Informar el destino.',
    noPlate: 'Informar a la matrícula.',
    noLocation: 'Debes permitir el acceso a tu ubicación.',
  },
  AddMoviment: {
    title: 'Movimiento',
    noCode: 'No se agregó código.',
    options: {
      deaggregation: 'Desglose de volumen',
      location: 'Ubicación',
      cancel: 'Cancelado',
      lost: 'Perdido',
      damaged: 'Dañado',
      unlinked: 'Desvinculado de NFe',
      partition: 'Cancelar Commeia',
    },
  },
  Company: {
    title: 'Su empresa',
    infoTitle: 'Información de la empresa',
    updateTitle: 'Actualizar datos de la empresa',
  },
  User: {
    subsidiaryName: 'subsidiaryName',
    title: 'Usuários',
    noUser: 'No se encontraron usuários',
    occupation: 'Ocupación:',
    client: 'Cliente',
    role: 'Permisos de usuario',
    actions: 'Más opciones',
    total: 'Total',
    type: 'Tipo',
    creat: 'Unido:',
    infor: 'Información del usuario',
    permi: 'Información del cliente',
    userActive: 'Usuario activado',
    userDeactive: 'Usuario deshabilitado',
    orgName: 'Nombre del cliente:',
    register: 'Registrarse',
    addUser: 'Registrar un nuevo empleado',
    edit: 'Editar',
    active: 'Activo',
    desable: 'No activo',
    name: 'nombre',
    email: 'Correo electrónico',
    editUser: 'Editar empleado',
    disable: {
      part1: '¿Estás seguro de que quieres?',
      active: 'activar',
      deactive: 'deshabilitar',
      activation: 'activación',
      deactivation: 'desactivación',
      part2: 'este empleado?',
      part3: 'Esta es una acción de',
      part4: 'riesgo',
      part5: 'Al confirmar',
      part6: ', esta acción',
      part7:
        'tuvo efectos directos en el uso del sistema por parte del empleado',
    },
  },
  Distributor: {
    addAdmin: 'addAdmin',
    client: 'client',
    type: 'type',
    title: 'Distribuidores',
    noData: 'No se encontraron distribuidores',
    total: 'Total',
    creat: 'Unido:',
    orgName: 'Nombre del cliente:',
    register: 'Registrarse',
    add: 'Registrar un nuevo distribuidor',
    edit: 'Editar',
    name: 'nombre',
    editDistributor: 'Editar cliente',
  },
  Invoice: {
    accessKey: 'accessKey',
    invoiceDelivered: 'invoiceDelivered',
    invoiceDispatched: 'invoiceDispatched',
    name: 'name',
    title: 'Facturas',
    total: 'Total:',
    noMove: 'Sin movimientos',
    aggregated: 'Vinculado',
    transfered: 'Transferencia de custodia',
    nfe: 'NFe',
    selectCarrier: 'Seleccione el transportista',
    number: 'No. de productos',
    state: 'Estado:',
    date: 'Fecha',
    actions: 'Más opciones',
    linkInvoice: 'Enlace NF-e',
    details: 'Detalles',
    importTitle: 'Importación NF-e',
    aggregation: 'notas agregadas',
    trow: 'Arrastra facturas o haz clic aquí',
    noMovimets: 'Sin movimientos',
    linked: 'Vinculado',
    transfer: 'Custodia transferida',
    added: 'Agregado en',
    productsAmount: 'N ° de productos:',
    creat: 'Creado el',
    receiver: 'Destinatario',
    locality: 'Localidad',
    items: 'Artículos',
    ean: 'EAN',
    amount: 'Cantidad',
    price: 'Precio',
    ncm: 'NCM',
    unity: 'Unidad',
    noProduct: 'No se encontraron productos.',
    driverInfo: 'Información del conductor',
    info: 'Información de operación',
    importedSucesfully: 'Notas importadas correctamente.',
    deleteModal: {
      part1: 'Eliminar',
      part2: 'Nota Fiscal',
      part3: 'Esta es una acción de',
      part4: 'riesgo',
      part5: '¿Estás seguro de que quieres',
      part6: 'eliminar',
      part7: 'esta',
      part8: 'Nota Fiscal?',
    },
    dailyProductionModal: {
      title: 'Producción diaria',
      text: '¿Desea descargar el archivo XML de producción diaria?',
    },
  },
  Products: {
    title: 'Productos',
    filter: 'Filtrar por fecha de registro',
    addProducts: 'Registrar un nuevo producto',
    productsCodes: 'Códigos disponibles',
    register: 'Registrarse',
    noBarCode: 'Sin código de barras',
    addImage: 'Elija un archivo',
    total: 'Total:',
    details: 'Detalles',
    date: 'Fecha',
    noData: 'No hay productos registrados.',
    noPacking: 'No packaging found.',
    name: 'Nombre',
    product: 'Producto',
    amount: 'Cantidad',
    actions: 'Más opciones',
    proportions: {
      name: 'Catalizador',
      proportion: 'Proporción',
    },
    options: {
      details: 'Detalles',
      codeGenerate: 'Generar códigos',
      printBox: 'Cuadro de impresión',
      printPartitions: 'Imprimir colmenas',
      partitionSolicitation: 'Solicitar nuevos códigos de particiones',
      aggregatePartition: 'Agregar nueva particiones',
    },
    info: 'Detalles',
    volumes: {
      drum: 'Tambor',
      barrel: 'Barril',
      bombona: 'Bombona',
      box: 'Caja',
      bag: 'Bolsa',
      compositePackaging: 'Embalaje compuesto',
    },
  },
  Printers: {
    edit: 'edit',
    title: 'Impresoras',
    total: 'Total:',
    date: 'Fecha:',
    name: 'nombre',
    port: 'URL: Puerto',
    actions: 'Más opciones',
    noData: 'No se encontró impresora.',
    add: 'Agregar',
    addTitle: 'Agregar una nueva impresora',
    select: 'Seleccionar si es una impresora USB',
    noPrinter: 'No se encontró impresora usb.',
    printer: 'Impresora',
    localPrinter: 'Impresora local',
    delete: {
      part1: '¿Estás seguro de que quieres',
      part2: 'eliminar',
      part3: 'esta impresora?',
      part4: 'Esta es una acción de',
      part5: 'riesgo',
      part6: 'Al confirmar la eliminación, esta acción',
      part7: 'no se puede deshacer',
    },
  },
  Safety: {
    title: 'Perfil de Acceso',
    list: 'Funciones registradas',
    details: 'Ver detalles de la función',
    edit: 'Editar rol',
    register: 'Registrar un nuevo rol',
    choosePermission: 'Elija permisos para este rol',
    select: 'Elegir permisos ...',
    noPermission: 'No se encontraron permisos.',
  },
  Permissions: {
    nothing: 'Ninguno',
    index: 'Leer',
    store: 'Crear',
    indexStore: 'Leer y crear',
    indexUpdate: 'Leer, actualizar',
    update: 'Leer, actualizar y eliminar',
    delete: 'Leer, crear, actualizar y eliminar',
  },
  Settings: {
    title: 'Configuración',
    subtitle: 'Actualice sus datos',
  },
  Report: {
    title: 'Informe',
    dateInit: 'Fecha de inicio',
    dateEnd: 'Fecha de finalización',
    all: 'todo',
    codeState: 'Seleccionar para generar informe de estado del código',
    report: 'Generar informe',
    reportGenerate: 'Ejecute un informe para mostrar los datos',
    pdfGenerate: 'Generar pdf',
  },
  ShippingCompanies: {
    title: 'Transportistas',
    all: 'todo',
    register: 'Registrarse',
    total: 'Total:',
    type: 'Tipo:',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    date: 'Fecha:',
    details: 'Detalles',
    noCarriers: 'No hay transportistas registrados.',
    newCarrier: 'Registrar un nuevo transportista',
    company: 'Empresa ou MEI',
    selfuser: 'autónomo',
    edit: 'Editar',
    companyName: 'Nombre / Nombre de la empresa',
    document: 'CNPJ / CPF',
    actions: 'Más opciones',
  },
  Tracking: {
    title: 'Seguimiento',
    noData: 'No hubo movimientos recientes.',
  },
  Clients: {
    title: 'Clientes',
    add: 'Registrar',
    addClient: 'Registrar un nuevo cliente',
    registerRepresentative: 'Registrar un representante',
    representative: 'Representante',
    all: 'Todo',
    noData: 'Sin clientes registrados',
    date: 'Fecha: ',
    name: 'Relación social/nombre',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    edit: 'Editar',
    total: 'Total: ',
    details: 'Detalles',
    addRepresentative: 'Añadir delegado',
    editRepresentative: 'Editar representante',
    noRepresentative: 'No añadido',
    editClient: 'Editar cliente',
    activeClient: 'Activar cliente',
    deactiveClient: 'Desactivar cliente',
  },
  Employee: {
    title: 'Empleado',
    register: 'Registrar',
    noData: 'No se encontraron empleados',
    add: 'Registrar un nuevo empleado',
    editEmployee: 'Editar empleado',
    activeEmployee: 'Activar empleado',
    deactiveEmployee: 'Desactivar empleado',
  },
  BackEndErrors: {
    // users module
    you_do_not_have_permission_to_enable_or_disable_a_user:
      'No tiene permitido activar o desactivar a un empleado.',
    the_company_must_be_activated_in_order_to_register_a_user:
      'La empresa debe estar activada para dar de alta a un empleado.',
    email_already_registered: 'El correo electrónico ya ha sido registrado.',
    you_have_exceeded_the_limit_of_active_users_upgrade_your_plan_to_register_more:
      'Has excedido el límite de empleados activos, actualiza tu plan para registrarte más.',
    the_company_exceeded_the_activate_users_limit_please_upgrade_your_plan:
      'La empresa ha superado el límite de empleados activos. Actualice su plan. ',
    user_with_this_email_already_registered:
      'El empleado con este correo electrónico ya existe.',
    user_not_found: 'Empleado no encontrado.',
    you_cant_change_your_password_without_inform_your_old_password:
      'No puede cambiar su contraseña sin ingresar su contraseña anterior.',
    incorrect_password: 'Contraseña incorrecta.',
    token_already_used: 'Token ya usado.',
    token_expired: 'Token expired.',
    token_not_found: 'Token no encontrado.',
    user_disabled: 'Empleado discapacitado.',
    // company module
    company_not_found: 'Compañía no encontrada.',
    // solicitation module
    amount_not_available: 'Cantidad no disponible.',
    this_code_is_already_aggregated_to_an_invoice:
      'Este código ya se ha agregado a una factura.',
    code_not_found: 'Código no encontrado.',
    invalid_code: 'Código no válido',
    the_code_does_not_belong_to_a_volume:
      'El código no pertenece a un volumen.',
    solicitation_not_found: 'Solicitud no encontrada',

    'Insufficient partition codes.': 'Códigos de partición insuficientes.',
    'Insufficient volume codes.': 'Códigos de volumen insuficientes.',
    'Insufficient product codes.': 'Códigos de producto insuficientes.',
    'Internal server error.': 'Error interno del servidor',
    'Your license expired. Please, upgrade your plan to get access again.':
      'Su licencia ha caducado, actualice su plan de acceso.',
    'Your license offline time expired. Please, connect you server in internet to get access again.':
      'Su licencia sin conexión ha caducado, conéctese a Internet para volver a acceder.',
    'Access denied.': 'Acceso denegado.',
    'Validation error.': 'Error de validacion.',
    'No invoice aggregate with this code.':
      'No hay factura vinculada a este código.',
    'It is not possible to delete an invoice already aggregated.':
      'No puede eliminar una factura que ya está agregada.',
    'Invoice not found.': 'Factura no encontrada.',
    'Printer not found': 'Impresora no encontrada.',
    'A product with this PCE has already been registered.':
      'Ya se ha registrado un producto con este PCE.',
    'A product with this EAN-13 has already been registered.':
      'Un producto con este ean ya está registrado.',

    'Product not found.': 'Producto no encontrado.',
    'Permission not found.': 'Permiso no encontrado.',
    'A rule with this name has already been created.':
      'Ya se ha creado una regla con este nombre.',
    'There is already a shipping company with this CNPJ.':
      'Ya existe un transportista con este CNPJ.',
    'There is already a shipping company with this CPF.':
      'Ya existe un transportista con este CPF.',
    'Shipping company not found.': 'Transportista no encontrado.',

    'Codes not found.': 'Códigos no encontrados.',
    'Only products solicitations allowed':
      'Solo se permiten solicitudes de productos.',
    'Solicitation already printed.': 'Solicitud ya impresa.',
    'Solicitation not printed.': 'Solicitud no impresa.',
    'A volume with this abbreviation has already been created.':
      'Ya se ha creado un volumen con esta abreviatura.',
    'Volume not found.': 'Volumen no encontrado.',
    'A volume with this has already been created.':
      'Ya se ha creado un volumen con este DUN-14.',
    'No files uploaded': 'Los archivos no fueron cargados.',
    'Unknown permission.': 'Permiso desconocido.',
    'This invoice was already transferred.':
      'Esta factura ya ha sido transferida.',
    'Old password is incorrect.': 'Antigua contraseña incorrecta.',
    'Only products requests allowed':
      'Solo se permiten solicitudes de productos',
    'You cannot change your password without informing your old password.':
      'No puede cambiar su contraseña sin informar su contraseña antigua.',
    'The company has exceeded the activate users limit. Please, upgrade your plan.':
      'La empresa ha superado el límite de activación de empleados. Por favor, actualice su plan.',
    'The company must be activated in order to register an user.':
      'La empresa debe estar activada para registrar a un empleado.',
    'You do not have permission to enable or disable an user.':
      'No tiene permiso para habilitar o deshabilitar un empleado.',
    'Your license expired. Please upgrade your plan to get access again.':
      'Su licencia expiró. Actualice su plan para obtener acceso de nuevo.',
    code_not_active: 'Código no activado',
    code_not_print: 'Código no impreso',
    no_event_found: 'Evento no encontrado',
    code_already_active: 'Código ya activo',
    code_already_active_aggregation: 'Código ya activo para agregación',
    code_not_already_active_aggregation: 'Código no activo para agregación',
    code_does_not_belong_aggregation: 'El código no pertenece a la agregación',
    code_is_not_valid: 'El código no existe',
    code_already_marked: 'Código ya impreso',
    client_document_already_exist:
      'Un cliente ya está registrado con este documento.',
  },
  Modal: {
    unBlock: 'unBlock',
    userFilter: 'Filtrar empleados',
    userOrder: 'Clasificar empleados',
    nameOrder: 'Orden alfabético',
    mailOrder: 'Ordenar por correo electrónico',
    dateFilter: 'Filtrar por fecha:',
    registerFilter: 'Filtrar por fecha de registro',
  },
  PrintTest: {
    title: 'Prueba de impresión del producto',
    text: 'Impresión de etiquetas',
  },
  sideBar: {
    moviments: 'Movimiento',
    tracking: 'Trazabilidad',
    status: 'Estado',
    statusList: 'Lista',
    statusAdd: 'Agregar',
    distributorFuelsAdd: 'Agregar',
    volumes: 'Volúmenes',
    volumesList: 'Lista',
    volumesAdd: 'Agregar',
    testPrint: 'Prueba de impresión',
    shippingCompanies: 'Transportistas',
    shippingCompaniesList: 'Lista',
    shippingCompaniesAdd: 'Agregar',
    invoices: 'Facturas',
    invoicesList: 'Lista',
    invoicesAdd: 'Importar',
    printers: 'Impresoras',
    printersList: 'Lista',
    printersAdd: 'Agregar',
    report: 'Informe',
    users: 'Usuarios',
    usersList: 'Lista',
    usersAdd: 'Agregar',
    vehicles: 'Vehículos',
    vehiclesList: 'Lista',
    vehiclesAdd: 'Agregar',
    racks: 'Racks',
    racksList: 'Lista',
    racksAdd: 'Agregar',
    settingsRacks: 'Configurar Rack',
    conductors: 'Condutores',
    conductorsList: 'Lista',
    conductorsAdd: 'Agregar',
    distributors: 'Distribuidores',
    distributorsList: 'Lista',
    distributorsAdd: 'Agregar',
    company: 'Empresa',
    security: 'Perfil de Acceso',
    settings: 'Configuración',
    clients: 'Clientes',
    clientsAdd: 'Agregar',
    clientsList: 'Lista',
    employee: 'Empleados',
    employeesAdd: 'Añadir',
    employeesList: 'Enumerars',
    subsidiaryStock: 'Control de Inventario',
    gasStations: 'Gasolineras',
    resources: 'Recursos',
    resourcesAdd: 'Agregar',
    resourcesList: 'Lista',
    resourcesEdit: 'Edición',
    clientList: 'Lista',
    clientAdd: 'Agregar',
    codes: 'Codigos',
    due: 'Vencimiento',
    inspection: 'Inspección',
    events: 'Eventos',
    list: 'Lista',
    add: 'Agregar',
    gas: 'Materia Prima',
    gasList: 'Lista',
    gasAdd: 'Agregar',
    storages: 'Almacenamiento',
    storagesList: 'Lista',
    storagesAdd: 'Agregar',
    settingsPurpose: 'Configurar Propósito',
  },
  Success: {
    fuelTypeAdd: '¡Tipo de combustible agregado correctamente!',
    distributorFuelsAdd: '¡Combustible agregado correctamente!',
    companyUpdate: '¡Empresa actualizada correctamente!',
    completeSearch: 'La búsqueda fue exitosa',
    registerUser: 'Empleado registrado correctamente.',
    userUpdate: 'Usuario actualizado.',
    invoiceLinked: 'NF-e aggregado.',
    invoiceTransfered:
      'Custodia de la NF-e transferida al vehículo con matrícula',
    invoiceDelete: 'Factura eliminada.',
    moveError:
      'Se produjo un error al hacer el movimiento. código no encontrado',
    moveSuccess: 'Mover hecho.',
    printDone: 'Impresión terminada.',
    error: 'Error',
    inCode: 'en código',
    rePrintDone: 'Reimpresión realizada.',
    codesError: 'Error en los siguientes códigos:',
    printerCreated: 'Impresora creada.',
    printerUpdate: 'Impresora actualizada.',
    printerDelete: 'Impresora eliminada.',
    productAdd: 'Producto agregado.',
    productUpdate: 'Producto actualizado.',
    newOcupation: 'Función creada.',
    updateRole: 'Reglas actualizadas con éxito.',
    carrierRegister: 'Transportista registrado.',
    carrierUpdate: 'Operador actualizado.',
    solicitationDone: 'Solicitud realizada.',
    aggregationDone: 'Aggregation done.',
    successUpdate: 'Datos actualizados correctamente.',
    successRegister: 'Registro exitoso',
    recoveryEmail:
      'Se ha enviado un enlace de recuperación de contraseña a su correo electrónico.',
    passwordRecovery: 'Contraseña recuperada.',
    activeLink:
      'Se ha enviado un enlace de activación a su correo electrónico.',
    registerActive: 'Cuenta activada con éxito.',
    volumeAdd: 'Volumen agregado.',
    volumeUpdate: 'Volumen actualizado.',
    createClient: 'Cliente creado.',
    updateClient: 'Cliente actualizado.',
    createRepresentative: 'Responsable creado.',
    updateRepresentative: 'Responsable actualizado.',
  },
};

export default locale;
