import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import ConductorDTO from '@/dtos/conductor/ConductorDTO';
import ConductorListResultDTO from '@/dtos/conductor/ConductorListResultDTO';
import ConductorFiltersDTO from '@/dtos/conductor/ConductorFiltersDTO';
import {
  AddConductorDTO,
  ChangeActiveDTO,
  UpdateConductorDTO,
} from '@/dtos/conductor';
import { genericError, genericRequest, genericSuccess } from '../utils';
import { ConductorsState } from './types';

export enum ConductorsTypes {
  ADD_CONDUCTOR_REQUEST = '@conductors/addConductorRequest',
  ADD_CONDUCTOR_SUCCESS = '@conductors/addConductorSuccess',
  ADD_CONDUCTOR_ERROR = '@conductors/addConductorError',
  GET_ONE_CONDUCTOR_REQUEST = '@conductors/getOneConductorRequest',
  GET_ONE_CONDUCTOR_SUCCESS = '@conductors/getOneConductorSuccess',
  GET_ONE_CONDUCTOR_ERROR = '@conductors/getOneConductorError',
  UPDATE_REQUEST = '@conductors/updateRequest',
  UPDATE_SUCCESS = '@conductors/updateSuccess',
  UPDATE_ERROR = '@conductors/updateError',
  GET_CONDUCTORS_REQUEST = '@conductors/getConductorsRequest',
  GET_CONDUCTORS_SUCCESS = '@conductors/getConductorsSuccess',
  GET_CONDUCTORS_ERROR = '@conductors/getConductorsError',
  CHANGE_ACTIVE_REQUEST = '@conductors/changeActiveRequest',
  CHANGE_ACTIVE_SUCCESS = '@conductors/changeActiveSuccess',
  CHANGE_ACTIVE_ERROR = '@conductors/changeActiveError',
  SET_PAGE = '@conductors/setPage',
  CLEAR_CONDUCTOR = '@conductors/clearConductor',
  SET_FILTERS_CONDUCTORS = '@conductors/setFiltersConductors',
}

const ConductorsActions = {
  addConductorRequest: (data: AddConductorDTO) =>
    action(ConductorsTypes.ADD_CONDUCTOR_REQUEST, { data }),
  addConductorSuccess: () => action(ConductorsTypes.ADD_CONDUCTOR_SUCCESS),
  addConductorError: (error: string) =>
    action(ConductorsTypes.ADD_CONDUCTOR_ERROR, { error }),
  getOneConductorRequest: (id: string) =>
    action(ConductorsTypes.GET_ONE_CONDUCTOR_REQUEST, { id }),
  getOneConductorSuccess: (conductor: ConductorDTO) =>
    action(ConductorsTypes.GET_ONE_CONDUCTOR_SUCCESS, { conductor }),
  getOneConductorError: (error: string) =>
    action(ConductorsTypes.GET_ONE_CONDUCTOR_ERROR, { error }),
  getConductorsRequest: (filters: ConductorFiltersDTO) =>
    action(ConductorsTypes.GET_CONDUCTORS_REQUEST, { filters }),
  getConductorsSuccess: (data: ConductorListResultDTO) =>
    action(ConductorsTypes.GET_CONDUCTORS_SUCCESS, data),
  getConductorsError: (error: string) =>
    action(ConductorsTypes.GET_CONDUCTORS_ERROR, { error }),
  updateRequest: (conductor: UpdateConductorDTO) =>
    action(ConductorsTypes.UPDATE_REQUEST, conductor),
  updateSuccess: () => action(ConductorsTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(ConductorsTypes.UPDATE_ERROR, { error }),
  changeActiveRequest: (data: ChangeActiveDTO) =>
    action(ConductorsTypes.CHANGE_ACTIVE_REQUEST, data),
  changeActiveSuccess: () => action(ConductorsTypes.CHANGE_ACTIVE_SUCCESS),
  changeActiveError: (error: string) =>
    action(ConductorsTypes.CHANGE_ACTIVE_ERROR, { error }),
  setPage: (page: number) => action(ConductorsTypes.SET_PAGE, { page }),
  clearConductor: () => action(ConductorsTypes.CLEAR_CONDUCTOR),
  setFiltersConductors: (filters: ConductorFiltersDTO) =>
    action(ConductorsTypes.SET_FILTERS_CONDUCTORS, { filters }),
};

export default ConductorsActions;

const INITIAL_STATE: ConductorsState = {
  data: [],
  selected: {} as ConductorDTO,
  loading: false,
  error: '',
  page: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: {
    page: 1,
  } as ConductorFiltersDTO,
  totalActive: {
    activated: 0,
    disabled: 0,
  },
};

type ConductorsReducer<Action extends AnyAction> = Reducer<
  ConductorsState,
  Action
>;
const getConductorsSuccess: ConductorsReducer<
  ReturnType<typeof ConductorsActions.getConductorsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  page: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
  totalActive: payload.totalActive,
  // filters: { ...state.filters, page: payload.currentPage },
});

const getOneConductorSuccess: ConductorsReducer<
  ReturnType<typeof ConductorsActions.getOneConductorSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  error: '',
  selected: payload.conductor,
});

const setPage: ConductorsReducer<ReturnType<typeof ConductorsActions.setPage>> =
  (state = INITIAL_STATE, { payload }) => {
    return { ...state, page: payload.page };
  };

const clearConductor: ConductorsReducer<any> = () => INITIAL_STATE;

const setFiltersConductors: ConductorsReducer<
  ReturnType<typeof ConductorsActions.setFiltersConductors>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<ConductorsState>(INITIAL_STATE)
  .handleAction(ConductorsTypes.GET_CONDUCTORS_REQUEST, genericRequest)
  .handleAction(ConductorsTypes.GET_CONDUCTORS_SUCCESS, getConductorsSuccess)
  .handleAction(ConductorsTypes.GET_CONDUCTORS_ERROR, genericError)
  .handleAction(ConductorsTypes.GET_ONE_CONDUCTOR_REQUEST, genericRequest)
  .handleAction(
    ConductorsTypes.GET_ONE_CONDUCTOR_SUCCESS,
    getOneConductorSuccess
  )
  .handleAction(ConductorsTypes.GET_ONE_CONDUCTOR_ERROR, genericError)
  .handleAction(ConductorsTypes.ADD_CONDUCTOR_REQUEST, genericRequest)
  .handleAction(ConductorsTypes.ADD_CONDUCTOR_SUCCESS, genericSuccess)
  .handleAction(ConductorsTypes.ADD_CONDUCTOR_ERROR, genericError)
  .handleAction(ConductorsTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(ConductorsTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(ConductorsTypes.UPDATE_ERROR, genericError)
  .handleAction(ConductorsTypes.CHANGE_ACTIVE_REQUEST, genericRequest)
  .handleAction(ConductorsTypes.CHANGE_ACTIVE_SUCCESS, genericSuccess)
  .handleAction(ConductorsTypes.CHANGE_ACTIVE_ERROR, genericError)
  .handleAction(ConductorsTypes.SET_PAGE, setPage)
  .handleAction(ConductorsTypes.CLEAR_CONDUCTOR, clearConductor)
  .handleAction(ConductorsTypes.SET_FILTERS_CONDUCTORS, setFiltersConductors);
