import api from '@/services/api';
import {
  AddVehicleDTO,
  ChangeActiveDTO,
  UpdateVehicleDTO,
  VehicleFiltersDTO,
  VehicleListResultDTO,
  VehicleDTO,
} from '@/dtos/vehicle';

class VehicleServices {
  async create(vehicle: AddVehicleDTO): Promise<VehicleDTO> {
    const { data } = await api.post<VehicleDTO>('/vehicles', vehicle);
    return data;
  }
  async find(vehicleId: string): Promise<VehicleDTO> {
    const { data } = await api.get<VehicleDTO>(`/vehicles/${vehicleId}`);
    return data;
  }
  async update(vehicle: UpdateVehicleDTO): Promise<VehicleDTO> {
    const { data } = await api.put<VehicleDTO>(
      `/vehicles/${vehicle.id}`,
      vehicle
    );
    return data;
  }
  async changeActive(params: ChangeActiveDTO): Promise<VehicleDTO> {
    const { data } = await api.put<VehicleDTO>(
      `/vehicles/${params.id}`,
      params
    );
    return data;
  }
  async list(filters: VehicleFiltersDTO): Promise<VehicleListResultDTO> {
    const { data } = await api.get<VehicleListResultDTO>('/vehicles', {
      params: filters,
    });
    return data;
  }
}

const vehicleServices = new VehicleServices();
export default vehicleServices;
