import PageDTO from '@/dtos/generics/PageDTO';
import { ClientDTO, CreateClientDTO, UpdateClientDTO } from '@/dtos/clients';
import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';

class ClientsServices {
  async create(client: CreateClientDTO): Promise<ClientDTO> {
    const { data } = await api.post<ClientDTO>('/clients', client);
    return data;
  }
  async update(client: UpdateClientDTO): Promise<ClientDTO> {
    const { data } = await api.put<ClientDTO>(`/clients/${client.id}`, client);
    return data;
  }
  async list(filters: SearchQuery): Promise<PageDTO<ClientDTO>> {
    const { data } = await api.get<PageDTO<ClientDTO>>('/clients', {
      params: filters,
    });
    return data;
  }
  async find(clientId: string): Promise<ClientDTO> {
    const { data } = await api.get<ClientDTO>(`/clients/${clientId}`);
    return data;
  }
  async delete(clients: string): Promise<void> {
    await api.delete(`/clients/${clients}`);
  }
  async active(clientId: string): Promise<void> {
    await api.put(`/clients/${clientId}/active`);
  }
}

const clientServices = new ClientsServices();

export default clientServices;
