import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';
import { PageDTO } from '@/dtos/generics';

import RackDTO from '@/dtos/rack/RackDTO';
import RackListResultDTO from '@/dtos/rack/RackListResultDTO';
import RackFiltersDTO from '@/dtos/rack/RackFiltersDTO';
import { AddRackDTO, UpdateRackDTO } from '@/dtos/rack';
import { genericError, genericRequest, genericSuccess } from '../utils';
import { RacksState } from './types';
import SearchQuery from '@/utils/SearchQuery';

export enum RacksTypes {
  ADD_RACK_REQUEST = '@racks/addRackRequest',
  ADD_RACK_SUCCESS = '@racks/addRackSuccess',
  ADD_RACK_ERROR = '@racks/addRackError',
  LIST_REQUEST = '@racks/LIST_REQUEST',
  LIST_SUCCESS = '@racks/LIST_SUCCESS',
  LIST_ERROR = '@racks/LIST_ERROR',
  GET_ONE_RACK_REQUEST = '@racks/getOneRackRequest',
  GET_ONE_RACK_SUCCESS = '@racks/getOneRackSuccess',
  GET_ONE_RACK_ERROR = '@racks/getOneRackError',
  UPDATE_REQUEST = '@racks/updateRequest',
  UPDATE_SUCCESS = '@racks/updateSuccess',
  UPDATE_ERROR = '@racks/updateError',
  GET_RACKS_REQUEST = '@racks/getRacksRequest',
  GET_RACKS_SUCCESS = '@racks/getRacksSuccess',
  GET_RACKS_ERROR = '@racks/getRacksError',
  CHANGE_ACTIVE_REQUEST = '@racks/changeActiveRequest',
  CHANGE_ACTIVE_SUCCESS = '@racks/changeActiveSuccess',
  CHANGE_ACTIVE_ERROR = '@racks/changeActiveError',
  SET_PAGE = '@racks/setPage',
  CLEAR_RACK = '@racks/clearRack',
  SET_FILTERS_RACKS = '@racks/setFiltersRacks',
}

const RacksActions = {
  addRackRequest: (data: AddRackDTO) =>
    action(RacksTypes.ADD_RACK_REQUEST, { data }),
  addRackSuccess: () => action(RacksTypes.ADD_RACK_SUCCESS),
  addRackError: (error: string) => action(RacksTypes.ADD_RACK_ERROR, { error }),
  listRequest: (filters: SearchQuery) =>
    action(RacksTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<RackDTO>) =>
    action(RacksTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(RacksTypes.LIST_ERROR, { error }),
  getOneRackRequest: (id: string) =>
    action(RacksTypes.GET_ONE_RACK_REQUEST, { id }),
  getOneRackSuccess: (rack: RackDTO) =>
    action(RacksTypes.GET_ONE_RACK_SUCCESS, { rack }),
  getOneRackError: (error: string) =>
    action(RacksTypes.GET_ONE_RACK_ERROR, { error }),
  getRacksRequest: (filters: RackFiltersDTO) =>
    action(RacksTypes.GET_RACKS_REQUEST, { filters }),
  getRacksSuccess: (data: RackListResultDTO) =>
    action(RacksTypes.GET_RACKS_SUCCESS, data),
  getRacksError: (error: string) =>
    action(RacksTypes.GET_RACKS_ERROR, { error }),
  updateRequest: (rack: UpdateRackDTO) =>
    action(RacksTypes.UPDATE_REQUEST, rack),
  updateSuccess: () => action(RacksTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(RacksTypes.UPDATE_ERROR, { error }),
  changeActiveRequest: (id: string) =>
    action(RacksTypes.CHANGE_ACTIVE_REQUEST, id),
  changeActiveSuccess: () => action(RacksTypes.CHANGE_ACTIVE_SUCCESS),
  changeActiveError: (error: string) =>
    action(RacksTypes.CHANGE_ACTIVE_ERROR, { error }),
  setPage: (page: number) => action(RacksTypes.SET_PAGE, { page }),
  clearRack: () => action(RacksTypes.CLEAR_RACK),
  setFiltersRacks: (filters: RackFiltersDTO) =>
    action(RacksTypes.SET_FILTERS_RACKS, { filters }),
};

export default RacksActions;

const INITIAL_STATE: RacksState = {
  data: [],
  selected: {} as RackDTO,
  loading: false,
  error: '',
  page: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: {
    page: 1,
  } as RackFiltersDTO,
  totalActive: {
    activated: 0,
    disabled: 0,
  },
};

type RacksReducer<Action extends AnyAction> = Reducer<RacksState, Action>;
const getRacksSuccess: RacksReducer<
  ReturnType<typeof RacksActions.getRacksSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  page: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
  totalActive: payload.totalActive,
});

const getOneRackSuccess: RacksReducer<
  ReturnType<typeof RacksActions.getOneRackSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  error: '',
  selected: payload.rack,
});

const listSuccess: RacksReducer<ReturnType<typeof RacksActions.listSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const setPage: RacksReducer<ReturnType<typeof RacksActions.setPage>> = (
  state = INITIAL_STATE,
  { payload }
) => {
  return { ...state, page: payload.page };
};

const clearRack: RacksReducer<any> = () => INITIAL_STATE;

const setFiltersRacks: RacksReducer<
  ReturnType<typeof RacksActions.setFiltersRacks>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<RacksState>(INITIAL_STATE)
  .handleAction(RacksTypes.GET_RACKS_REQUEST, genericRequest)
  .handleAction(RacksTypes.GET_RACKS_SUCCESS, getRacksSuccess)
  .handleAction(RacksTypes.GET_RACKS_ERROR, genericError)
  .handleAction(RacksTypes.GET_ONE_RACK_REQUEST, genericRequest)
  .handleAction(RacksTypes.GET_ONE_RACK_SUCCESS, getOneRackSuccess)
  .handleAction(RacksTypes.GET_ONE_RACK_ERROR, genericError)
  .handleAction(RacksTypes.ADD_RACK_REQUEST, genericRequest)
  .handleAction(RacksTypes.ADD_RACK_SUCCESS, genericSuccess)
  .handleAction(RacksTypes.ADD_RACK_ERROR, genericError)
  .handleAction(RacksTypes.LIST_REQUEST, genericRequest)
  .handleAction(RacksTypes.LIST_SUCCESS, listSuccess)
  .handleAction(RacksTypes.LIST_ERROR, genericError)
  .handleAction(RacksTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(RacksTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(RacksTypes.UPDATE_ERROR, genericError)
  .handleAction(RacksTypes.CHANGE_ACTIVE_REQUEST, genericRequest)
  .handleAction(RacksTypes.CHANGE_ACTIVE_SUCCESS, genericSuccess)
  .handleAction(RacksTypes.CHANGE_ACTIVE_ERROR, genericError)
  .handleAction(RacksTypes.SET_PAGE, setPage)
  .handleAction(RacksTypes.CLEAR_RACK, clearRack)
  .handleAction(RacksTypes.SET_FILTERS_RACKS, setFiltersRacks);
