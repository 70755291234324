import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import {
  AddSettingPurposeDTO,
  SettingPurposeDTO,
} from '@/dtos/rack/settingPurpose';
import { PageDTO } from '@/dtos/generics';

import { genericRequest, genericError, genericSuccess } from '../utils';
import { SettingPurposeState } from './types';
import UpdateSettingPurposeDTO from '@/dtos/rack/settingPurpose/UpdateSettingPurposeDTO';
import SearchQuery from '@/utils/SearchQuery';

const INITIAL_STATE: SettingPurposeState = {
  data: [],
  currentPage: 1,
  next: undefined,
  prev: undefined,
  loading: false,
  error: '',
  filters: SearchQuery.build(),
  total: 0,
  selected: {} as SettingPurposeDTO,
};

export enum SettingPurposeTypes {
  SET_LOADING = '@settingPurposes/SET_LOADING',
  SET_FILTERS = '@settingPurposes/SET_FILTERS',
  LIST_REQUEST = '@settingPurposes/LIST_REQUEST',
  LIST_SUCCESS = '@settingPurposes/LIST_SUCCESS',
  LIST_ERROR = '@settingPurposes/LIST_ERROR',
  UPDATE_REQUEST = '@settingPurposes/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@settingPurposes/UPDATE_SUCCESS',
  UPDATE_ERROR = '@settingPurposes/UPDATE_ERROR',
  GET_REQUEST = '@settingPurposes/GET_REQUEST',
  GET_SUCCESS = '@settingPurposes/GET_SUCCESS',
  GET_ERROR = '@settingPurposes/GET_ERROR',
  ADD_REQUEST = '@settingPurposes/ADD_REQUEST',
  ADD_SUCCESS = '@settingPurposes/ADD_SUCCESS',
  ADD_ERROR = '@settingPurposes/ADD_ERROR',
  CLEAR = '@settingPurposes/CLEAR',
}

const SettingPurposeActions = {
  setLoading: (loading: boolean) =>
    action(SettingPurposeTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(SettingPurposeTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(SettingPurposeTypes.LIST_REQUEST, { filters }),
  listSuccess: (page: PageDTO<SettingPurposeDTO>) =>
    action(SettingPurposeTypes.LIST_SUCCESS, page),
  listError: (error: string) =>
    action(SettingPurposeTypes.LIST_ERROR, { error }),

  updateRequest: (settingPurpose: UpdateSettingPurposeDTO) =>
    action(SettingPurposeTypes.UPDATE_REQUEST, settingPurpose),
  updateSuccess: () => action(SettingPurposeTypes.UPDATE_SUCCESS),
  updateError: (error: string) =>
    action(SettingPurposeTypes.UPDATE_ERROR, { error }),

  getRequest: (id: string) => action(SettingPurposeTypes.GET_REQUEST, id),
  getSuccess: (settingPurpose: SettingPurposeDTO) =>
    action(SettingPurposeTypes.GET_SUCCESS, settingPurpose),
  getError: (error: string) => action(SettingPurposeTypes.GET_ERROR, { error }),

  addRequest: (data: AddSettingPurposeDTO) =>
    action(SettingPurposeTypes.ADD_REQUEST, { data }),
  addSuccess: () => action(SettingPurposeTypes.ADD_SUCCESS),
  addError: (error: string) => action(SettingPurposeTypes.ADD_ERROR, { error }),

  clear: () => action(SettingPurposeTypes.CLEAR),
};
export default SettingPurposeActions;

type SettingPurposeReducer<Action extends AnyAction> = Reducer<
  SettingPurposeState,
  Action
>;

const setLoading: SettingPurposeReducer<
  ReturnType<typeof SettingPurposeActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});
const setFilters: SettingPurposeReducer<
  ReturnType<typeof SettingPurposeActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: SettingPurposeReducer<
  ReturnType<typeof SettingPurposeActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: SettingPurposeReducer<
  ReturnType<typeof SettingPurposeActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selected: payload,
  loading: false,
});

const clear: SettingPurposeReducer<
  ReturnType<typeof SettingPurposeActions.clear>
> = () => INITIAL_STATE;

export const reducer = createReducer<SettingPurposeState>(INITIAL_STATE)
  .handleAction(SettingPurposeTypes.SET_LOADING, setLoading)
  .handleAction(SettingPurposeTypes.SET_FILTERS, setFilters)
  .handleAction(SettingPurposeTypes.LIST_REQUEST, genericRequest)
  .handleAction(SettingPurposeTypes.LIST_SUCCESS, listSuccess)
  .handleAction(SettingPurposeTypes.LIST_ERROR, genericError)
  .handleAction(SettingPurposeTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(SettingPurposeTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(SettingPurposeTypes.UPDATE_ERROR, genericError)
  .handleAction(SettingPurposeTypes.GET_REQUEST, genericRequest)
  .handleAction(SettingPurposeTypes.GET_SUCCESS, getSuccess)
  .handleAction(SettingPurposeTypes.GET_ERROR, genericError)
  .handleAction(SettingPurposeTypes.ADD_REQUEST, genericRequest)
  .handleAction(SettingPurposeTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(SettingPurposeTypes.ADD_ERROR, genericError)
  .handleAction(SettingPurposeTypes.CLEAR, clear);
