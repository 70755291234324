import api from '@/services/api';
import {
  AddSettingRackDTO,
  SettingRackDTO,
  UpdateSettingRackDTO,
} from '@/dtos/rack/settingRack';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

class SettingRackServices {
  async list(filters: SearchQuery): Promise<PageDTO<SettingRackDTO>> {
    const { data } = await api.get<PageDTO<SettingRackDTO>>(`/settingRacks`, {
      params: filters.query,
    });
    return data;
  }

  async getById(id: string): Promise<SettingRackDTO> {
    const { data } = await api.get<SettingRackDTO>(`/settingRacks/${id}`);
    return data;
  }

  async create(settingRack: AddSettingRackDTO): Promise<SettingRackDTO> {
    const { data } = await api.post<SettingRackDTO>(
      '/settingRacks',
      settingRack
    );
    return data;
  }

  async update(settingRack: UpdateSettingRackDTO): Promise<SettingRackDTO> {
    const { data } = await api.put<SettingRackDTO>(
      `/settingRacks/${settingRack.id}`,
      settingRack
    );
    return data;
  }
}

const settingRackservices = new SettingRackServices();
export default settingRackservices;
