import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectEntity from '@/components/SelectEntity';
import { StringSet } from '@/utils/setTypes';
import resourcesProvider from '@/lib/resources';

type ResourceField = {
  value: string;
  onChange: StringSet;
};
const ResourceField: React.FC<ResourceField> = ({ value, onChange }) => {
  const { t: translation } = useTranslation();
  return (
    <SelectEntity
      loadItems={resourcesProvider.list}
      onChange={onChange}
      value={value}
      placeholder={translation('select.placeholder.selectId')}
    />
  );
};

export default ResourceField;
