import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';
import { DashboardState } from './types';

export enum DashboardTypes {
  SET_PAGE_TITLE = '@dashboard/setPageTitle',
  SET_TITLE_HEADER = '@dashboard/setTitleHeader',
}

const DashboardActions = {
  setPageTitle: (title: string[], goTo?: string) =>
    action(DashboardTypes.SET_PAGE_TITLE, { title, goTo }),
};
export default DashboardActions;

const INITIAL_STATE: DashboardState = {
  title: [],
  goTo: '',
  loading: false,
  error: '',
};

type DashboardReducer<Action extends AnyAction> = Reducer<
  DashboardState,
  Action
>;
type SetPageTitleParams = DashboardReducer<
  ReturnType<typeof DashboardActions.setPageTitle>
>;
const setPageTitle: SetPageTitleParams = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  title: payload.title,
  goTo: payload.goTo,
  loading: false,
  error: '',
});

export const reducer = createReducer(INITIAL_STATE).handleAction(
  DashboardTypes.SET_PAGE_TITLE,
  setPageTitle
);
