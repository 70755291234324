import api from '@/services/api';
import {
  AddSettingPurposeDTO,
  SettingPurposeDTO,
  UpdateSettingPurposeDTO,
} from '@/dtos/rack/settingPurpose';
import { PageDTO } from '@/dtos/generics';
import SearchQuery from '@/utils/SearchQuery';

class SettingPurposeServices {
  async list(filters: SearchQuery): Promise<PageDTO<SettingPurposeDTO>> {
    const { data } = await api.get<PageDTO<SettingPurposeDTO>>(
      `/settingPurposes`,
      {
        params: filters.query,
      }
    );
    return data;
  }

  async getById(id: string): Promise<SettingPurposeDTO> {
    const { data } = await api.get<SettingPurposeDTO>(`/settingPurposes/${id}`);
    return data;
  }

  async create(
    settingPurpose: AddSettingPurposeDTO
  ): Promise<SettingPurposeDTO> {
    const { data } = await api.post<SettingPurposeDTO>(
      '/settingPurposes',
      settingPurpose
    );
    return data;
  }

  async update(
    settingPurpose: UpdateSettingPurposeDTO
  ): Promise<SettingPurposeDTO> {
    const { data } = await api.put<SettingPurposeDTO>(
      `/settingPurposes/${settingPurpose.id}`,
      settingPurpose
    );
    return data;
  }
}

const settingPurposeServices = new SettingPurposeServices();
export default settingPurposeServices;
