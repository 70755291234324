import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import settingRackservices from '@/lib/settingRack';
import { PageDTO } from '@/dtos/generics';
import { SettingRackDTO } from '@/dtos/rack/settingRack';
import SettingRackActions, { SettingRackTypes } from './duck';

type SignUpParams = ReturnType<typeof SettingRackActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(settingRackservices.list, payload.filters);
    yield put(
      SettingRackActions.listSuccess(result as PageDTO<SettingRackDTO>)
    );
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingRackActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof SettingRackActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(settingRackservices.create, payload.data);
    yield put(SettingRackActions.addSuccess());
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingRackActions.addError(errors));
  }
}

type UpdateParams = ReturnType<typeof SettingRackActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(settingRackservices.update, payload);
    yield put(SettingRackActions.updateSuccess());
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingRackActions.updateError(errors));
  }
}

type GetParams = ReturnType<typeof SettingRackActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(settingRackservices.getById, payload);
    yield put(SettingRackActions.getSuccess(data as SettingRackDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(SettingRackActions.getError(errors));
  }
}

export default all([
  takeLatest(SettingRackTypes.LIST_REQUEST, list),
  takeLatest(SettingRackTypes.ADD_REQUEST, add),
  takeLatest(SettingRackTypes.UPDATE_REQUEST, update),
  takeLatest(SettingRackTypes.GET_REQUEST, get),
]);
