import { all, takeLatest, call, put } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import codeServices from '@/lib/codes';
import codesProvider from '@/lib/codes';

import CodesActions, { CodesTypes } from './duck';
import CodeDTO from '@/dtos/solicitations/CodeDTO';
import PageDTO from '@/dtos/generics/PageDTO';
import { addDays, format } from 'date-fns';
import SearchQuery from '@/utils/SearchQuery';

type ListParams = ReturnType<typeof CodesActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const result = yield call(codesProvider.list, payload.filters);
    yield put(CodesActions.listSuccess(result as PageDTO<CodeDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(CodesActions.listError(errors));
  }
}

type GetCodesBySolicitation = ReturnType<
  typeof CodesActions.getCodesBySolicitationRequest
>;
export function* getCodesBySolicitation({
  payload,
}: GetCodesBySolicitation): Generator {
  try {
    const data = yield call(
      codeServices.findBySolicitation,
      payload.solicitationId
    );
    yield put(CodesActions.getCodesBySolicitationSuccess(data as string[]));
  } catch (err) {
    const error = handleError(err);
    yield put(CodesActions.getCodesBySolicitationError(error));
  }
}

type GetParams = ReturnType<typeof CodesActions.getCodeRequest>;
export function* find({ payload }: GetParams): Generator {
  try {
    const result = yield call(codesProvider.find, payload.value);
    yield put(CodesActions.getCodeSuccess(result as CodeDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(CodesActions.getCodeError(errors));
  }
}

type DeleteParams = ReturnType<typeof CodesActions.deleteRequest>;
export function* del({ payload }: DeleteParams): Generator {
  try {
    yield call(codesProvider.delete, payload);
    const dueDate = addDays(new Date(), 20);
    const params = SearchQuery.build()
      .isNotNull('due')
      .isNull('clientId')
      .lessThan('due', format(dueDate, 'yyyy-MM-dd'));
    params.page = 1;
    const result = yield call(codesProvider.list, params);
    yield put(CodesActions.listSuccess(result as PageDTO<CodeDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(CodesActions.deleteError(errors));
  }
}

export default all([
  takeLatest(CodesTypes.LIST_REQUEST, list),
  takeLatest(CodesTypes.DELETE_REQUEST, del),
  takeLatest(
    CodesTypes.GET_CODES_BY_SOLICITATION_REQUEST,
    getCodesBySolicitation
  ),
  takeLatest(CodesTypes.GET_CODE_REQUEST, find),
]);
