import api from '@/services/api';
import {
  AddRackDTO,
  UpdateRackDTO,
  RackDTO,
  RackFiltersDTO,
  RackListResultDTO,
} from '@/dtos/rack';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';

class RackServices {
  async create(rack: AddRackDTO): Promise<RackDTO> {
    const { data } = await api.post<RackDTO>('/racks', rack);
    return data;
  }
  async find(rackId: string): Promise<RackDTO> {
    const { data } = await api.get<RackDTO>(`/racks/${rackId}`);
    return data;
  }
  async update(rack: UpdateRackDTO): Promise<RackDTO> {
    const { data } = await api.put<RackDTO>(`/racks/${rack.id}`, rack);
    return data;
  }

  async changeActive(id: string): Promise<RackDTO> {
    const { data } = await api.delete<RackDTO>(`/racks/${id}/active`);
    return data;
  }
  async list(filters: SearchQuery): Promise<PageDTO<RackDTO>> {
    const { data } = await api.get<PageDTO<RackDTO>>(`/racks`, {
      params: filters.query,
    });
    return data;
  }
  async listWithDTO(filters: RackFiltersDTO): Promise<RackListResultDTO> {
    const { data } = await api.get<RackListResultDTO>('/racks', {
      params: filters,
    });
    return data;
  }
}

const rackServices = new RackServices();
export default rackServices;
