import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import VehicleDTO from '@/dtos/vehicle/VehicleDTO';
import VehicleListResultDTO from '@/dtos/vehicle/VehicleListResultDTO';
import VehicleFiltersDTO from '@/dtos/vehicle/VehicleFiltersDTO';
import {
  AddVehicleDTO,
  ChangeActiveDTO,
  UpdateVehicleDTO,
} from '@/dtos/vehicle';
import { genericError, genericRequest, genericSuccess } from '../utils';
import { VehiclesState } from './types';

export enum VehiclesTypes {
  ADD_VEHICLE_REQUEST = '@vehicles/addVehicleRequest',
  ADD_VEHICLE_SUCCESS = '@vehicles/addVehicleSuccess',
  ADD_VEHICLE_ERROR = '@vehicles/addVehicleError',
  GET_ONE_VEHICLE_REQUEST = '@vehicles/getOneVehicleRequest',
  GET_ONE_VEHICLE_SUCCESS = '@vehicles/getOneVehicleSuccess',
  GET_ONE_VEHICLE_ERROR = '@vehicles/getOneVehicleError',
  UPDATE_REQUEST = '@vehicles/updateRequest',
  UPDATE_SUCCESS = '@vehicles/updateSuccess',
  UPDATE_ERROR = '@vehicles/updateError',
  GET_VEHICLES_REQUEST = '@vehicles/getVehiclesRequest',
  GET_VEHICLES_SUCCESS = '@vehicles/getVehiclesSuccess',
  GET_VEHICLES_ERROR = '@vehicles/getVehiclesError',
  CHANGE_ACTIVE_REQUEST = '@vehicles/changeActiveRequest',
  CHANGE_ACTIVE_SUCCESS = '@vehicles/changeActiveSuccess',
  CHANGE_ACTIVE_ERROR = '@vehicles/changeActiveError',
  SET_PAGE = '@vehicles/setPage',
  CLEAR_VEHICLE = '@vehicles/clearVehicle',
  SET_FILTERS_VEHICLES = '@vehicles/setFiltersVehicles',
}

const VehiclesActions = {
  addVehicleRequest: (data: AddVehicleDTO) =>
    action(VehiclesTypes.ADD_VEHICLE_REQUEST, { data }),
  addVehicleSuccess: () => action(VehiclesTypes.ADD_VEHICLE_SUCCESS),
  addVehicleError: (error: string) =>
    action(VehiclesTypes.ADD_VEHICLE_ERROR, { error }),
  getOneVehicleRequest: (id: string) =>
    action(VehiclesTypes.GET_ONE_VEHICLE_REQUEST, { id }),
  getOneVehicleSuccess: (vehicle: VehicleDTO) =>
    action(VehiclesTypes.GET_ONE_VEHICLE_SUCCESS, { vehicle }),
  getOneVehicleError: (error: string) =>
    action(VehiclesTypes.GET_ONE_VEHICLE_ERROR, { error }),
  getVehiclesRequest: (filters: VehicleFiltersDTO) =>
    action(VehiclesTypes.GET_VEHICLES_REQUEST, { filters }),
  getVehiclesSuccess: (data: VehicleListResultDTO) =>
    action(VehiclesTypes.GET_VEHICLES_SUCCESS, data),
  getVehiclesError: (error: string) =>
    action(VehiclesTypes.GET_VEHICLES_ERROR, { error }),
  updateRequest: (vehicle: UpdateVehicleDTO) =>
    action(VehiclesTypes.UPDATE_REQUEST, vehicle),
  updateSuccess: () => action(VehiclesTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(VehiclesTypes.UPDATE_ERROR, { error }),
  changeActiveRequest: (data: ChangeActiveDTO) =>
    action(VehiclesTypes.CHANGE_ACTIVE_REQUEST, data),
  changeActiveSuccess: () => action(VehiclesTypes.CHANGE_ACTIVE_SUCCESS),
  changeActiveError: (error: string) =>
    action(VehiclesTypes.CHANGE_ACTIVE_ERROR, { error }),
  setPage: (page: number) => action(VehiclesTypes.SET_PAGE, { page }),
  clearVehicle: () => action(VehiclesTypes.CLEAR_VEHICLE),
  setFiltersVehicles: (filters: VehicleFiltersDTO) =>
    action(VehiclesTypes.SET_FILTERS_VEHICLES, { filters }),
};

export default VehiclesActions;

const INITIAL_STATE: VehiclesState = {
  data: [],
  selected: {} as VehicleDTO,
  loading: false,
  error: '',
  page: 1,
  next: undefined,
  prev: undefined,
  total: 0,
  filters: {
    page: 1,
  } as VehicleFiltersDTO,
  totalActive: {
    activated: 0,
    disabled: 0,
  },
};

type VehiclesReducer<Action extends AnyAction> = Reducer<VehiclesState, Action>;
const getVehiclesSuccess: VehiclesReducer<
  ReturnType<typeof VehiclesActions.getVehiclesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  page: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
  error: '',
  totalActive: payload.totalActive,
  // filters: { ...state.filters, page: payload.currentPage },
});

const getOneVehicleSuccess: VehiclesReducer<
  ReturnType<typeof VehiclesActions.getOneVehicleSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: false,
  error: '',
  selected: payload.vehicle,
});

const setPage: VehiclesReducer<ReturnType<typeof VehiclesActions.setPage>> = (
  state = INITIAL_STATE,
  { payload }
) => {
  return { ...state, page: payload.page };
};

const clearVehicle: VehiclesReducer<any> = () => INITIAL_STATE;

const setFiltersVehicles: VehiclesReducer<
  ReturnType<typeof VehiclesActions.setFiltersVehicles>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

export const reducer = createReducer<VehiclesState>(INITIAL_STATE)
  .handleAction(VehiclesTypes.GET_VEHICLES_REQUEST, genericRequest)
  .handleAction(VehiclesTypes.GET_VEHICLES_SUCCESS, getVehiclesSuccess)
  .handleAction(VehiclesTypes.GET_VEHICLES_ERROR, genericError)
  .handleAction(VehiclesTypes.GET_ONE_VEHICLE_REQUEST, genericRequest)
  .handleAction(VehiclesTypes.GET_ONE_VEHICLE_SUCCESS, getOneVehicleSuccess)
  .handleAction(VehiclesTypes.GET_ONE_VEHICLE_ERROR, genericError)
  .handleAction(VehiclesTypes.ADD_VEHICLE_REQUEST, genericRequest)
  .handleAction(VehiclesTypes.ADD_VEHICLE_SUCCESS, genericSuccess)
  .handleAction(VehiclesTypes.ADD_VEHICLE_ERROR, genericError)
  .handleAction(VehiclesTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(VehiclesTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(VehiclesTypes.UPDATE_ERROR, genericError)
  .handleAction(VehiclesTypes.CHANGE_ACTIVE_REQUEST, genericRequest)
  .handleAction(VehiclesTypes.CHANGE_ACTIVE_SUCCESS, genericSuccess)
  .handleAction(VehiclesTypes.CHANGE_ACTIVE_ERROR, genericError)
  .handleAction(VehiclesTypes.SET_PAGE, setPage)
  .handleAction(VehiclesTypes.CLEAR_VEHICLE, clearVehicle)
  .handleAction(VehiclesTypes.SET_FILTERS_VEHICLES, setFiltersVehicles);
