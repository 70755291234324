import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
import conductorServices from '@/lib/conductor';
import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import ConductorListResultDTO from '@/dtos/conductor/ConductorListResultDTO';
import ConductorDTO from '@/dtos/conductor/ConductorDTO';
import ConductorFiltersDTO from '@/dtos/conductor/ConductorFiltersDTO';
import ConductorsActions, { ConductorsTypes } from './duck';

const registerConductor = i18next.t('Success.successRegister');
const ConductorUpdate = i18next.t('Success.successUpdate');

type AddConductorParams = ReturnType<
  typeof ConductorsActions.addConductorRequest
>;
export function* addConductor({ payload }: AddConductorParams): Generator {
  try {
    yield call(conductorServices.create, payload.data);
    yield put(ConductorsActions.addConductorSuccess());
    history.push('/conductors');
    toast.success(registerConductor);
  } catch (err) {
    const error = handleError(err);
    yield put(ConductorsActions.addConductorError(error));
  }
}

type GetConductorsParams = ReturnType<
  typeof ConductorsActions.getConductorsRequest
>;
export function* getConductors({ payload }: GetConductorsParams): Generator {
  try {
    const data = yield call(conductorServices.list, payload.filters);
    yield put(
      ConductorsActions.getConductorsSuccess(data as ConductorListResultDTO)
    );
  } catch (err) {
    const error = handleError(err);
    yield put(ConductorsActions.getConductorsError(error));
  }
}

type GetOneConductorParams = ReturnType<
  typeof ConductorsActions.getOneConductorRequest
>;
export function* getOneConductor({
  payload,
}: GetOneConductorParams): Generator {
  try {
    const data = yield call(conductorServices.find, payload.id);
    yield put(ConductorsActions.getOneConductorSuccess(data as ConductorDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(ConductorsActions.getOneConductorError(error));
  }
}

type UpdateConductorParams = ReturnType<typeof ConductorsActions.updateRequest>;
export function* update({ payload }: UpdateConductorParams): Generator {
  try {
    const data = yield call(conductorServices.update, payload);
    const conductor = data as ConductorDTO;
    yield put(ConductorsActions.updateSuccess());
    yield put(
      ConductorsActions.getConductorsRequest({} as ConductorFiltersDTO)
    );
    yield put(ConductorsActions.getOneConductorRequest(conductor.id));
    toast.success(ConductorUpdate);
  } catch (err) {
    const error = handleError(err);
    yield put(ConductorsActions.updateError(error));
  }
}

type ChangeActiveParams = ReturnType<
  typeof ConductorsActions.changeActiveRequest
>;
export function* changeActive({ payload }: ChangeActiveParams): Generator {
  try {
    const data = yield call(conductorServices.changeActive, payload);
    const conductor = data as ConductorDTO;
    yield put(ConductorsActions.updateSuccess());
    yield put(
      ConductorsActions.getConductorsRequest({} as ConductorFiltersDTO)
    );
    yield put(ConductorsActions.getOneConductorRequest(conductor.id));
  } catch (err) {
    const error = handleError(err);
    yield put(ConductorsActions.updateError(error));
  }
}

export default all([
  takeLatest(ConductorsTypes.ADD_CONDUCTOR_REQUEST, addConductor),
  takeLatest(ConductorsTypes.GET_CONDUCTORS_REQUEST, getConductors),
  takeLatest(ConductorsTypes.GET_ONE_CONDUCTOR_REQUEST, getOneConductor),
  takeLatest(ConductorsTypes.UPDATE_REQUEST, update),
  takeLatest(ConductorsTypes.CHANGE_ACTIVE_REQUEST, changeActive),
]);
