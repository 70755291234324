import { combineReducers } from 'redux';

import { reducer as user } from './user/duck';
import { reducer as users } from './users/duck';
import { reducer as codes } from './codes/duck';
import { reducer as dashboard } from './dashboard/duck';
import { reducer as events } from './events/duck';
import { reducer as roles } from './roles/duck';
import { reducer as permissions } from './permissions/duck';
import { reducer as sidebar } from './sidebar/duck';
import { reducer as invoices } from './invoices/duck';
import { reducer as status } from './status/duck';
import { reducer as resources } from './resources/duck';
import { reducer as clients } from './clients/duck';
import { reducer as vehicles } from './vehicles/duck';
import { reducer as conductors } from './conductors/duck';
import { reducer as gases } from './gas/duck';
import { reducer as racks } from './racks/duck';
import { reducer as storages } from './storages/duck';
import { reducer as settingRacks } from './settingRacks/duck';
import { reducer as settingPurposes } from './settingPurposes/duck';
import { reducer as equipments } from './equipments/duck';
import { reducer as procedures } from './procedures/duck';
import { reducer as printers } from './printers/duck';

const reducers = combineReducers({
  user,
  users,
  codes,
  dashboard,
  events,
  roles,
  permissions,
  sidebar,
  invoices,
  status,
  resources,
  clients,
  vehicles,
  conductors,
  gases,
  racks,
  storages,
  settingRacks,
  settingPurposes,
  equipments,
  procedures,
  printers,
});

export default reducers;
