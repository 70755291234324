import { call, put, all, takeLatest } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import equipmentServices from '@/lib/equipments';
import { PageDTO } from '@/dtos/generics';
import { EquipmentDTO } from '@/dtos/equipments';
import history from '@/services/history';
import EquipmentsActions, { EquipmentTypes } from './duck';

type SignUpParams = ReturnType<typeof EquipmentsActions.listRequest>;
export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(equipmentServices.list, payload.filters);
    yield put(EquipmentsActions.listSuccess(result as PageDTO<EquipmentDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(EquipmentsActions.listError(errors));
  }
}

type AddParams = ReturnType<typeof EquipmentsActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(equipmentServices.create, payload.data);
    yield put(EquipmentsActions.addSuccess());
    history.push('/equipments');
  } catch (err) {
    const errors = handleError(err);
    yield put(EquipmentsActions.addError(errors));
  }
}

type UpdateParams = ReturnType<typeof EquipmentsActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(equipmentServices.update, payload);
    yield put(EquipmentsActions.updateSuccess());
    history.push('/equipments');
  } catch (err) {
    const errors = handleError(err);
    yield put(EquipmentsActions.updateError(errors));
  }
}

type GetParams = ReturnType<typeof EquipmentsActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(equipmentServices.getById, payload);
    yield put(EquipmentsActions.getSuccess(data as EquipmentDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(EquipmentsActions.getError(errors));
  }
}

export default all([
  takeLatest(EquipmentTypes.LIST_REQUEST, list),
  takeLatest(EquipmentTypes.ADD_REQUEST, add),
  takeLatest(EquipmentTypes.UPDATE_REQUEST, update),
  takeLatest(EquipmentTypes.GET_REQUEST, get),
]);
