import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18next from '@/locale';
// import api from '@/services/api';
import invoiceServices from '@/lib/invoices';
// import history from '@/services/history';
import handleError from '@/adapters/httpErrors';
import { InvoiceDTO, InvoiceListResultDTO } from '@/dtos/invoices';
import InvoicesActions, { InvoicesTypes } from './duck';
// import positionProvider, { Position } from '@/services/position';

// const invoiceLinked = i18next.t('Success.invoiceLinked');
// const invoiceTransfered = i18next.t('Success.invoiceTransfered');
// const invoiceDelete = i18next.t('Success.invoiceDelete');

type GetInvoicesParams = ReturnType<typeof InvoicesActions.getInvoicesRequest>;
export function* getInvoices({ payload }: GetInvoicesParams): Generator {
  try {
    const data = yield call(invoiceServices.list, payload.filters);
    yield put(InvoicesActions.getInvoicesSuccess(data as InvoiceListResultDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.getInvoicesError(error));
  }
}

type GetInvoiceParams = ReturnType<typeof InvoicesActions.getInvoiceRequest>;
export function* getInvoice({ payload }: GetInvoiceParams): Generator {
  try {
    const data = yield call(invoiceServices.find, payload.id);
    yield put(InvoicesActions.getInvoiceSuccess(data as InvoiceDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.getInvoiceError(error));
  }
}

type GetInvoiceByAccessKeyParams = ReturnType<
  typeof InvoicesActions.getInvoiceByAccessKeyRequest
>;
export function* getInvoiceByAccessKey({
  payload,
}: GetInvoiceByAccessKeyParams): Generator {
  try {
    const data = yield call(invoiceServices.findByAccessKey, payload.accessKey);
    yield put(InvoicesActions.getInvoiceByAccessKeySuccess(data as InvoiceDTO));
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.getInvoiceByAccessKeyError(error));
  }
}

// type AggregateInvoiceParams = ReturnType<
//   typeof InvoicesActions.aggregateInvoiceRequest
// >;
// export function* aggregateInvoice({
//   payload,
// }: GetInvoiceByAccessKeyParams): Generator {
//   try {
//     const { events, invoiceId } = action;
//     yield call(api.post, `/codes/invoices`, {
//       events,
//       invoiceId,
//     });
//     yield put(InvoicesActions.aggregateInvoiceSuccess());
//     toast.success(invoiceLinked);
//     history.push(`/invoices/details/${invoiceId}`);
//   } catch (err) {
//     const error = handleError(err);
//     yield put(InvoicesActions.aggregateInvoiceError(error));
//   }
// }

type TransferCustodyParams = ReturnType<
  typeof InvoicesActions.transferCustodyRequest
>;
export function* transferCustody({
  payload,
}: TransferCustodyParams): Generator {
  try {
    const { onBack } = payload;
    // const position = yield call(positionProvider.getCurrentPosition);
    // const { latitude, longitude } = position as Position;
    // yield call(driverWorks.create, {
    //   invoiceId,
    //   registrationKey,
    //   latitude,
    //   longitude,
    // });
    yield put(InvoicesActions.transferCustodySuccess());
    yield put(InvoicesActions.getInvoicesRequest({}));
    toast.success(i18next.t('success.transferCustodySuccess'));
    onBack();
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.transferCustodyError(error));
  }
}

type CancelInvoiceParams = ReturnType<
  typeof InvoicesActions.cancelInvoiceRequest
>;
export function* cancelInvoice({ payload }: CancelInvoiceParams): Generator {
  try {
    const { invoiceId, data, onBack } = payload;
    yield call(invoiceServices.cancelInvoice, invoiceId, data);
    yield put(InvoicesActions.cancelInvoiceSuccess());
    yield put(InvoicesActions.getInvoicesRequest({}));
    onBack();
  } catch (err) {
    const error = handleError(err);
    yield put(InvoicesActions.cancelInvoiceError(error));
  }
}

export default all([
  takeLatest(InvoicesTypes.GET_INVOICES_REQUEST, getInvoices),
  takeLatest(InvoicesTypes.GET_INVOICE_REQUEST, getInvoice),
  takeLatest(
    InvoicesTypes.GET_INVOICE_BY_ACCESS_KEY_REQUEST,
    getInvoiceByAccessKey
  ),
  // takeLatest(InvoicesTypes.AGGREGATE_INVOICE_REQUEST, aggregateInvoice),
  takeLatest(InvoicesTypes.TRANSFER_CUSTODY_REQUEST, transferCustody),
  takeLatest(InvoicesTypes.CANCEL_INVOICE_REQUEST, cancelInvoice),
]);
